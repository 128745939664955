import { ArrowRight } from "@ignite-analytics/icons";
import { formatDateAsUTC } from "@ignite-analytics/locale";
import { Avatar, Paper, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { Activity_NoteFragment, RiskReasonActivity_UserFragment, RiskScore } from "@/gql/graphql";

import { RiskChip } from "../Common/StatusChip";

graphql(`
    fragment RiskReasonActivity_User on RoleUser {
        fullName
        initials
    }
`);

interface RiskReasonActivityProps {
    note: Activity_NoteFragment;
    user: RiskReasonActivity_UserFragment;
    igniteRiskScore?: RiskScore;
}

export const RiskReasonActivity: React.FC<RiskReasonActivityProps> = ({ note, user, igniteRiskScore }) => {
    let previousUserEstimate: RiskScore | null = null;
    if (note.metadata?.prevValue) {
        previousUserEstimate = getRiskValue(note.metadata?.prevValue);
    }

    let newUserEstimate: RiskScore | null = null;
    if (note.metadata?.newValue) {
        newUserEstimate = getRiskValue(note.metadata?.newValue);
    }
    return (
        <Paper sx={{ borderRadius: 1, p: 2, rowGap: 1 }}>
            <Stack direction="row" alignItems="center" spacing={1} paddingBottom={1}>
                <Avatar src="" sx={{ width: 32, height: 32 }}>
                    <Typography variant="textXs">{user.initials}</Typography>
                </Avatar>
                <Stack>
                    <Typography variant="textSm">{user.fullName}</Typography>
                    <Typography variant="textXs" color="text.secondary">
                        {formatDateAsUTC(note.createdAt)}
                    </Typography>
                </Stack>
            </Stack>

            <Stack paddingLeft={5}>
                <Typography
                    variant="textSm"
                    sx={{
                        wordBreak: "break-word",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {note.note}
                </Typography>
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                paddingLeft={5}
                paddingTop={2}
            >
                <Typography variant="textXs" sx={{ color: (theme) => theme.palette.tokens?.text.textBody }}>
                    <FormattedMessage defaultMessage="Changed social risk" />
                </Typography>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    {previousUserEstimate ? (
                        <RiskChip riskScore={previousUserEstimate} size="small" />
                    ) : igniteRiskScore ? (
                        <RiskChip riskScore={igniteRiskScore} size="small" />
                    ) : null}
                    <ArrowRight />
                    {newUserEstimate ? (
                        <RiskChip riskScore={newUserEstimate} size="small" />
                    ) : igniteRiskScore ? (
                        <RiskChip riskScore={igniteRiskScore} size="small" />
                    ) : null}
                </Stack>
            </Stack>
        </Paper>
    );
};

function getRiskValue(riskValue: RiskScore | string): RiskScore | null {
    switch (riskValue) {
        case "veryLow":
        case "very_low":
        case "VERY_LOW":
            return RiskScore.VeryLow;
        case "low":
        case "LOW":
            return RiskScore.Low;
        case "medium":
        case "MEDIUM":
            return RiskScore.Medium;
        case "high":
        case "HIGH":
            return RiskScore.High;
        case "veryHigh":
        case "very_high":
        case "VERY_HIGH":
            return RiskScore.VeryHigh;
        default:
            return null;
    }
}
