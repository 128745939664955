import { useQuery } from "@apollo/client";
import { orderBy } from "lodash";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { SupplierCountryLanguage } from "@/gql/graphql";

const getCountriesQuery = graphql(`
    query useCountries_getCountries($language: SupplierCountryLanguage) {
        getCountries {
            countries {
                id
                name(language: $language)
            }
        }
    }
`);

export function useCountries() {
    const language = useCountryLanguage();
    const { data, ...others } = useQuery(getCountriesQuery, {
        variables: {
            language,
        },
    });
    const sortedCountryData = orderBy(data?.getCountries.countries ?? [], ["name"], ["asc"]);
    return { data: { getCountries: { countries: sortedCountryData } }, ...others };
}

export function useCountryLanguage() {
    const { locale } = useIntl();
    let language: SupplierCountryLanguage | null = null;
    switch (locale) {
        case "en-US":
            language = SupplierCountryLanguage.EnUs;
            break;
        case "nb-NO":
            language = SupplierCountryLanguage.NbNo;
            break;
    }
    return language;
}
