import { ShapeIcon } from "@ignite-analytics/components";
import { PlusCircle, XCircle } from "@ignite-analytics/icons";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

import { DocumentActivityMetadata } from "@/gql/graphql";
import { getShortDocumentTypeName } from "@/lib/files";

import { normalizeLocale } from "./utils";

interface DocumentActivityProps {
    documentDetails: DocumentActivityMetadata;
    name: string;
    noteCreatedAt: string;
}

export const DocumentActivity: React.FC<DocumentActivityProps> = ({ documentDetails, name, noteCreatedAt }) => {
    const { formatMessage, locale } = useIntl();
    dayjs.locale(normalizeLocale(locale));
    const theme = useTheme();

    const activityMessage = (() => {
        switch (documentDetails.documentEvent) {
            case "add":
                return (
                    <>
                        <Typography variant="textSm" color={theme.palette.text.textPrimary}>
                            {formatMessage(
                                {
                                    defaultMessage: "{name} added {filename}",
                                },
                                {
                                    name,
                                    filename: decodeURIComponent(documentDetails.originalFilename ?? ""),
                                }
                            )}
                        </Typography>{" "}
                        <Typography variant="textSm" color={theme.palette.text.textBody}>
                            {formatMessage(
                                {
                                    defaultMessage: "[{documentType}]",
                                },
                                {
                                    documentType: getShortDocumentTypeName(documentDetails.documentType ?? ""),
                                }
                            )}
                        </Typography>
                    </>
                );
            case "remove":
                return (
                    <>
                        <Typography variant="textSm" color={theme.palette.text.textPrimary}>
                            {formatMessage(
                                {
                                    defaultMessage: "{name} deleted {filename}",
                                },
                                {
                                    name,
                                    filename: decodeURIComponent(documentDetails.originalFilename ?? ""),
                                }
                            )}
                        </Typography>{" "}
                        <Typography variant="textSm" color={theme.palette.text.textBody}>
                            {formatMessage(
                                {
                                    defaultMessage: "[{documentType}]",
                                },
                                {
                                    documentType: getShortDocumentTypeName(documentDetails.documentType ?? ""),
                                }
                            )}
                        </Typography>
                    </>
                );
            default:
                return "";
        }
    })();

    return (
        <Stack direction="row" spacing={1} alignItems="flex-start" sx={{ marginLeft: 1 }}>
            <Box sx={{ flexShrink: 0 }}>
                {documentDetails.documentEvent === "add" ? (
                    <ShapeIcon size="xsmall" color="accent">
                        <PlusCircle />
                    </ShapeIcon>
                ) : (
                    <ShapeIcon size="xsmall" color="error">
                        <XCircle />
                    </ShapeIcon>
                )}
            </Box>
            <Stack sx={{ minWidth: 0 }}>
                <Typography variant="textSm" sx={{ maxWidth: "100%" }}>
                    {activityMessage}
                </Typography>
                <Typography variant="textSm" color={theme.palette.text.textHelper}>
                    {dayjs.utc(noteCreatedAt).fromNow()}
                </Typography>
            </Stack>
        </Stack>
    );
};
