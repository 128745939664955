import { useQuery } from "@apollo/client";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import dayjs from "dayjs";
import { orderBy } from "lodash";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { CountryAutocomplete_SupplierCountryFragment, SupplierCustomFieldType } from "@/gql/graphql";

import { useSupplierCountryLanguage } from "../../useSupplierCountryLanguage";
import { getNACELabel } from "../NaceField/helpers";
import { NACEOptions } from "../NaceField/NACEOptions";

interface EditFieldProps {
    id: string;
    value: string | string[] | number; // as dataJson is any when marshalled
    type: SupplierCustomFieldType | "onboarding";
    onFieldChange: (id: string, value: string) => void;
    selectOptions?: string[];
}

export const EditField: React.FC<EditFieldProps> = ({ id, value, onFieldChange, type, selectOptions }) => {
    switch (type) {
        case SupplierCustomFieldType.Select:
            // check that value have to be type of string
            if (typeof value !== "string") {
                value = "";
            }

            return (
                <Select
                    type="text"
                    size="small"
                    defaultValue={value}
                    onChange={(fieldVal) => {
                        onFieldChange(id, fieldVal.target.value);
                    }}
                    fullWidth
                >
                    <MenuItem value="">
                        <FormattedMessage defaultMessage="Clear value" />
                    </MenuItem>
                    {selectOptions?.map((s: string) => {
                        return (
                            <MenuItem value={s} key={s}>
                                {s}
                            </MenuItem>
                        );
                    })}
                </Select>
            );
        case SupplierCustomFieldType.Nace:
            return (
                <Autocomplete
                    onChange={(_e, value) => onFieldChange && onFieldChange(id, value?.code ?? "")}
                    options={NACEOptions}
                    getOptionLabel={(option) => `${option.code} - ${getNACELabel(option)}`}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                    value={NACEOptions.find((nace) => nace.code === value)}
                    filterOptions={(options, params) => {
                        const inputValue = params.inputValue.toLowerCase();
                        return options.filter((option) => {
                            const codeMatches = option.code.toLowerCase().includes(inputValue);
                            const nameMatches = getNACELabel(option).toLowerCase().includes(inputValue);
                            return codeMatches || nameMatches;
                        });
                    }}
                    slotProps={{ paper: { sx: { wordBreak: "pre-line" } } }}
                    ListboxProps={{
                        sx: {
                            "& .MuiAutocomplete-option": {
                                height: "100%",
                            },
                        },
                    }}
                />
            );
        case SupplierCustomFieldType.Boolean:
            if (typeof value !== "string") {
                value = "false";
            }
            return (
                <Select
                    size="small"
                    defaultValue={value}
                    onChange={(fieldVal) => onFieldChange(id, fieldVal.target.value)}
                    fullWidth
                >
                    <MenuItem value="true">
                        <FormattedMessage defaultMessage="Yes" />
                    </MenuItem>
                    <MenuItem value="false">
                        <FormattedMessage defaultMessage="No" />
                    </MenuItem>
                </Select>
            );
        default:
            if (id === "country") {
                return (
                    <CountryAutocomplete
                        onChange={(country) => onFieldChange(id, country?.id ?? "")}
                        value={typeof value === "string" ? value : null}
                    />
                );
            }
            return (
                <TextField
                    size="small"
                    variant="outlined"
                    type={
                        type === SupplierCustomFieldType.Spend || type == SupplierCustomFieldType.MonetaryAmount
                            ? "number"
                            : type
                    }
                    defaultValue={
                        type === SupplierCustomFieldType.Date && typeof value === "string"
                            ? dayjs(value).format("YYYY-MM-DD")
                            : value
                    }
                    onChange={(fieldVal) => onFieldChange && onFieldChange(id, fieldVal.target.value)}
                    fullWidth
                />
            );
    }
};

type CountryAutocompleteProps = {
    onChange?: (value: CountryAutocomplete_SupplierCountryFragment | null) => void;
    value: string | null;
};

graphql(`
    fragment CountryAutocomplete_SupplierCountry on SupplierCountry {
        id
        name(language: $language)
        iso2Code
    }
`);

const CountryAutocomplete: React.FC<CountryAutocompleteProps> = ({ onChange, value }) => {
    const language = useSupplierCountryLanguage();
    const { data, loading } = useQuery(
        graphql(`
            query CountryAutocomplete_GetCountries($language: SupplierCountryLanguage) {
                getCountries {
                    countries {
                        ...CountryAutocomplete_SupplierCountry
                    }
                }
            }
        `),
        {
            variables: { language },
        }
    );
    const orderedCountries = orderBy(data?.getCountries.countries, ["name"], ["asc"]);

    const country = data?.getCountries.countries.find((country) => country.id === value) ?? null;
    return (
        <Autocomplete
            onChange={(_e, value) => onChange && onChange(value)}
            options={orderedCountries ?? []}
            loading={loading}
            getOptionKey={(option) => option.id}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => `${option.iso2Code} - ${option.name}`}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            value={country}
            filterOptions={(options, params) => {
                const inputValue = params.inputValue.toLowerCase();
                return options.filter((option) => {
                    const codeMatches = option.iso2Code.toLowerCase().includes(inputValue);
                    const nameMatches = option.name
                        .toLowerCase()
                        .split(" ")
                        .some((word) => word.startsWith(inputValue));
                    return codeMatches || nameMatches;
                });
            }}
        />
    );
};
