import { gql, useQuery } from "@apollo/client";
import { Clipboard, X } from "@ignite-analytics/icons";
import {
    Alert,
    Box,
    Chip,
    Dialog,
    DialogContent,
    IconButton,
    Skeleton,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { ShowInvoiceDataDialog_GetTransactionsDocument } from "@/gql/graphql";
import { useCurrencyCode } from "@/providers/CurrencyProvider";

import { DatePeriodSelection, toDatePeriodInput, useDatePeriodLabel } from "../Common/spendUtils";

import { NoWrapCell, StickyCell, StickyHeaderCell } from "./style";

gql(`
    query ShowInvoiceDataDialog_GetTransactions($input: GetTransactionsInput!) {
        getTransactions(input: $input) {
            total
            transactions {
                id
                date
                amount
                currency
                accountNumber
                accountName
                dueDate
                invoiceDate
                invoiceId
                invoiceDescription
                createdAt
                supplier {
                    id
                    name
                }
                import {
                    id
                    dataRepository {
                        name
                    }
                }
            }
        }
    }
`);

interface ShowInvoiceDataDialogProps {
    open: boolean;
    supplier?: {
        id: string;
        name: string;
    };
    period: DatePeriodSelection;
    onClose: () => void;
}

export function ShowInvoiceDataDialog({ open, supplier, period, onClose }: ShowInvoiceDataDialogProps) {
    const { formatMessage, formatNumber } = useIntl();
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { data, loading, error } = useQuery(ShowInvoiceDataDialog_GetTransactionsDocument, {
        skip: !supplier || !open,
        variables: {
            input: {
                range: rowsPerPage,
                index: currentPage * rowsPerPage,
                selection: {
                    period: toDatePeriodInput(period, true),
                    filters: {
                        suppliers: [supplier?.id ?? ""],
                    },
                },
            },
        },
    });

    const handleChangePage = useCallback((_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setCurrentPage(page);
    }, []);
    const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(Number(event.target.value));
        setCurrentPage(0);
    }, []);

    const { transactions, total } = data?.getTransactions ?? {};

    const loadingSkeleton = Array.from({ length: 5 }).map((_, index) => (
        <TableRow key={index}>
            {Array.from({ length: 9 }).map((_, n) => (
                <TableCell key={n}>
                    <Skeleton variant="rounded" />
                </TableCell>
            ))}
        </TableRow>
    ));

    const currency = useCurrencyCode();

    const handleCopyImportId = (id?: string) => {
        if (id) {
            navigator.clipboard.writeText(id);
            setSnackbarOpen(true);
        }
    };

    const handleClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <IconButton onClick={onClose} sx={{ position: "absolute", top: 10, right: 10 }}>
                <X />
            </IconButton>
            <DialogContent>
                <Stack spacing={4}>
                    <Stack spacing={0.75}>
                        <Typography variant="textLg" fontWeight={500}>
                            {formatMessage(
                                { defaultMessage: "{supplierName} - Transaction data - {period}" },
                                { supplierName: supplier?.name, period: useDatePeriodLabel(period) }
                            )}
                        </Typography>
                    </Stack>
                    <TableContainer>
                        <Stack p={2.5} spacing={2} direction="row" sx={{ position: "sticky", left: 0 }}>
                            <Typography variant="textLg" fontWeight={500}>
                                {formatMessage({ defaultMessage: "Total transactions" })}
                            </Typography>
                            <Chip size="small" label={total} />
                        </Stack>
                        <Box
                            sx={{ overflowX: "auto", maxHeight: "50vh" }}
                            borderBottom={1}
                            borderTop={1}
                            borderColor="tokens.border.border300"
                        >
                            <Table size="small" sx={{ width: "100%", borderCollapse: "separate" }}>
                                <TableHead>
                                    <TableRow sx={{ position: "sticky", top: 0, zIndex: 2 }}>
                                        <StickyHeaderCell>
                                            {formatMessage({ defaultMessage: "Supplier" })}
                                        </StickyHeaderCell>
                                        <NoWrapCell align="right">
                                            {formatMessage(
                                                { defaultMessage: "Transaction value {currency}" },
                                                { currency }
                                            )}
                                        </NoWrapCell>
                                        <NoWrapCell>{formatMessage({ defaultMessage: "Transaction date" })}</NoWrapCell>
                                        <NoWrapCell>
                                            {formatMessage({ defaultMessage: "GL Account number" })}
                                        </NoWrapCell>
                                        <NoWrapCell>{formatMessage({ defaultMessage: "GL Account name" })}</NoWrapCell>
                                        <NoWrapCell>{formatMessage({ defaultMessage: "Invoice ID" })}</NoWrapCell>
                                        <NoWrapCell>
                                            {formatMessage({ defaultMessage: "Invoice description" })}
                                        </NoWrapCell>
                                        <NoWrapCell>{formatMessage({ defaultMessage: "Invoice date" })}</NoWrapCell>
                                        <NoWrapCell>{formatMessage({ defaultMessage: "Invoice due date" })}</NoWrapCell>
                                        <NoWrapCell>{formatMessage({ defaultMessage: "Data source" })}</NoWrapCell>
                                        <NoWrapCell>{formatMessage({ defaultMessage: "Import date" })}</NoWrapCell>
                                    </TableRow>
                                </TableHead>
                                {/* Table body */}
                                <TableBody>
                                    {/* Loading skeleton */}
                                    {loading && loadingSkeleton}

                                    {/* Error state */}
                                    {error && (
                                        <TableRow>
                                            <TableCell colSpan={9}>
                                                <Alert severity="error">
                                                    {formatMessage({ defaultMessage: "Error loading transactions" })}
                                                </Alert>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                    {/* Table rows */}
                                    {transactions?.map((transaction) => (
                                        <TableRow key={transaction.id}>
                                            <StickyCell>{transaction.supplier?.name}</StickyCell>
                                            <NoWrapCell align="right">
                                                {formatNumber(transaction.amount || 0, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) + (transaction.currency ? ` ${transaction.currency}` : "")}
                                            </NoWrapCell>
                                            <NoWrapCell>{dayjs(transaction.date).format("MMM DD YYYY")}</NoWrapCell>
                                            <NoWrapCell>{transaction.accountNumber ?? "-"}</NoWrapCell>
                                            <NoWrapCell>{transaction.accountName ?? "-"}</NoWrapCell>
                                            <NoWrapCell>{transaction.invoiceId ?? "-"}</NoWrapCell>
                                            <NoWrapCell>{transaction.invoiceDescription ?? "-"}</NoWrapCell>
                                            <NoWrapCell>
                                                {dayjs(transaction.invoiceDate).format("MMM DD YYYY")}
                                            </NoWrapCell>
                                            <NoWrapCell>{dayjs(transaction.dueDate).format("MMM DD YYYY")}</NoWrapCell>
                                            <NoWrapCell>
                                                {transaction.import ? (
                                                    <Stack spacing={0.5}>
                                                        <Typography variant="textSm" fontWeight={500}>
                                                            {transaction.import.dataRepository?.name}
                                                        </Typography>
                                                        <Typography
                                                            variant="textXs"
                                                            color="text.textBody"
                                                            sx={{ cursor: "pointer" }}
                                                            onClick={() => handleCopyImportId(transaction.import?.id)}
                                                        >
                                                            <Clipboard fontSize="inherit" /> {transaction.import?.id}
                                                        </Typography>
                                                    </Stack>
                                                ) : (
                                                    "-"
                                                )}
                                            </NoWrapCell>
                                            <NoWrapCell>
                                                {dayjs(transaction.createdAt).format("MMM DD YYYY")}
                                            </NoWrapCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" p={1}>
                            <TablePagination
                                count={total ?? 0}
                                page={currentPage}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Stack>
                    </TableContainer>
                </Stack>
            </DialogContent>

            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={formatMessage({ defaultMessage: "Import ID copied to clipboard" })}
            />
        </Dialog>
    );
}
