import { ArrowRight } from "@ignite-analytics/icons";
import { Chip, Link, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { formatCompactCurrency } from "@/lib/format";
import { useCurrencyCode } from "@/providers/CurrencyProvider";

import { EmissionFactorCoachMark } from "./EmissionFactorCoachmark";

type EmissionFactorDetailProps = {
    emissionFactorCategory: string[];
    emissionFactorRegion: string;
    emissionFactorYear: number;
    emissionFactorInDisplayCurrency: number;
    relatedSpend: number;
};

export const EmissionFactorDetail: React.FC<EmissionFactorDetailProps> = ({
    emissionFactorCategory,
    emissionFactorRegion,
    emissionFactorYear,
    emissionFactorInDisplayCurrency,
    relatedSpend,
}) => {
    const { formatMessage } = useIntl();
    const currency = useCurrencyCode();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    return (
        <Paper>
            <Stack margin={2} direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Stack spacing={1}>
                    {emissionFactorCategory.length > 0 && (
                        <Stack direction="row" display="inline-flex">
                            <Typography variant="textSm" fontWeight={500}>
                                {emissionFactorCategory[0]}
                            </Typography>
                            {emissionFactorCategory.length > 1 && (
                                <>
                                    <ArrowRight sx={{ margin: "0 4px" }} />
                                    <Typography variant="textSm" fontWeight={500}>
                                        {emissionFactorCategory[1]}
                                    </Typography>
                                </>
                            )}
                        </Stack>
                    )}
                    <Stack direction="row" display="inline-flex" alignItems="center" spacing={1}>
                        <Tooltip
                            title={formatMessage({
                                defaultMessage: "Spend for this category",
                                description: "Emission factor spend tooltip",
                            })}
                        >
                            <Chip label={formatCompactCurrency(relatedSpend, currency)} color="primary" size="small" />
                        </Tooltip>
                        <Chip
                            label={`${emissionFactorInDisplayCurrency.toFixed(4)} kg CO₂e/${currency}`}
                            size="small"
                        />
                        <Typography variant="textSm">
                            {formatMessage({
                                defaultMessage: "This emission factor is for",
                                description: "Emission factor detail description",
                            })}{" "}
                            <span style={{ fontWeight: 500 }}>{emissionFactorRegion}</span>{" "}
                            {formatMessage({ defaultMessage: "and from" })}{" "}
                            <span style={{ fontWeight: 500 }}>{emissionFactorYear}</span>
                        </Typography>
                    </Stack>
                </Stack>
                <Link href={undefined} target="_blank" underline="none" onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <Typography variant="textSm" color="text.text-tertiary">
                        {formatMessage({ defaultMessage: "Learn more" })}
                    </Typography>
                </Link>
                <EmissionFactorCoachMark
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                />
            </Stack>
        </Paper>
    );
};
