import { getDesignTokens, responsiveFontSizes } from "@ignite-analytics/theme";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { deDE, enUS, nbNO } from "@mui/material/locale";
import { useMemo } from "react";
import { IntlProvider } from "react-intl";

import { ApolloProvider } from "@/lib/apollo";
import { LocaleMessages } from "@/lib/i18n";
import { AppProps } from "@/types";

import { AlertProvider } from "./Alerts";
import { CurrencyProvider } from "./CurrencyProvider/provider";
import { UserContextProvider } from "./UserContext";

type Props = {
    children?: React.ReactNode;
    messages: LocaleMessages;
} & AppProps;

function getMuiLocale(locale: string) {
    switch (locale) {
        case "nb-NO":
            return nbNO;
        case "de-DE":
            return deDE;
        default:
            return enUS;
    }
}

function customErrorFunction(err: Error) {
    console.warn(err);
    return;
}

export const AppProvider: React.FC<Props> = ({ children, theme = "ignite-riddle", locale, messages }) => {
    const designTokens = useMemo(() => getDesignTokens(theme, "light"), [theme]);
    const localization = useMemo(() => getMuiLocale(locale), [locale]);

    return (
        <UserContextProvider>
            <ThemeProvider theme={responsiveFontSizes(createTheme(designTokens, localization, { name: theme }))}>
                <CssBaseline />
                <IntlProvider messages={messages} locale={locale} key={locale} onError={customErrorFunction}>
                    <ApolloProvider>
                        <AlertProvider>
                            <CurrencyProvider>{children}</CurrencyProvider>
                        </AlertProvider>
                    </ApolloProvider>
                </IntlProvider>
            </ThemeProvider>
        </UserContextProvider>
    );
};
