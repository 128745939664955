import { useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql/gql";
import { SupplierCustomFieldType, SupplierInfo_SupplierFragment } from "@/gql/graphql";
import { track } from "@/lib/track";

import { IgniteEstimateChip, ManualStatusChip } from "../../../../Common/StatusChip";

import { AboutSupplierModal } from "./AboutSupplierModal";
import { GeoRisk } from "./RiskFields/GeoRisk";
import { IndustryRisk } from "./RiskFields/IndustryRisk";
import { ProductionCountries } from "./RiskFields/ProductionCountries";
import { InfoRow, isValidDate } from "./utils";

graphql(`
    fragment SupplierInfo_Supplier on Supplier {
        id
        name
        country
        orgNumber
        nace
        hasCodeOfConduct
        socialRiskScore {
            value
        }
        productionCountries
        onboarding {
            status
            approverId
            evaluatedAt
        }
        risk {
            social
            industry
            geography
        }
        ...AboutSupplierModal_Supplier
    }

    fragment SupplierInfo_RoleUser on RoleUser {
        id
        firstName
        lastName
    }
`);

const getApproverUserQuery = graphql(`
    query getUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...SupplierInfo_RoleUser
            }
        }
    }
`);

interface SupplierInfoProps {
    supplier: SupplierInfo_SupplierFragment;
    isEditor: boolean;
}

export const SupplierInfo: React.FC<SupplierInfoProps> = ({ supplier, isEditor }) => {
    const [edit, setEdit] = useState(false);
    const { formatMessage } = useIntl();
    const [showAll, setShowAll] = useState(false);
    const [approver, setApprover] = useState("");
    const onboardingEnabled = useFeatureToggle("onboarding-enabled", true);
    const newSocialRisk = useFeatureToggle("new-social-risk", false);
    useQuery(getApproverUserQuery, {
        variables: {
            input: {
                userIds: [supplier.onboarding?.approverId ?? ""],
            },
        },
        skip: !supplier.onboarding?.approverId || supplier.onboarding?.approverId === "",
        onCompleted: (data) => {
            if (data.getUsers.result.length !== 1) return;
            setApprover(`${data.getUsers.result[0].firstName} ${data.getUsers.result[0].lastName}`);
        },
    });

    const onboardingDate = supplier.onboarding?.evaluatedAt ?? "";
    const isValidOnboardingDate = isValidDate(onboardingDate);

    return (
        <Card>
            <CardHeader
                title={
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <Button
                            type="button"
                            color="secondary"
                            size="small"
                            onClick={() => {
                                track("Supplier Profile: Edit Supplier Info Opened");
                                setEdit(true);
                                setShowAll((prev) => !prev);
                            }}
                        >
                            <FormattedMessage defaultMessage="Edit" description="Edit button" />
                        </Button>
                    </Stack>
                }
            />
            <CardContent sx={{ paddingTop: 0 }}>
                <Typography variant="textMd" fontWeight={500}>
                    <FormattedMessage defaultMessage="About" description="supplier information header" />
                </Typography>
                <Stack spacing={2}>
                    <Grid container rowSpacing={2}>
                        <InfoRow
                            name={formatMessage({ defaultMessage: "Country", description: "Country name" })}
                            value={supplier.country ?? ""}
                            type={SupplierCustomFieldType.Text}
                        />
                        <InfoRow
                            name={formatMessage({ defaultMessage: "Industry", description: "Industry name" })}
                            value={supplier.nace ?? ""}
                            type={SupplierCustomFieldType.Nace}
                        />
                        <InfoRow
                            name={formatMessage({ defaultMessage: "VAT id", description: "VAT id name" })}
                            value={supplier.orgNumber ?? ""}
                            type={SupplierCustomFieldType.Text}
                        />
                    </Grid>
                    <Divider />
                    {supplier.onboarding && onboardingEnabled && (
                        <>
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Onboarding" description="Onboarding info header" />
                            </Typography>
                            <Grid
                                container
                                rowSpacing={2}
                                sx={{
                                    "& .MuiGrid-item:nth-of-type(1), & .MuiGrid-item:nth-of-type(2)": { paddingTop: 0 },
                                }}
                            >
                                <InfoRow
                                    name={formatMessage({ defaultMessage: "Approver", description: "Approver name" })}
                                    value={
                                        approver
                                            ? approver
                                            : formatMessage({
                                                  defaultMessage: "N/A",
                                                  description: "Onboarding approver placeholder",
                                              })
                                    }
                                    type={SupplierCustomFieldType.Text}
                                />
                                <InfoRow
                                    name={formatMessage({
                                        defaultMessage: "Approved at",
                                        description: "Approved at name",
                                    })}
                                    value={
                                        isValidOnboardingDate
                                            ? onboardingDate
                                            : formatMessage({
                                                  defaultMessage: "N/A",
                                                  description: "Onboarding approved at placeholder",
                                              })
                                    }
                                    type={
                                        isValidOnboardingDate
                                            ? SupplierCustomFieldType.Date
                                            : SupplierCustomFieldType.Text
                                    }
                                />
                                <InfoRow
                                    name={formatMessage({
                                        defaultMessage: "Onboarding status",
                                        description: "Onboarding status name",
                                    })}
                                    value={supplier.onboarding.status}
                                    type="onboarding"
                                />
                            </Grid>
                            <Divider />
                        </>
                    )}
                    <Typography variant="textMd" fontWeight={500}>
                        <FormattedMessage defaultMessage="Social Risk" description="Social Risk info header" />
                    </Typography>
                    <Grid
                        container
                        rowSpacing={2}
                        sx={{ "& .MuiGrid-item:nth-of-type(1), & .MuiGrid-item:nth-of-type(2)": { paddingTop: 0 } }}
                    >
                        {newSocialRisk ? (
                            <InfoRow
                                name={formatMessage({ defaultMessage: "Estimated Risk", description: "Social risk" })}
                                value=""
                                type={SupplierCustomFieldType.Risk}
                                component={
                                    supplier.socialRiskScore?.value ? (
                                        <ManualStatusChip status={supplier.socialRiskScore.value} />
                                    ) : (
                                        <IgniteEstimateChip status={supplier.risk?.social} />
                                    )
                                }
                            />
                        ) : (
                            <InfoRow
                                name={formatMessage({ defaultMessage: "Social risk", description: "Social risk" })}
                                value={supplier.risk?.social ?? ""}
                                type={SupplierCustomFieldType.Risk}
                            />
                        )}
                        <InfoRow
                            name={formatMessage({ defaultMessage: "Geography risk", description: "Geography risk" })}
                            value=""
                            type={SupplierCustomFieldType.Risk}
                            component={
                                <GeoRisk
                                    country={
                                        supplier.productionCountries.length > 0 &&
                                        supplier.productionCountries[0] !== ""
                                            ? supplier.productionCountries[0]
                                            : supplier.country ?? ""
                                    }
                                />
                            }
                        />
                        {newSocialRisk && (
                            <InfoRow
                                name={formatMessage({
                                    defaultMessage: "Production countries",
                                    description: "Production countries",
                                })}
                                value=""
                                type={SupplierCustomFieldType.Text}
                                component={<ProductionCountries countries={supplier.productionCountries ?? []} />}
                            />
                        )}
                        <InfoRow
                            name={formatMessage({ defaultMessage: "Industry risk", description: "Industry risk" })}
                            value=""
                            type={SupplierCustomFieldType.Risk}
                            component={<IndustryRisk nace={supplier.nace ?? ""} />}
                        />
                    </Grid>
                </Stack>
            </CardContent>
            <CardActions>
                <Stack width="100%">
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => {
                            track("Supplier Profile: Show More Supplier Info Opened");
                            setShowAll((prev) => !prev);
                        }}
                    >
                        <FormattedMessage defaultMessage="Show more" description="Show more button supplier info" />
                    </Button>
                </Stack>
            </CardActions>
            <AboutSupplierModal
                open={showAll}
                onClose={() => {
                    setEdit(false);
                    setShowAll(false);
                }}
                supplier={supplier}
                approver={approver}
                isEditor={isEditor}
                edit={edit}
                setEdit={setEdit}
                onboardingEnabled={onboardingEnabled}
            />
        </Card>
    );
};
