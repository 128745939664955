import { Grid2, Typography } from "@mui/material";

interface AboutLabelProps {
    label: React.ReactNode;
}

function AboutLabel(props: React.PropsWithChildren<AboutLabelProps>) {
    return (
        <Grid2
            p={1}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            width="100%"
            spacing={1}
        >
            <Grid2 size={6} textOverflow="ellipsis" overflow="hidden">
                <Typography variant="textSm" fontWeight={600} noWrap>
                    {props.label}
                </Typography>
            </Grid2>
            <Grid2 size={6}>{props.children}</Grid2>
        </Grid2>
    );
}

export { AboutLabel };
