import { queryOptions, useQuery } from "@tanstack/react-query";

/**
 * privileged is used for admin permissions
 */
type Relation = {
    object: "general" | "privileged";
    relation: "read" | "write";
};

type PermissionNamespace = "general" | "carbon" | "suppliers" | "contracts";

async function checkPermissions(namespace: PermissionNamespace, relation: Relation): Promise<boolean> {
    const res = await fetch(`${import.meta.env.REACT_APP_AUTH_SIDECAR_API_URL}/permissions/check/${namespace}`, {
        method: "POST",
        body: JSON.stringify([relation]),
        credentials: "include",
    });
    const data: boolean[] = await res.json();
    return Array.isArray(data) ? data[0] : false;
}

async function hasPermission(namespace: PermissionNamespace, options: Relation): Promise<boolean | undefined> {
    const permissions = await checkPermissions(namespace, options);
    return permissions;
}

function hasPermissionQueryOptions(namespace: PermissionNamespace, options: Relation) {
    const tenant = localStorage.getItem("tenant") || "";
    return queryOptions({
        queryKey: ["permissions", tenant, namespace, options],
        queryFn: async () => hasPermission(namespace, options),
        staleTime: 1000 * 60 * 2,
    });
}

function usePermission(namespace: PermissionNamespace, options: Relation): boolean {
    const { data } = useQuery(hasPermissionQueryOptions(namespace, options));

    return data ?? false;
}

export function useGeneralPermission(relation: Relation) {
    return usePermission("general", relation);
}

export function useSupplierPermission(relation: Relation) {
    return usePermission("suppliers", relation);
}

export function useContractsPermission(relation: Relation) {
    return usePermission("contracts", relation);
}

export function useCarbonPermission(relation: Relation) {
    return usePermission("carbon", relation);
}
