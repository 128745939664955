import { User } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Contact } from "./ContactCard";

interface AddContactDialogProps {
    open: boolean;
    onClose: () => void;
    onAddContact: (contact: {
        id?: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        position?: string;
    }) => void;
    editContact?: Contact;
}

export const AddContactDialog: React.FC<AddContactDialogProps> = ({ open, onClose, onAddContact, editContact }) => {
    const { formatMessage } = useIntl();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [position, setPosition] = useState("");

    useEffect(() => {
        if (editContact) {
            setFirstName(editContact.firstName);
            setLastName(editContact.lastName);
            setEmail(editContact.email);
            setPhoneNumber(editContact.phoneNumber);
            setPosition(editContact.position || "");
        }
    }, [editContact]);

    const handleSubmit = () => {
        onAddContact({
            id: editContact?.id,
            firstName,
            lastName,
            email,
            phoneNumber,
            position: position || undefined,
        });
        resetForm();
        onClose();
    };

    const resetForm = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setPosition("");
    };

    const handleCancel = () => {
        resetForm();
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Stack direction="row" spacing={2} alignItems="center">
                    <User />
                    <Typography variant="h6">
                        {editContact ? (
                            <FormattedMessage defaultMessage="Edit contact" />
                        ) : (
                            <FormattedMessage defaultMessage="Add contact" />
                        )}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid2 container spacing={3} mt={1}>
                    <Grid2 size={6}>
                        <Typography variant="body2" mb={1}>
                            <FormattedMessage defaultMessage="First name" />
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder={formatMessage({ defaultMessage: "Enter first name" })}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="body2" mb={1}>
                            <FormattedMessage defaultMessage="Last name" />
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder={formatMessage({ defaultMessage: "Enter last name" })}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="body2" mb={1}>
                            <FormattedMessage defaultMessage="Email" />
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder={formatMessage({ defaultMessage: "Enter email address" })}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="body2" mb={1}>
                            <FormattedMessage defaultMessage="Phone number" />
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder={formatMessage({ defaultMessage: "Enter phone number" })}
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="body2" mb={1}>
                            <FormattedMessage defaultMessage="Position (optional)" />
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder={formatMessage({ defaultMessage: "Enter position" })}
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel} fullWidth>
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    fullWidth
                    disabled={!firstName || !lastName || !email || !phoneNumber}
                >
                    {editContact ? (
                        <FormattedMessage defaultMessage="Save changes" />
                    ) : (
                        <FormattedMessage defaultMessage="Add contact" />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
