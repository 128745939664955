import { CircleSolid } from "@ignite-analytics/icons";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { countryCodes } from "../countryCode";
import { getCountryLabel } from "../utils";

interface GeoRiskProps {
    country: string;
}

export const GeoRisk: React.FC<GeoRiskProps> = ({ country }) => {
    const theme = useTheme();
    const riskCountry = countryCodes.find((c) => c.code === country);
    const color = (() => {
        switch (riskCountry?.score) {
            case "1":
            case "2":
                return theme.palette.tokens?.icon.iconSuccess;
            case "3":
            case "4":
                return theme.palette.tokens?.icon.iconWarning;
            case "5":
            case "6":
                return theme.palette.tokens?.icon.iconError;
            default:
                return theme.palette.tokens?.icon.iconGray;
        }
    })();
    const label = riskCountry ? getCountryLabel(riskCountry) : undefined;
    return (
        <Stack direction="row" alignItems="center">
            {country ? (
                <Tooltip title={label} followCursor>
                    <Stack direction="row" alignItems="center" spacing={1} maxWidth="100%" height="100%">
                        <CircleSolid sx={{ color, height: "8px", width: "8px" }} />
                        <Typography
                            variant="textSm"
                            noWrap
                            sx={{ textOverflow: "ellipsis", overflow: "ellipsis" }}
                        >{`${riskCountry?.code}: ${label}`}</Typography>
                    </Stack>
                </Tooltip>
            ) : (
                <Typography>
                    <FormattedMessage defaultMessage="N/A" />
                </Typography>
            )}
        </Stack>
    );
};
