export interface NACEOption {
    code: string;
    score: string;
    name_no: string;
    short_name_no: string;
    name_en: string;
    short_name_en: string;
}

export const NACEOptions: NACEOption[] = [
    {
        code: "01.11",
        score: "high",
        name_no: "Dyrking av korn (unntatt ris), belgvekster og oljeholdige vekster",
        short_name_no: "Dyrking av korn, belgvekster mv.",
        name_en: "Growing of cereals (except rice), leguminous crops and oil seeds",
        short_name_en: "Growing of cereals, crops etc.",
    },
    {
        code: "01.12",
        score: "high",
        name_no: "Dyrking av ris",
        short_name_no: "Dyrking av ris",
        name_en: "Growing of rice",
        short_name_en: "Growing of rice",
    },
    {
        code: "01.13",
        score: "high",
        name_no: "Dyrking av grønnsaker, meloner, rot- og knollvekster",
        short_name_no: "Dyrking av grønnsaker, poteter mv.",
        name_en: "Growing of vegetables and melons, roots and tubers",
        short_name_en: "Growing of vegetables etc.",
    },
    {
        code: "01.14",
        score: "high",
        name_no: "Dyrking av sukkerrør",
        short_name_no: "Dyrking av sukkerrør",
        name_en: "Growing of sugar cane",
        short_name_en: "Growing of sugar cane",
    },
    {
        code: "01.15",
        score: "high",
        name_no: "Dyrking av tobakk",
        short_name_no: "Dyrking av tobakk",
        name_en: "Growing of tobacco",
        short_name_en: "Growing of tobacco",
    },
    {
        code: "01.16",
        score: "high",
        name_no: "Dyrking av fibervekster",
        short_name_no: "Dyrking av fibervekster",
        name_en: "Growing of fibre crops",
        short_name_en: "Growing of fibre crops",
    },
    {
        code: "01.19",
        score: "high",
        name_no: "Dyrking av ettårige vekster ellers",
        short_name_no: "Dyrking av ettårige vekster ellers",
        name_en: "Growing of other non-perennial crops",
        short_name_en: "Growing of other non-perennial crops",
    },
    {
        code: "01.21",
        score: "high",
        name_no: "Dyrking av druer",
        short_name_no: "Dyrking av druer",
        name_en: "Growing of grapes",
        short_name_en: "Growing of grapes",
    },
    {
        code: "01.22",
        score: "high",
        name_no: "Dyrking av tropiske og subtropiske frukter",
        short_name_no: "Dyrking av trop./subtrop. frukter",
        name_en: "Growing of tropical and subtropical fruits",
        short_name_en: "Growing of trop./subtrop. fruits",
    },
    {
        code: "01.23",
        score: "high",
        name_no: "Dyrking av sitrusfrukter",
        short_name_no: "Dyrking av sitrusfrukter",
        name_en: "Growing of citrus fruits",
        short_name_en: "Growing of citrus fruits",
    },
    {
        code: "01.24",
        score: "high",
        name_no: "Dyrking av kjernefrukter og steinfrukter",
        short_name_no: "Dyrking av kjerne- og steinfrukter",
        name_en: "Growing of pome fruits and stone fruits",
        short_name_en: "Growing of pome fruits/stone fruits",
    },
    {
        code: "01.25",
        score: "high",
        name_no: "Dyrking av annen frukt som vokser på trær eller busker samt nøtter",
        short_name_no: "Dyrking av bær og nøtter mv.",
        name_en: "Growing of other tree and bush fruits and nuts",
        short_name_en: "Grow. of other fruits, nuts etc.",
    },
    {
        code: "01.26",
        score: "high",
        name_no: "Dyrking av oljeholdige frukter",
        short_name_no: "Dyrking av oljeholdige frukter",
        name_en: "Growing of oleaginous fruits",
        short_name_en: "Growing of oleaginous fruits",
    },
    {
        code: "01.27",
        score: "high",
        name_no: "Dyrking av vekster for produksjon av drikkevarer",
        short_name_no: "Dyrk. vekster for prod. av drikkev.",
        name_en: "Growing of beverage crops",
        short_name_en: "Growing of beverage crops",
    },
    {
        code: "01.28",
        score: "high",
        name_no: "Dyrking av krydder og aromatiske, medisinske og farmasøytiske vekster",
        short_name_no: "Dyrking av krydder mv.",
        name_en: "Growing of spices, aromatic, drug and pharmaceutical crops",
        short_name_en: "Growing of spices etc.",
    },
    {
        code: "01.29",
        score: "high",
        name_no: "Dyrking av flerårige vekster ellers",
        short_name_no: "Dyrking av flerårige vekster ellers",
        name_en: "Growing of other perennial crops",
        short_name_en: "Growing of other perennial crops",
    },
    {
        code: "01.30",
        score: "medium",
        name_no: "Planteformering",
        short_name_no: "Planteformering",
        name_en: "Plant propagation",
        short_name_en: "Plant propagation",
    },
    {
        code: "01.41",
        score: "medium",
        name_no: "Melkeproduksjon på storfe",
        short_name_no: "Melkeproduksjon på storfe",
        name_en: "Raising of dairy cattle",
        short_name_en: "Raising of dairy cattle",
    },
    {
        code: "01.42",
        score: "medium",
        name_no: "Oppdrett av annet storfe",
        short_name_no: "Oppdrett av annet storfe",
        name_en: "Raising of other cattle and buffaloes",
        short_name_en: "Raising of other cattle/buffaloes",
    },
    {
        code: "01.43",
        score: "medium",
        name_no: "Oppdrett av hester og andre dyr av hestefamilien",
        short_name_no: "Oppdrett av hester, esler o.l.",
        name_en: "Raising of horses and other equines",
        short_name_en: "Raising of horses/other equines",
    },
    {
        code: "01.44",
        score: "medium",
        name_no: "Oppdrett av kameler og andre kameldyr",
        short_name_no: "Oppdrett av kameler og kameldyr el.",
        name_en: "Raising of camels and camelids",
        short_name_en: "Raising of camels and camelids",
    },
    {
        code: "01.45",
        score: "medium",
        name_no: "Saue- og geitehold",
        short_name_no: "Saue- og geitehold",
        name_en: "Raising of sheep and goats",
        short_name_en: "Raising of sheep and goats",
    },
    {
        code: "01.46",
        score: "medium",
        name_no: "Svinehold",
        short_name_no: "Svinehold",
        name_en: "Raising of swine/pigs",
        short_name_en: "Raising of swine/pigs",
    },
    {
        code: "01.47",
        score: "medium",
        name_no: "Fjørfehold",
        short_name_no: "Fjørfehold",
        name_en: "Raising of poultry",
        short_name_en: "Raising of poultry",
    },
    {
        code: "01.49",
        score: "low",
        name_no: "Husdyrhold ellers",
        short_name_no: "Husdyrhold ellers",
        name_en: "Raising of other animals",
        short_name_en: "Raising of other animals",
    },
    {
        code: "01.50",
        score: "high",
        name_no: "Kombinert husdyrhold og planteproduksjon",
        short_name_no: "Komb. husdyrhold og planteprod.",
        name_en: "Mixed farming",
        short_name_en: "Mixed farming",
    },
    {
        code: "01.61",
        score: "medium",
        name_no: "Tjenester tilknyttet planteproduksjon",
        short_name_no: "Tjenester tilknyttet planteprod.",
        name_en: "Support activities for crop production",
        short_name_en: "Supp. act. for crop production",
    },
    {
        code: "01.62",
        score: "medium",
        name_no: "Tjenester tilknyttet husdyrhold",
        short_name_no: "Tjenester tilknyttet husdyrhold",
        name_en: "Support activities for animal production",
        short_name_en: "Supp. act. for animal production",
    },
    {
        code: "01.63",
        score: "medium",
        name_no: "Etterbehandling av vekster etter innhøsting",
        short_name_no: "Etterbeh. av vekster etter innhøst.",
        name_en: "Post-harvest crop activities",
        short_name_en: "Post-harvest crop activities",
    },
    {
        code: "01.64",
        score: "medium",
        name_no: "Behandling av såfrø",
        short_name_no: "Behandling av såfrø",
        name_en: "Seed processing for propagation",
        short_name_en: "Seed processing for propagation",
    },
    {
        code: "01.70",
        score: "medium",
        name_no: "Jakt, viltstell og tjenester tilknyttet jakt og viltstell",
        short_name_no: "Jakt, viltstell og tilkn. tjenester",
        name_en: "Hunting, trapping and related service activities",
        short_name_en: "Hunting, trapping and rel. serv. act",
    },
    {
        code: "02.10",
        score: "high",
        name_no: "Skogskjøtsel og andre skogbruksaktiviteter",
        short_name_no: "Skogskjøtsel, andre skogbruksakt.",
        name_en: "Silviculture and other forestry activities",
        short_name_en: "Silviculture, other forestry act.",
    },
    {
        code: "02.20",
        score: "high",
        name_no: "Avvirkning",
        short_name_no: "Avvirkning",
        name_en: "Logging",
        short_name_en: "Logging",
    },
    {
        code: "02.30",
        score: "medium",
        name_no: "Innsamling av viltvoksende produkter av annet enn tre",
        short_name_no: "Innsaml. av sopp, bær, mose etc.",
        name_en: "Gathering of wild growing non-wood products",
        short_name_en: "Gathering of mushrooms, berries etc.",
    },
    {
        code: "02.40",
        score: "medium",
        name_no: "Tjenester tilknyttet skogbruk",
        short_name_no: "Tjenester tilknyttet skogbruk",
        name_en: "Support services to forestry",
        short_name_en: "Support services to forestry",
    },
    {
        code: "03.11",
        score: "high",
        name_no: "Hav- og kystfiske og fangst",
        short_name_no: "Hav- og kystfiske og fangst",
        name_en: "Marine fishing",
        short_name_en: "Marine fishing",
    },
    {
        code: "03.12",
        score: "medium",
        name_no: "Ferskvannsfiske",
        short_name_no: "Ferskvannsfiske",
        name_en: "Freshwater fishing",
        short_name_en: "Freshwater fishing",
    },
    {
        code: "03.21",
        score: "medium",
        name_no: "Hav- og kystbasert akvakultur",
        short_name_no: "Hav- og kystbasert akvakultur",
        name_en: "Marine aquaculture",
        short_name_en: "Marine aquaculture",
    },
    {
        code: "03.22",
        score: "medium",
        name_no: "Ferskvannsbasert akvakultur",
        short_name_no: "Ferskvannsbasert akvakultur",
        name_en: "Freshwater aquaculture",
        short_name_en: "Freshwater aquaculture",
    },
    {
        code: "05.10",
        score: "high",
        name_no: "Bryting av steinkull",
        short_name_no: "Bryting av steinkull",
        name_en: "Mining of hard coal",
        short_name_en: "Mining of hard coal",
    },
    {
        code: "05.20",
        score: "high",
        name_no: "Bryting av brunkull",
        short_name_no: "Bryting av brunkull",
        name_en: "Mining of lignite",
        short_name_en: "Mining of lignite",
    },
    {
        code: "06.10",
        score: "high",
        name_no: "Utvinning av råolje",
        short_name_no: "Utvinning av råolje",
        name_en: "Extraction of crude petroleum",
        short_name_en: "Extraction of crude petroleum",
    },
    {
        code: "06.20",
        score: "high",
        name_no: "Utvinning av naturgass",
        short_name_no: "Utvinning av naturgass",
        name_en: "Extraction of natural gas",
        short_name_en: "Extraction of natural gas",
    },
    {
        code: "07.10",
        score: "high",
        name_no: "Bryting av jernmalm",
        short_name_no: "Bryting av jernmalm",
        name_en: "Mining of iron ores",
        short_name_en: "Mining of iron ores",
    },
    {
        code: "07.21",
        score: "high",
        name_no: "Bryting av uran- og thoriummalm",
        short_name_no: "Bryting av uran- og thoriummalm",
        name_en: "Mining of uranium and thorium ores",
        short_name_en: "Mining of uran and thorium ores",
    },
    {
        code: "07.29",
        score: "high",
        name_no: "Bryting av ikke-jernholdig malm ellers",
        short_name_no: "Bryting av annen ikke-jernh. malm",
        name_en: "Mining of other non-ferrous metal ores",
        short_name_en: "Mining of other non-ferr metal ores",
    },
    {
        code: "08.11",
        score: "high",
        name_no: "Bryting av stein til bygge- og anleggsvirksomhet, kalkstein, gips, kritt og skifer",
        short_name_no: "Bryt. bygn.-/kalkstein/skifer mv.",
        name_en: "Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate",
        short_name_en: "Quarry. build. stone, limestone etc.",
    },
    {
        code: "08.12",
        score: "high",
        name_no: "Utvinning fra grus- og sandtak, og utvinning av leire og kaolin",
        short_name_no: "Utv. fra grus- og sandtak, leire mv.",
        name_en: "Operation of gravel and sand pits; mining of clays and kaolin",
        short_name_en: "Operation of gravel and sand pits",
    },
    {
        code: "08.91",
        score: "high",
        name_no: "Bryting og utvinning av kjemiske mineraler og gjødselsmineraler",
        short_name_no: "Utv. kjem. mineraler/gjødselsmin.",
        name_en: "Mining of chemical and fertiliser minerals",
        short_name_en: "Mining of chem./fertiliser minerals",
    },
    {
        code: "08.92",
        score: "high",
        name_no: "Stikking av torv",
        short_name_no: "Stikking av torv",
        name_en: "Extraction of peat",
        short_name_en: "Extraction of peat",
    },
    {
        code: "08.93",
        score: "high",
        name_no: "Utvinning av salt",
        short_name_no: "Utvinning av salt",
        name_en: "Extraction of salt",
        short_name_en: "Extraction of salt",
    },
    {
        code: "08.99",
        score: "high",
        name_no: "Annen bryting og utvinning ikke nevnt annet sted",
        short_name_no: "Annen bryting og utvinning",
        name_en: "Other mining and quarrying n.e.c.",
        short_name_en: "Other mining and quarrying n.e.c.",
    },
    {
        code: "09.10",
        score: "high",
        name_no: "Tjenester tilknyttet utvinning av råolje og naturgass",
        short_name_no: "Utvinningstjenester",
        name_en: "Support activities for petroleum and natural gas extraction",
        short_name_en: "Supp. for petro/natural gas extrac",
    },
    {
        code: "09.90",
        score: "high",
        name_no: "Tjenester tilknyttet annen bergverksdrift",
        short_name_no: "Bergverkstjenester",
        name_en: "Support activities for other mining and quarrying",
        short_name_en: "Supp. for other mining/quarrying",
    },
    {
        code: "10.11",
        score: "high",
        name_no: "Bearbeiding og konservering av kjøtt",
        short_name_no: "Kjøttbearbeiding og konservering",
        name_en: "Processing and preserving of meat",
        short_name_en: "Processing and preserving of meat",
    },
    {
        code: "10.12",
        score: "high",
        name_no: "Bearbeiding og konservering av fjørfekjøtt",
        short_name_no: "Fjørfekjøttbearb. og konservering",
        name_en: "Processing and preserving of poultry meat",
        short_name_en: "Process./preserv. of poultry meat",
    },
    {
        code: "10.13",
        score: "high",
        name_no: "Produksjon av kjøtt- og fjørfevarer",
        short_name_no: "Kjøtt- og fjørfevareproduksjon",
        name_en: "Production of meat and poultry meat products",
        short_name_en: "Prod. of meat/poultry meat prod.",
    },
    {
        code: "10.20",
        score: "medium",
        name_no: "Bearbeiding og konservering av fisk, skalldyr og bløtdyr",
        short_name_no: "Bearbeiding fisk, skalldyr, bløtdyr",
        name_en: "Processing and preserving of fish, crustaceans and molluscs",
        short_name_en: "Process. and preserv. of fish etc.",
    },
    {
        code: "10.31",
        score: "medium",
        name_no: "Bearbeiding og konservering av poteter",
        short_name_no: "Potetbearbeiding",
        name_en: "Processing and preserving of potatoes",
        short_name_en: "Process. and preserv. of potatoes",
    },
    {
        code: "10.32",
        score: "medium",
        name_no: "Produksjon av juice av frukt og grønnsaker",
        short_name_no: "Frukt- og grønnsakjuiceproduksjon",
        name_en: "Manufacture of fruit and vegetable juice",
        short_name_en: "Fruit and vegetable juice",
    },
    {
        code: "10.39",
        score: "medium",
        name_no: "Bearbeiding og konservering av frukt og grønnsaker ellers",
        short_name_no: "Frukt- og grønnsakbearbeiding el.",
        name_en: "Other processing and preserving of fruit and vegetables",
        short_name_en: "Processing of fruit, vegetables n.e.c.",
    },
    {
        code: "10.41",
        score: "medium",
        name_no: "Produksjon av oljer og fettstoffer",
        short_name_no: "Prod. av oljer og fettstoffer",
        name_en: "Manufacture of oils and fats",
        short_name_en: "Manufacture of oils and fats",
    },
    {
        code: "10.42",
        score: "medium",
        name_no: "Produksjon av margarin og lignende spiselige fettstoffer",
        short_name_no: "Margarinproduksjon m.m.",
        name_en: "Manufacture of margarine and similar edible fats",
        short_name_en: "Manuf. of margarine/sim. edible fats",
    },
    {
        code: "10.51",
        score: "medium",
        name_no: "Produksjon av meierivarer",
        short_name_no: "Meierivareproduksjon",
        name_en: "Operation of dairies and cheese making",
        short_name_en: "Oper. of dairies /cheese making",
    },
    {
        code: "10.52",
        score: "medium",
        name_no: "Produksjon av iskrem",
        short_name_no: "Iskremproduksjon",
        name_en: "Manufacture of ice cream",
        short_name_en: "Manufacture of ice cream",
    },
    {
        code: "10.61",
        score: "medium",
        name_no: "Produksjon av kornvarer",
        short_name_no: "Kornvareproduksjon",
        name_en: "Manufacture of grain mill products",
        short_name_en: "Manuf. of grain mill products",
    },
    {
        code: "10.62",
        score: "medium",
        name_no: "Produksjon av stivelse og stivelsesprodukter",
        short_name_no: "Prod. av stivelse og stivelseprod.",
        name_en: "Manufacture of starches and starch products",
        short_name_en: "Manuf. of starches/starch products",
    },
    {
        code: "10.71",
        score: "medium",
        name_no: "Produksjon av brød og ferske konditorvarer",
        short_name_no: "Brød og ferske konditorvarer",
        name_en: "Manufacture of bread; manufacture of fresh pastry goods and cakes",
        short_name_en: "Manuf. of bread/ fresh cakes etc.",
    },
    {
        code: "10.72",
        score: "medium",
        name_no: "Produksjon av kavringer, kjeks og konserverte konditorvarer",
        short_name_no: "Kavring- og kjeksprod. mv.",
        name_en: "Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes",
        short_name_en: "Manuf. of rusks, biscuits etc.",
    },
    {
        code: "10.73",
        score: "medium",
        name_no: "Produksjon av makaroni, nudler, couscous og lignende pastavarer",
        short_name_no: "Pastavareproduksjon",
        name_en: "Manufacture of macaroni, noodles, couscous and similar farinaceous products",
        short_name_en: "Manuf. of macaroni, noodles etc.",
    },
    {
        code: "10.81",
        score: "medium",
        name_no: "Produksjon av sukker",
        short_name_no: "Sukkerproduksjon",
        name_en: "Manufacture of sugar",
        short_name_en: "Manufacture of sugar",
    },
    {
        code: "10.82",
        score: "medium",
        name_no: "Produksjon av kakao, sjokolade og sukkervarer",
        short_name_no: "Kakao-, sjokolade- og sukkervareprod.",
        name_en: "Manufacture of cocoa, chocolate and sugar confectionery",
        short_name_en: "Manuf. of cocoa, chocolate etc.",
    },
    {
        code: "10.83",
        score: "medium",
        name_no: "Bearbeiding av te og kaffe",
        short_name_no: "Te- og kaffebearbeiding",
        name_en: "Processing of tea and coffee",
        short_name_en: "Processing of tea and coffee",
    },
    {
        code: "10.84",
        score: "medium",
        name_no: "Produksjon av smakstilsettingsstoffer og krydderier",
        short_name_no: "Smakstilsettingsstoffer og krydder",
        name_en: "Manufacture of condiments and seasonings",
        short_name_en: "Manuf. of condiments and seasonings",
    },
    {
        code: "10.85",
        score: "medium",
        name_no: "Produksjon av ferdigmat",
        short_name_no: "Ferdigmatproduksjon",
        name_en: "Manufacture of prepared meals and dishes",
        short_name_en: "Manuf. of prepared meat and dishes",
    },
    {
        code: "10.86",
        score: "medium",
        name_no: "Produksjon av homogeniserte matprodukter og diettmat",
        short_name_no: "Diettmat og homogeniserte matprod.",
        name_en: "Manufacture of homogenised food preparations and dietetic food",
        short_name_en: "Manuf. of dietetic food etc.",
    },
    {
        code: "10.89",
        score: "medium",
        name_no: "Produksjon av næringsmidler ikke nevnt annet sted",
        short_name_no: "Produksjon av næringsmidler el.",
        name_en: "Manufacture of other food products n.e.c.",
        short_name_en: "Manuf. of other products n.e.c.",
    },
    {
        code: "10.91",
        score: "medium",
        name_no: "Produksjon av fôrvarer til husdyrhold",
        short_name_no: "Fôrvareproduksjon til husdyr",
        name_en: "Manufacture of prepared feeds for farm animals",
        short_name_en: "Manuf. of prep. feeds for farm anim.",
    },
    {
        code: "10.92",
        score: "medium",
        name_no: "Produksjon av fôrvarer til kjæledyr",
        short_name_no: "Fôrvareproduksjon til kjæledyr",
        name_en: "Manufacture of prepared pet foods",
        short_name_en: "Manuf. of prepared pet foods",
    },
    {
        code: "11.01",
        score: "medium",
        name_no: "Destillering, rektifisering og blanding av sprit",
        short_name_no: "Destill. alkoholholdige drikkevarer",
        name_en: "Distilling, rectifying and blending of spirits",
        short_name_en: "Distil, rectify., blend. of spirits",
    },
    {
        code: "11.02",
        score: "medium",
        name_no: "Produksjon av vin",
        short_name_no: "Vinproduksjon",
        name_en: "Manufacture of wine from grape",
        short_name_en: "Manuf. of wine from grapes",
    },
    {
        code: "11.03",
        score: "medium",
        name_no: "Produksjon av sider og annen fruktvin",
        short_name_no: "Sider- og annen fruktvinproduksjon",
        name_en: "Manufacture of cider and other fruit wines",
        short_name_en: "Manuf. of cider/other fruit wines",
    },
    {
        code: "11.04",
        score: "medium",
        name_no: "Produksjon av andre ikke-destillerte gjærede drikkevarer",
        short_name_no: "Ikke-destill. gjærede drikkevarer",
        name_en: "Manufacture of other non-distilled fermented beverages",
        short_name_en: "Non-distill. fermented beverages",
    },
    {
        code: "11.05",
        score: "medium",
        name_no: "Produksjon av øl",
        short_name_no: "Ølproduksjon",
        name_en: "Manufacture of beer",
        short_name_en: "Manufacture of beer",
    },
    {
        code: "11.06",
        score: "medium",
        name_no: "Produksjon av malt",
        short_name_no: "Maltproduksjon",
        name_en: "Manufacture of malt",
        short_name_en: "Manufacture of malt",
    },
    {
        code: "11.07",
        score: "medium",
        name_no: "Produksjon av mineralvann, leskedrikker og annet vann på flaske",
        short_name_no: "Mineralvann- og leskedrikkproduksjon",
        name_en: "Manufacture of soft drinks; production of mineral waters and other bottled waters",
        short_name_en: "Prod. of mineral waters etc.",
    },
    {
        code: "12.00",
        score: "medium",
        name_no: "Produksjon av tobakksvarer",
        short_name_no: "Tobakksindustri",
        name_en: "Manufacture of tobacco products",
        short_name_en: "Tobacco products",
    },
    {
        code: "13.10",
        score: "high",
        name_no: "Bearbeiding og spinning av tekstilfibrer",
        short_name_no: "Bearb./spinning av tekstilfibrer",
        name_en: "Preparation and spinning of textile fibres",
        short_name_en: "Prep. and spinning of textile fibres",
    },
    {
        code: "13.20",
        score: "high",
        name_no: "Veving av tekstiler",
        short_name_no: "Veving av tekstiler",
        name_en: "Weaving of textiles",
        short_name_en: "Weaving of textiles",
    },
    {
        code: "13.30",
        score: "high",
        name_no: "Etterbehandling av tekstiler",
        short_name_no: "Etterbehandling av tekstiler",
        name_en: "Finishing of textiles",
        short_name_en: "Finishing of textiles",
    },
    {
        code: "13.91",
        score: "high",
        name_no: "Produksjon av stoffer av trikotasje",
        short_name_no: "Trikotasjestoffproduksjon",
        name_en: "Manufacture of knitted and crocheted fabrics",
        short_name_en: "Manuf. knitted and crocheted fabrics",
    },
    {
        code: "13.92",
        score: "high",
        name_no: "Produksjon av tekstilvarer, unntatt klær",
        short_name_no: "Tekstilvareproduksjon, unntatt klær",
        name_en: "Manufacture of made-up textile articles, except apparel",
        short_name_en: "Manuf. of made-up text. art. etc.",
    },
    {
        code: "13.93",
        score: "high",
        name_no: "Produksjon av gulvtepper, -matter og -ryer",
        short_name_no: "Gulvteppe-, matte- og ryeproduksjon",
        name_en: "Manufacture of carpets and rugs",
        short_name_en: "Manuf. of carpets and rugs",
    },
    {
        code: "13.94",
        score: "high",
        name_no: "Produksjon av tauverk og nett",
        short_name_no: "Tauverk- og nettproduksjon",
        name_en: "Manufacture of cordage, rope, twine and rugs",
        short_name_en: "Manuf. of cordage, rope etc.",
    },
    {
        code: "13.95",
        score: "high",
        name_no: "Produksjon av ikke-vevde tekstiler og tekstilvarer, unntatt klær",
        short_name_no: "Fiberproduksjon mv.",
        name_en: "Manufacture of non-wovens and articles made from non-wovens, except apparel",
        short_name_en: "Manuf. of non-wovens and -articles",
    },
    {
        code: "13.96",
        score: "high",
        name_no: "Produksjon av tekstiler til teknisk og industriell bruk",
        short_name_no: "Tekstiler til tekn./industriell bruk",
        name_en: "Manufacture of other technical and industrial textiles",
        short_name_en: "Manuf. of other tech./ind. textiles",
    },
    {
        code: "13.99",
        score: "high",
        name_no: "Produksjon av tekstiler ikke nevnt annet sted",
        short_name_no: "Tekstilproduksjon el.",
        name_en: "Manufacture of other textiles n.e.c.",
        short_name_en: "Manuf. of other textiles n.e.c.",
    },
    {
        code: "14.11",
        score: "high",
        name_no: "Produksjon av klær av lær",
        short_name_no: "Produksjon av klær av lær",
        name_en: "Manufacture of leather clothes",
        short_name_en: "Manuf. of leather clothes",
    },
    {
        code: "14.12",
        score: "high",
        name_no: "Produksjon av arbeidstøy",
        short_name_no: "Arbeidstøyproduksjon",
        name_en: "Manufacture of workwear",
        short_name_en: "Manufacture of workwear",
    },
    {
        code: "14.13",
        score: "high",
        name_no: "Produksjon av annet yttertøy",
        short_name_no: "Annen yttertøyproduksjon",
        name_en: "Manufacture of other outerwear",
        short_name_en: "Manufacture of other outerwear",
    },
    {
        code: "14.14",
        score: "high",
        name_no: "Produksjon av undertøy og innertøy",
        short_name_no: "Undertøy- og innertøyproduksjon",
        name_en: "Manufacture of underwear",
        short_name_en: "Manufacture of underwear",
    },
    {
        code: "14.19",
        score: "high",
        name_no: "Produksjon av klær og tilbehør ellers",
        short_name_no: "Produksjon av klær og tilbehør el.",
        name_en: "Manufacture of other wearing apparel and accessories",
        short_name_en: "Manuf. of other wearing apparel",
    },
    {
        code: "14.20",
        score: "high",
        name_no: "Produksjon av pelsvarer",
        short_name_no: "Produksjon av pelsvarer",
        name_en: "Manufacture of articles of fur",
        short_name_en: "Manufacture of articles of fur",
    },
    {
        code: "14.31",
        score: "high",
        name_no: "Produksjon av strømpevarer",
        short_name_no: "Strømpevareproduksjon",
        name_en: "Manufacture of knitted and crocheted hosiery",
        short_name_en: "Manuf. knitted and croch. hosiery",
    },
    {
        code: "14.39",
        score: "high",
        name_no: "Produksjon av andre klær av trikotasje",
        short_name_no: "Prod. av klær av trikotasje el.",
        name_en: "Manufacture of other knitted and crocheted apparel",
        short_name_en: "Other knitted and croch. apparel",
    },
    {
        code: "15.11",
        score: "high",
        name_no: "Beredning av lær, og beredning og farging av pelsskinn",
        short_name_no: "Lærberedning",
        name_en: "Tanning and dressing of leather; dressing and dyeing of fur",
        short_name_en: "Tanning/dressing of leather etc.",
    },
    {
        code: "15.12",
        score: "high",
        name_no: "Produksjon av reiseeffekter og salmakerartikler",
        short_name_no: "Reiseeffekter og salmakerartikler",
        name_en: "Manufacture of luggage, handbags and the like, saddlery and harness",
        short_name_en: "Manuf. of luggage, handbags etc.",
    },
    {
        code: "15.20",
        score: "high",
        name_no: "Produksjon av skotøy",
        short_name_no: "Produksjon av skotøy",
        name_en: "Manufacture of footwear",
        short_name_en: "Manufacture of footwear",
    },
    {
        code: "16.10",
        score: "medium",
        name_no: "Saging, høvling og impregnering av tre",
        short_name_no: "Saging, høvling mv. av tre",
        name_en: "Sawing and planing of wood",
        short_name_en: "Sawing and planing of wood",
    },
    {
        code: "16.21",
        score: "medium",
        name_no: "Produksjon av finerplater og andre bygnings- og møbelplater av tre",
        short_name_no: "Bygnings- og møbelplateproduksjon",
        name_en: "Manufacture of veneer sheets and wood-based materials",
        short_name_en: "Veneer sheets, wood-based materials",
    },
    {
        code: "16.22",
        score: "medium",
        name_no: "Produksjon av sammensatte parkettstaver",
        short_name_no: "Sammensatte parkettstavprod.",
        name_en: "Manufacture of assembled parquet floors",
        short_name_en: "Manuf. of assembled parquet floors",
    },
    {
        code: "16.23",
        score: "medium",
        name_no: "Produksjon av andre bygningsartikler",
        short_name_no: "Annen bygningsartikkelprod.",
        name_en: "Manufacture of other builders' carpentry and joinery",
        short_name_en: "Manuf. builders' carp./joinery etc.",
    },
    {
        code: "16.24",
        score: "medium",
        name_no: "Produksjon av treemballasje",
        short_name_no: "Treemballasjeproduksjon",
        name_en: "Manufacture of wooden containers",
        short_name_en: "Manuf. of wooden containers",
    },
    {
        code: "16.29",
        score: "medium",
        name_no: "Produksjon av andre trevarer og varer av kork, strå og flettematerialer",
        short_name_no: "Andre tre-, kork-, stråvarer mv.",
        name_en: "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials",
        short_name_en: "Other prod. of wood, cork, straw etc.",
    },
    {
        code: "17.11",
        score: "high",
        name_no: "Produksjon av papirmasse",
        short_name_no: "Produksjon av papirmasse",
        name_en: "Manufacture of pulp",
        short_name_en: "Manufacture of pulp",
    },
    {
        code: "17.12",
        score: "medium",
        name_no: "Produksjon av papir og papp",
        short_name_no: "Produksjon av papir og papp",
        name_en: "Manufacture of paper and paperboard",
        short_name_en: "Manuf. of paper and paperboard",
    },
    {
        code: "17.21",
        score: "medium",
        name_no: "Produksjon av bølgepapp og emballasje av papir og papp",
        short_name_no: "Bølgepapp, papir- og pappemballasje",
        name_en: "Manufacture of corrugated paper and paperboard and of containers of paper and paperboard",
        short_name_en: "Manuf. of corrugat. paper and -board",
    },
    {
        code: "17.22",
        score: "medium",
        name_no: "Produksjon av husholdnings-, sanitær- og toalettartikler av papir",
        short_name_no: "Hushold.-/toalettartikler av papir",
        name_en: "Manufacture of household and sanitary goods and of toilet requisites",
        short_name_en: "Manuf. of household and sanitary goods",
    },
    {
        code: "17.23",
        score: "medium",
        name_no: "Produksjon av kontorartikler av papir",
        short_name_no: "Prod. av kontorartikler av papir",
        name_en: "Manufacture of paper stationery",
        short_name_en: "Manuf. of paper stationery",
    },
    {
        code: "17.24",
        score: "medium",
        name_no: "Produksjon av tapeter",
        short_name_no: "Tapetproduksjon",
        name_en: "Manufacture of wallpaper",
        short_name_en: "Manuf. of wallpaper",
    },
    {
        code: "17.29",
        score: "medium",
        name_no: "Produksjon av varer av papir og papp ellers",
        short_name_no: "Papir- og pappvareproduksjon el.",
        name_en: "Manufacture of other articles of paper and paperboard",
        short_name_en: "Manuf. of other art. of paper/-board",
    },
    {
        code: "18.11",
        score: "low",
        name_no: "Trykking av aviser",
        short_name_no: "Trykking av aviser",
        name_en: "Printing of newspapers",
        short_name_en: "Printing of newspaper",
    },
    {
        code: "18.12",
        score: "low",
        name_no: "Trykking ellers",
        short_name_no: "Trykking ellers",
        name_en: "Other printing",
        short_name_en: "Other printing",
    },
    {
        code: "18.13",
        score: "low",
        name_no: "Ferdiggjøring før trykking og publisering",
        short_name_no: "Ferdiggjøring før trykking og publ.",
        name_en: "Pre-press and pre-media services",
        short_name_en: "Pre-press and pre-media services",
    },
    {
        code: "18.14",
        score: "low",
        name_no: "Bokbinding og tilknyttede tjenester",
        short_name_no: "Bokbinding og tilkn. tjenester",
        name_en: "Binding and related services",
        short_name_en: "Binding and related services",
    },
    {
        code: "18.20",
        score: "low",
        name_no: "Reproduksjon av innspilte opptak",
        short_name_no: "Reproduksjon av innspilte opptak",
        name_en: "Reproduction of recorded media",
        short_name_en: "Reprod. of recorded media",
    },
    {
        code: "19.10",
        score: "high",
        name_no: "Produksjon av kullprodukter",
        short_name_no: "Produksjon av kullprodukter",
        name_en: "Manufacture of coke oven products",
        short_name_en: "Manuf. of coke oven products",
    },
    {
        code: "19.20",
        score: "high",
        name_no: "Produksjon av raffinerte petroleumsprodukter",
        short_name_no: "Prod. raffinerte petroleumsprodukt",
        name_en: "Manufacture of refined petroleum products",
        short_name_en: "Manuf. of refined petroleum prod.",
    },
    {
        code: "20.11",
        score: "high",
        name_no: "Produksjon av industrigasser",
        short_name_no: "Industrigassproduksjon",
        name_en: "Manufacture of industrial gases",
        short_name_en: "Manuf. of industrial gases",
    },
    {
        code: "20.12",
        score: "high",
        name_no: "Produksjon av fargestoffer og pigmenter",
        short_name_no: "Fargestoff- og pigmentproduksjon",
        name_en: "Manufacture of dyes and pigments",
        short_name_en: "Manuf. of dyes and pigments",
    },
    {
        code: "20.13",
        score: "high",
        name_no: "Produksjon av andre uorganiske kjemikalier",
        short_name_no: "Uorganisk kjemikalieproduksjon el.",
        name_en: "Manufacture of other inorganic basic chemicals",
        short_name_en: "Manuf. of other inorg. basic chem.",
    },
    {
        code: "20.14",
        score: "high",
        name_no: "Produksjon av andre organiske kjemiske råvarer",
        short_name_no: "Annen org. kjemisk råvareproduksjon",
        name_en: "Manufacture of other organic basic chemicals",
        short_name_en: "Manuf. of other organic basic chem.",
    },
    {
        code: "20.15",
        score: "high",
        name_no: "Produksjon av gjødsel, nitrogenforbindelser og vekstjord",
        short_name_no: "Gjødselproduksjon mv.",
        name_en: "Manufacture of fertilisers and nitrogen compounds",
        short_name_en: "Fertilisers and nitrogen compounds",
    },
    {
        code: "20.16",
        score: "high",
        name_no: "Produksjon av basisplast",
        short_name_no: "Basisplastproduksjon",
        name_en: "Manufacture of plastics in primary forms",
        short_name_en: "Manuf. of plastics in primary forms",
    },
    {
        code: "20.17",
        score: "high",
        name_no: "Produksjon av syntetisk gummi",
        short_name_no: "Produksjon av syntetisk gummi",
        name_en: "Manufacture of synthetic rubber in primary forms",
        short_name_en: "Manuf. of synthetic rubber",
    },
    {
        code: "20.20",
        score: "high",
        name_no: "Produksjon av plantevern- og skadedyrmidler og andre landbrukskjemiske produkter",
        short_name_no: "Produksjon landbrukskjemiske prod.",
        name_en: "Manufacture of pesticides and other agrochemical products",
        short_name_en: "Manuf. of pesticides, agrochem prod.",
    },
    {
        code: "20.30",
        score: "high",
        name_no: "Produksjon av maling og lakk, trykkfarger og tetningsmidler",
        short_name_no: "Produksjon maling, lakk mv.",
        name_en: "Manufacture of paints, varnishes and similar coatings, printing ink and mastics",
        short_name_en: "Manuf. of paints,varnishes,coatings",
    },
    {
        code: "20.41",
        score: "high",
        name_no: "Produksjon av såpe og vaskemidler, rense- og polermidler",
        short_name_no: "Såpe- og vaskemiddelprod. mv.",
        name_en: "Manufacture of soap and detergents, cleaning and polishing preparations",
        short_name_en: "Soap, detergents, clean. prep. etc.",
    },
    {
        code: "20.42",
        score: "high",
        name_no: "Produksjon av parfyme og toalettartikler",
        short_name_no: "Parfyme og toalettartikler",
        name_en: "Manufacture of perfumes and toilet preparations",
        short_name_en: "Manuf. of perfumes, toilet prep.",
    },
    {
        code: "20.51",
        score: "high",
        name_no: "Produksjon av eksplosiver",
        short_name_no: "Produksjon av eksplosiver",
        name_en: "Manufacture of explosives",
        short_name_en: "Manuf. of explosives",
    },
    {
        code: "20.52",
        score: "high",
        name_no: "Produksjon av lim",
        short_name_no: "Limproduksjon",
        name_en: "Manufacture of glues",
        short_name_en: "Manuf. of glues",
    },
    {
        code: "20.53",
        score: "high",
        name_no: "Produksjon av eteriske oljer",
        short_name_no: "Produksjon av eteriske oljer",
        name_en: "Manufacture of essential oils",
        short_name_en: "Manuf. of essential oils",
    },
    {
        code: "20.59",
        score: "high",
        name_no: "Produksjon av kjemiske produkter ikke nevnt annet sted",
        short_name_no: "Annen kjemisk produksjon",
        name_en: "Manufacture of other chemical products n.e.c",
        short_name_en: "Manuf. of other chemical prod. n.e.c.",
    },
    {
        code: "20.60",
        score: "high",
        name_no: "Produksjon av kunstfibrer",
        short_name_no: "Produksjon av kunstfibrer",
        name_en: "Manufacture of man-made fibres",
        short_name_en: "Manuf. of man-made fibres",
    },
    {
        code: "21.10",
        score: "high",
        name_no: "Produksjon av farmasøytiske råvarer",
        short_name_no: "Farmasøytiske råvarer",
        name_en: "Manufacture of basic pharmaceutical products",
        short_name_en: "Manuf. of basic pharm. products",
    },
    {
        code: "21.20",
        score: "high",
        name_no: "Produksjon av farmasøytiske preparater",
        short_name_no: "Farmasøytiske preparater",
        name_en: "Manufacture of pharmaceutical preparations",
        short_name_en: "Manuf. of pharm. preparations",
    },
    {
        code: "22.11",
        score: "high",
        name_no: "Produksjon av gummidekk og slanger til gummidekk, og regummiering og vulkanisering av gummidekk",
        short_name_no: "Gummidekkprod., -regumm. og -vulk.",
        name_en: "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres",
        short_name_en: "Manuf. of rubber tyres and tubes etc",
    },
    {
        code: "22.19",
        score: "high",
        name_no: "Produksjon av gummiprodukter ellers",
        short_name_no: "Produksjon av gummiprodukter el.",
        name_en: "Manufacture of other rubber products",
        short_name_en: "Manuf. of other rubber products",
    },
    {
        code: "22.21",
        score: "high",
        name_no: "Produksjon av halvfabrikater av plast",
        short_name_no: "Prod. av halvfabrikater av plast",
        name_en: "Manufacture of plastic plates, sheets, tubes and profiles",
        short_name_en: "Manuf. of plastic plates, sheets etc.",
    },
    {
        code: "22.22",
        score: "high",
        name_no: "Produksjon av plastemballasje",
        short_name_no: "Plastemballasjeproduksjon",
        name_en: "Manufacture of plastic packing goods",
        short_name_en: "Manuf. of plastic packing goods",
    },
    {
        code: "22.23",
        score: "high",
        name_no: "Produksjon av byggevarer av plast",
        short_name_no: "Byggevareprod. av plast",
        name_en: "Manufacture of builders' ware of plastic",
        short_name_en: "Manuf. of builders' ware of plastic",
    },
    {
        code: "22.29",
        score: "high",
        name_no: "Produksjon av plastprodukter ellers",
        short_name_no: "Prod. av plastprodukter el.",
        name_en: "Manufacture of other plastic products",
        short_name_en: "Manuf. of other plastic products",
    },
    {
        code: "23.11",
        score: "medium",
        name_no: "Produksjon av planglass",
        short_name_no: "Planglassproduksjon",
        name_en: "Manufacture of flat glass",
        short_name_en: "Manuf. of flat glass",
    },
    {
        code: "23.12",
        score: "medium",
        name_no: "Bearbeiding av planglass",
        short_name_no: "Planglassbearbeiding",
        name_en: "Shaping and processing of flat glass",
        short_name_en: "Shaping, processing of flat glass",
    },
    {
        code: "23.13",
        score: "medium",
        name_no: "Produksjon av emballasje og husholdningsartikler av glass og krystall",
        short_name_no: "Emb. og hush.art. av glass/krystall",
        name_en: "Manufacture of hollow glass",
        short_name_en: "Manuf. of hollow glass",
    },
    {
        code: "23.14",
        score: "medium",
        name_no: "Produksjon av glassfibrer",
        short_name_no: "Glassfiberproduksjon",
        name_en: "Manufacture of glass fibres",
        short_name_en: "Manuf. of glass fibres",
    },
    {
        code: "23.19",
        score: "medium",
        name_no: "Produksjon av teknisk glass og andre glassvarer",
        short_name_no: "Tekn. glass- og annen glassvareprod.",
        name_en: "Manufacture and processing of other glass, including technical glassware",
        short_name_en: "Manuf. and process. of other glass",
    },
    {
        code: "23.20",
        score: "medium",
        name_no: "Produksjon av ildfaste produkter",
        short_name_no: "Prod. av ildfaste produkter",
        name_en: "Manufacture of refractory products",
        short_name_en: "Manuf. of refractory products",
    },
    {
        code: "23.31",
        score: "medium",
        name_no: "Produksjon av keramiske vegg- og gulvfliser",
        short_name_no: "Keramisk byggematerialproduksjon",
        name_en: "Manufacture of ceramic tiles and flags",
        short_name_en: "Manuf. of ceramic tiles and flags",
    },
    {
        code: "23.32",
        score: "medium",
        name_no: "Produksjon av murstein, teglstein og andre byggevarer av brent leire",
        short_name_no: "Mur-, teglstein- o.a. byggevareprod.",
        name_en: "Manufacture of bricks, tiles and construction products, in baked clay",
        short_name_en: "Construction prod. in baked clay",
    },
    {
        code: "23.41",
        score: "medium",
        name_no: "Produksjon av keramiske husholdningsartikler og dekorasjonsgjenstander",
        short_name_no: "Keramiske husholdningsartikler mv.",
        name_en: "Manufacture of ceramic household and ornamental articles",
        short_name_en: "Manuf. of ceramic househ. art. etc.",
    },
    {
        code: "23.42",
        score: "medium",
        name_no: "Produksjon av sanitærutstyr av keramisk materiale",
        short_name_no: "Prod. av keramisk sanitærutstyr",
        name_en: "Manufacture of ceramic sanitary fixtures",
        short_name_en: "Manuf. of ceram. sanitary fixtures",
    },
    {
        code: "23.43",
        score: "medium",
        name_no: "Produksjon av isolatorer og isoleringsdeler av keramisk materiale",
        short_name_no: "Prod. av keramiske isoleringsdeler",
        name_en: "Manufacture of ceramic insulators and insulating fittings",
        short_name_en: "Manuf. of ceram. insulators etc.",
    },
    {
        code: "23.44",
        score: "medium",
        name_no: "Produksjon av andre keramiske produkter for teknisk bruk",
        short_name_no: "Prod. av keram. prod. for tekn. bruk",
        name_en: "Manufacture of other technical ceramic products",
        short_name_en: "Manuf. of other tech. ceram. prod.",
    },
    {
        code: "23.49",
        score: "medium",
        name_no: "Produksjon av andre keramiske produkter",
        short_name_no: "Prod. av andre keramiske prod.",
        name_en: "Manufacture of other ceramic products",
        short_name_en: "Manuf. of other ceramic prod.",
    },
    {
        code: "23.51",
        score: "medium",
        name_no: "Produksjon av sement",
        short_name_no: "Sementproduksjon",
        name_en: "Manufacture of cement",
        short_name_en: "Manuf. of cement",
    },
    {
        code: "23.52",
        score: "medium",
        name_no: "Produksjon av kalk og gips",
        short_name_no: "Kalk- og gipsproduksjon",
        name_en: "Manufacture of lime and plaster",
        short_name_en: "Manuf. of lime and plaster",
    },
    {
        code: "23.61",
        score: "medium",
        name_no: "Produksjon av betongprodukter for bygge- og anleggsvirksomhet",
        short_name_no: "Betongprodukter for bygg og anlegg",
        name_en: "Manufacture of concrete products for construction purposes",
        short_name_en: "Concrete prod. for construc. purposes",
    },
    {
        code: "23.62",
        score: "medium",
        name_no: "Produksjon av gipsprodukter for bygge- og anleggsvirksomhet",
        short_name_no: "Gipsprodukter for bygg av anlegg",
        name_en: "Manufacture of plaster products for construction purposes",
        short_name_en: "Plaster prod. for construc. purposes",
    },
    {
        code: "23.63",
        score: "medium",
        name_no: "Produksjon av ferdigblandet betong",
        short_name_no: "Ferdigblandet betong",
        name_en: "Manufacture of ready-mixed concrete",
        short_name_en: "Manuf. of ready-mixed concrete",
    },
    {
        code: "23.64",
        score: "medium",
        name_no: "Produksjon av mørtel",
        short_name_no: "Mørtelproduksjon",
        name_en: "Manufacture of mortars",
        short_name_en: "Manuf. of mortars",
    },
    {
        code: "23.65",
        score: "medium",
        name_no: "Produksjon av fibersement",
        short_name_no: "Fibersementproduksjon",
        name_en: "Manufacture of fibre cement",
        short_name_en: "Manuf. of fibre cement",
    },
    {
        code: "23.69",
        score: "medium",
        name_no: "Produksjon av betong-, sement- og gipsprodukter ellers",
        short_name_no: "Annen betong-, sement- og gipsprod.",
        name_en: "Manufacture of other articles of concrete, plaster and cement",
        short_name_en: "Manuf. of other art. of concrete etc.",
    },
    {
        code: "23.70",
        score: "medium",
        name_no: "Hogging og bearbeiding av monument- og bygningsstein",
        short_name_no: "Hogg./bearb. av monum.-/bygn.stein",
        name_en: "Cutting, shaping and finishing of stone",
        short_name_en: "Cutting,shaping,finishing of stone",
    },
    {
        code: "23.91",
        score: "medium",
        name_no: "Produksjon av slipestoffer",
        short_name_no: "Slipestoffproduksjon",
        name_en: "Production of abrasive products",
        short_name_en: "Prod. of abrasive products",
    },
    {
        code: "23.99",
        score: "medium",
        name_no: "Produksjon av ikke-metallholdige mineralprodukter ikke nevnt annet sted",
        short_name_no: "Prod. av ikke-met. mineralprod. el.",
        name_en: "Manufacture of other non-metallic mineral products n.e.c.",
        short_name_en: "Manuf. of other non-met. min. prod.",
    },
    {
        code: "24.10",
        score: "high",
        name_no: "Produksjon av jern og stål, samt ferrolegeringer",
        short_name_no: "Produksjon jern,  stål, ferroleger.",
        name_en: "Manufacture of basic iron and steel and of ferro-alloys",
        short_name_en: "Manuf. basic iron and steel etc.",
    },
    {
        code: "24.20",
        score: "high",
        name_no: "Produksjon av andre rør og rørdeler av stål",
        short_name_no: "Annen rør- og rørdelprod. av stål",
        name_en: "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel",
        short_name_en: "Tubes, pipes etc. of steel",
    },
    {
        code: "24.31",
        score: "high",
        name_no: "Kaldtrekking av stenger og profiler",
        short_name_no: "Kaldtrekking av stenger og profiler",
        name_en: "Cold drawing of bars",
        short_name_en: "Cold drawing of bars",
    },
    {
        code: "24.32",
        score: "high",
        name_no: "Kaldvalsing av bånd",
        short_name_no: "Kaldvalsing av bånd",
        name_en: "Cold rolling of narrow strips",
        short_name_en: "Cold rolling of narrow strips",
    },
    {
        code: "24.33",
        score: "high",
        name_no: "Kaldvalsing og pressing av profilerte plater og profiler",
        short_name_no: "Kaldv./press. av prof. plater mv.",
        name_en: "Cold forming or folding",
        short_name_en: "Cold forming or folding",
    },
    {
        code: "24.34",
        score: "high",
        name_no: "Kaldtrekking av tråd",
        short_name_no: "Kaldtrekking av tråd",
        name_en: "Cold drawing of wire",
        short_name_en: "Cold drawing of wire",
    },
    {
        code: "24.41",
        score: "high",
        name_no: "Produksjon av edelmetaller",
        short_name_no: "Edelmetallproduksjon",
        name_en: "Precious metals production",
        short_name_en: "Precious metals production",
    },
    {
        code: "24.42",
        score: "high",
        name_no: "Produksjon av aluminium",
        short_name_no: "Produksjon av aluminium",
        name_en: "Aluminium production",
        short_name_en: "Aluminium production",
    },
    {
        code: "24.43",
        score: "high",
        name_no: "Produksjon av bly, sink og tinn",
        short_name_no: "Bly-, sink- og tinnproduksjon",
        name_en: "Lead, zinc and tin production",
        short_name_en: "Lead, zink and tin production",
    },
    {
        code: "24.44",
        score: "high",
        name_no: "Produksjon av kobber",
        short_name_no: "Kobberproduksjon",
        name_en: "Copper production",
        short_name_en: "Copper production",
    },
    {
        code: "24.45",
        score: "high",
        name_no: "Produksjon av ikke-jernholdige metaller ellers",
        short_name_no: "Prod. ikke-jernhold. metaller ellers",
        name_en: "Other non-ferrous metal production",
        short_name_en: "Other non-ferrous metal production",
    },
    {
        code: "24.46",
        score: "high",
        name_no: "Produksjon av kjernebrensel",
        short_name_no: "Kjernebrenselproduksjon",
        name_en: "Processing of nuclear fuel",
        short_name_en: "Processing of nuclear fuel",
    },
    {
        code: "24.51",
        score: "high",
        name_no: "Støping av jern",
        short_name_no: "Støping av jern",
        name_en: "Casting of iron",
        short_name_en: "Casting of iron",
    },
    {
        code: "24.52",
        score: "high",
        name_no: "Støping av stål",
        short_name_no: "Støping av stål",
        name_en: "Casting of steel",
        short_name_en: "Casting of steel",
    },
    {
        code: "24.53",
        score: "high",
        name_no: "Støping av lettmetaller",
        short_name_no: "Støping av lettmetaller",
        name_en: "Casting of light metals",
        short_name_en: "Casting of light metals",
    },
    {
        code: "24.54",
        score: "high",
        name_no: "Støping av andre ikke-jernholdige metaller",
        short_name_no: "Annen ikke-jernholdig metallstøp.",
        name_en: "Casting of other non-ferrous metals",
        short_name_en: "Casting of other non-ferr. metals",
    },
    {
        code: "25.11",
        score: "medium",
        name_no: "Produksjon av metallkonstruksjoner og deler",
        short_name_no: "Prod. av metallkonstruksj. og deler",
        name_en: "Manufacture of metal structures and parts of structures",
        short_name_en: "Manuf. of metal structures, parts",
    },
    {
        code: "25.12",
        score: "medium",
        name_no: "Produksjon av dører og vinduer av metall",
        short_name_no: "Bygningsartikkelprod. av metall",
        name_en: "Manufacture of doors and windows of metal",
        short_name_en: "Manuf. of doors/windows of metal",
    },
    {
        code: "25.21",
        score: "medium",
        name_no: "Produksjon av radiatorer og kjeler til sentralvarmeanlegg",
        short_name_no: "Sentralv.kjeler- og radiatorprod.",
        name_en: "Manufacture of central heating radiators and boilers",
        short_name_en: "Manuf. of centr. heating radiators etc.",
    },
    {
        code: "25.29",
        score: "medium",
        name_no: "Produksjon av andre tanker, cisterner og beholdere av metall",
        short_name_no: "Prod. av metallbeholdere",
        name_en: "Manufacture of other tanks, reservoirs and containers of metal",
        short_name_en: "Other metal tanks, reservoirs etc.",
    },
    {
        code: "25.30",
        score: "medium",
        name_no: "Produksjon av dampkjeler, unntatt kjeler til sentralvarmeanlegg",
        short_name_no: "Dampkjeler unnt. sentralvarmeanl.",
        name_en: "Manufacture of steam generators, except central heating hot water boilers",
        short_name_en: "Manuf. of steam generators etc.",
    },
    {
        code: "25.40",
        score: "medium",
        name_no: "Produksjon av våpen og ammunisjon",
        short_name_no: "Våpen- og ammunisjonsproduksjon",
        name_en: "Manufacture of weapons and ammunition",
        short_name_en: "Manuf. of weapons and ammunition",
    },
    {
        code: "25.50",
        score: "medium",
        name_no: "Smiing, stansing og valsing av metall, og pulvermetallurgi",
        short_name_no: "Stansing/valsing/pulvermetallurgi",
        name_en: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
        short_name_en: "Powder metallurgy etc.",
    },
    {
        code: "25.61",
        score: "high",
        name_no: "Overflatebehandling av metaller",
        short_name_no: "Overflatebehandling av metaller",
        name_en: "Treatment and coating of metals",
        short_name_en: "Treatment and coating of metals",
    },
    {
        code: "25.62",
        score: "medium",
        name_no: "Bearbeiding av metaller",
        short_name_no: "Bearbeiding av metaller",
        name_en: "Machining",
        short_name_en: "Machining",
    },
    {
        code: "25.71",
        score: "medium",
        name_no: "Produksjon av kjøkkenredskaper og skjære- og klipperedskaper",
        short_name_no: "Kjøkkenredskapsproduksjon",
        name_en: "Manufacture of cutlery",
        short_name_en: "Manuf. of cutlery",
    },
    {
        code: "25.72",
        score: "medium",
        name_no: "Produksjon av låser og beslag",
        short_name_no: "Låse- og beslagproduksjon",
        name_en: "Manufacture of locks and hinges",
        short_name_en: "Manuf. of locks and hinges",
    },
    {
        code: "25.73",
        score: "medium",
        name_no: "Produksjon av håndverktøy",
        short_name_no: "Håndverktøyproduksjon",
        name_en: "Manufacture of tools",
        short_name_en: "Manuf. of tools",
    },
    {
        code: "25.91",
        score: "medium",
        name_no: "Produksjon av stålfat og lignende beholdere av jern og stål",
        short_name_no: "Prod. av behold. mv. av jern/stål",
        name_en: "Manufacture of steel drums and similar containers",
        short_name_en: "Manuf. of steel drums etc.",
    },
    {
        code: "25.92",
        score: "medium",
        name_no: "Produksjon av emballasje av lettmetall",
        short_name_no: "Prod. av lettmetallemballasje",
        name_en: "Manufacture of light metal packaging",
        short_name_en: "Manuf. of light metal packaging",
    },
    {
        code: "25.93",
        score: "medium",
        name_no: "Produksjon av varer av metalltråd, kjetting og fjærer",
        short_name_no: "Metalltrådvareproduksjon",
        name_en: "Manufacture of wire products, chain and springs",
        short_name_en: "Manuf. wire prod., chain and springs",
    },
    {
        code: "25.94",
        score: "medium",
        name_no: "Produksjon av bolter og skruer",
        short_name_no: "Bolte- og skrueproduksjon",
        name_en: "Manufacture of fasteners and screw machine products",
        short_name_en: "Manuf. of fasteners etc.",
    },
    {
        code: "25.99",
        score: "medium",
        name_no: "Produksjon av metallvarer ikke nevnt annet sted",
        short_name_no: "Metallvareprod. el.",
        name_en: "Manufacture of other fabricated metal products n.e.c.",
        short_name_en: "Manuf. of other fabr. metal products",
    },
    {
        code: "26.11",
        score: "low",
        name_no: "Produksjon av elektroniske komponenter",
        short_name_no: "Prod. av elektroniske komponenter",
        name_en: "Manufacture of electronic components",
        short_name_en: "Manuf. of electronic components",
    },
    {
        code: "26.12",
        score: "low",
        name_no: "Produksjon av kretskort",
        short_name_no: "Produksjon av kretskort",
        name_en: "Manufacture of loaded electronic boards",
        short_name_en: "Manuf. of loaded electronic boards",
    },
    {
        code: "26.20",
        score: "low",
        name_no: "Produksjon av datamaskiner og tilleggsutstyr",
        short_name_no: "Prod. datamaskiner og tilleggsutstyr",
        name_en: "Manufacture of computers and peripheral equipment",
        short_name_en: "Manuf. of computers/periph. equip.",
    },
    {
        code: "26.30",
        score: "low",
        name_no: "Produksjon av kommunikasjonsutstyr",
        short_name_no: "Prod. av kommunikasjonsutstyr",
        name_en: "Manufacture of communication equipment",
        short_name_en: "Manuf. of communication equipment",
    },
    {
        code: "26.40",
        score: "low",
        name_no: "Produksjon av elektronikk til husholdningsbruk",
        short_name_no: "Prod. av husholdningselektronikk",
        name_en: "Manufacture of consumer electronics",
        short_name_en: "Manuf. of consumer electronics",
    },
    {
        code: "26.51",
        score: "low",
        name_no: "Produksjon av måle-, kontroll- og navigasjonsinstrumenter",
        short_name_no: "Måle-, kontroll- og navig.instr.",
        name_en: "Manufacture of instruments and appliances for measuring, testing and navigation",
        short_name_en: "Measuring, testing, navig. instrum.",
    },
    {
        code: "26.52",
        score: "low",
        name_no: "Produksjon av klokker og ur",
        short_name_no: "Klokke- og urproduksjon",
        name_en: "Manufacture of watches and clocks",
        short_name_en: "Manuf. of watches and clocks",
    },
    {
        code: "26.60",
        score: "low",
        name_no: "Produksjon av strålingsutstyr, elektromedisinsk og elektroterapeutisk utstyr",
        short_name_no: "Elektromed./elektroterap. utst. mv.",
        name_en: "Manufacture of irridation, electromedical and electrotherapeutic equipment",
        short_name_en: "Electromed./electrotherapeut. equip.",
    },
    {
        code: "26.70",
        score: "low",
        name_no: "Produksjon av optiske instrumenter og fotografisk utstyr",
        short_name_no: "Optiske instr./fotografisk utstyr",
        name_en: "Manufacture of optical instruments and photographic equipment",
        short_name_en: "Optical and photographic equip.",
    },
    {
        code: "26.80",
        score: "low",
        name_no: "Produksjon av magnetiske og optiske media",
        short_name_no: "Magnetiske/optiske media",
        name_en: "Manufacture of magnetic and optical media",
        short_name_en: "Manuf. of magnetic/optical media",
    },
    {
        code: "27.11",
        score: "medium",
        name_no: "Produksjon av elektromotorer, generatorer og transformatorer",
        short_name_no: "Elektromotor-, generatorprod. mv.",
        name_en: "Manufacture of electric motors, generators and transformers",
        short_name_en: "Electric motors, generators etc.",
    },
    {
        code: "27.12",
        score: "medium",
        name_no: "Produksjon av elektriske fordelings- og kontrolltavler og paneler",
        short_name_no: "Elektriske tavler, paneler mv.",
        name_en: "Manufacture of electricity distribution and control apparatus",
        short_name_en: "Elec. distrib. and control apparatus",
    },
    {
        code: "27.20",
        score: "medium",
        name_no: "Produksjon av batterier og akkumulatorer",
        short_name_no: "Batterier, akkumulatorer",
        name_en: "Manufacture of batteries and accumulators",
        short_name_en: "Manuf. of batteries and accumulators",
    },
    {
        code: "27.31",
        score: "medium",
        name_no: "Produksjon av optiske fiberkabler",
        short_name_no: "Prod. av optiske fiberkabler",
        name_en: "Manufacture of fibre optic cables",
        short_name_en: "Manuf. of fibre optic cables",
    },
    {
        code: "27.32",
        score: "medium",
        name_no: "Produksjon av andre elektroniske og elektriske ledninger og kabler",
        short_name_no: "Elektron./el. ledninger/kabler el.",
        name_en: "Manufacture of other electronic and electric wires and cables",
        short_name_en: "Other electron./electric wires/cables",
    },
    {
        code: "27.33",
        score: "medium",
        name_no: "Produksjon av ledningsmateriell",
        short_name_no: "Prod. av ledningsmateriell",
        name_en: "Manufacture of wiring devices",
        short_name_en: "Manuf. of wiring devices",
    },
    {
        code: "27.40",
        score: "medium",
        name_no: "Produksjon av belysningsutstyr",
        short_name_no: "Prod. av belysningsutstyr",
        name_en: "Manufacture of electric lighting equipment",
        short_name_en: "Manuf. of electric lighting equip.",
    },
    {
        code: "27.51",
        score: "medium",
        name_no: "Produksjon av elektriske husholdningsmaskiner og apparater",
        short_name_no: "Elektr. hush.maskin-/apparatprod.",
        name_en: "Manufacture of electric domestic appliances",
        short_name_en: "Manuf. elec. domestic appliances",
    },
    {
        code: "27.52",
        score: "medium",
        name_no: "Produksjon av ikke-elektriske husholdningsmaskiner og apparater",
        short_name_no: "Ikke-el. hush.mask.-/apparatprod.",
        name_en: "Manufacture of non-electric domestic appliances",
        short_name_en: "Manuf. non-elec. domestic appliances",
    },
    {
        code: "27.90",
        score: "medium",
        name_no: "Produksjon av annet elektrisk utstyr",
        short_name_no: "Produksjon elektrisk utstyr ellers",
        name_en: "Manufacture of other electrical equipment",
        short_name_en: "Manuf. of other electrical equip.",
    },
    {
        code: "28.11",
        score: "medium",
        name_no: "Produksjon av motorer og turbiner, unntatt motorer til luftfartøyer og motorvogner",
        short_name_no: "Skipsmotor-/motor- og turbinprod. el.",
        name_en: "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines",
        short_name_en: "Manuf. of engines and turbines",
    },
    {
        code: "28.12",
        score: "medium",
        name_no: "Produksjon av komponenter til hydraulisk og pneumatisk utstyr",
        short_name_no: "Komp. til hydraul./pneumatisk utstyr",
        name_en: "Manufacture of fluid power equipment",
        short_name_en: "Manuf. of fluid power equip.",
    },
    {
        code: "28.13",
        score: "medium",
        name_no: "Produksjon av pumper og kompressorer ellers",
        short_name_no: "Pumpe- og kompressorprod. el.",
        name_en: "Manufacture of other pumps and compressors",
        short_name_en: "Manuf. of other pumps, compressors",
    },
    {
        code: "28.14",
        score: "medium",
        name_no: "Produksjon av kraner og ventiler ellers",
        short_name_no: "Kran- og ventilproduksjon el.",
        name_en: "Manufacture of taps and valves",
        short_name_en: "Manuf. of taps and valves",
    },
    {
        code: "28.15",
        score: "medium",
        name_no: "Produksjon av lagre, gir, tannhjulsutvekslinger og andre innretninger for kraftoverføring",
        short_name_no: "Lager-, girproduksjon mv.",
        name_en: "Manufacture of bearings, gears, gearing and driving elements",
        short_name_en: "Bearings, gears, gear./driv. elements",
    },
    {
        code: "28.21",
        score: "medium",
        name_no: "Produksjon av industri- og laboratorieovner samt brennere",
        short_name_no: "Industri- og laboratorieovnprod.",
        name_en: "Manufacture of ovens, furnaces and furnace burners",
        short_name_en: "Ovens, furnaces and furnace burners",
    },
    {
        code: "28.22",
        score: "medium",
        name_no: "Produksjon av løfte- og håndteringsutstyr",
        short_name_no: "Løfte- og håndteringsutstyr",
        name_en: "Manufacture of lifting and handling equipment",
        short_name_en: "Manuf. lifting/handling equip.",
    },
    {
        code: "28.23",
        score: "medium",
        name_no: "Produksjon av kontormaskiner og utstyr (unntatt datamaskiner og tilleggsutstyr)",
        short_name_no: "Kontormaskinproduksjon",
        name_en: "Manufacture of office machinery and equipment (except computers and peripheral equipment)",
        short_name_en: "Manuf. of office mach. and equip.",
    },
    {
        code: "28.24",
        score: "medium",
        name_no: "Produksjon av motordrevet håndverktøy",
        short_name_no: "Prod. av motordrevet håndverktøy",
        name_en: "Manufacture of power-driven equipment",
        short_name_en: "Manuf. of power-driven equip.",
    },
    {
        code: "28.25",
        score: "medium",
        name_no: "Produksjon av kjøle- og ventilasjonsanlegg, unntatt til husholdningsbruk",
        short_name_no: "Kjøle- og ventilasjonsanleggprod.",
        name_en: "Manufacture of non-domestic cooling and ventilation equipment",
        short_name_en: "Non-domestic. cool./ventil. equip.",
    },
    {
        code: "28.29",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til generell bruk, ikke nevnt annet sted",
        short_name_no: "Maskiner og utstyr til gen. bruk el.",
        name_en: "Manufacture of other general-purpose machinery n.e.c.",
        short_name_en: "Other gen-purp. machinery n.e.c.",
    },
    {
        code: "28.30",
        score: "medium",
        name_no: "Produksjon av jordbruks- og skogbruksmaskiner",
        short_name_no: "Jord- og skogbruksmaskinproduksjon",
        name_en: "Manufacture of agricultural and forestry machinery",
        short_name_en: "Manuf. of agric., forestry machinery",
    },
    {
        code: "28.41",
        score: "medium",
        name_no: "Produksjon av maskinverktøy til metallbearbeiding",
        short_name_no: "Maskinverktøy til metallbearb.",
        name_en: "Manufacture of metal forming machinery",
        short_name_en: "Manuf. metal forming machinery",
    },
    {
        code: "28.49",
        score: "medium",
        name_no: "Produksjon av maskinverktøy ikke nevnt annet sted",
        short_name_no: "Prod. av maskinverktøy el.",
        name_en: "Manufacture of other machine tools",
        short_name_en: "Manuf. of other machine tools",
    },
    {
        code: "28.91",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til metallurgisk industri",
        short_name_no: "Mask./utstyr metallurgisk industri",
        name_en: "Manufacture of machinery for metallurgy",
        short_name_en: "Manuf. mach. for metallurgy",
    },
    {
        code: "28.92",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til bergverksdrift og bygge- og anleggsvirksomhet",
        short_name_no: "Mask./utstyr bergverk, bygg og anl.",
        name_en: "Manufacture of machinery for mining, quarrying and construction",
        short_name_en: "Manuf. mach. mining/quarry./constr.",
    },
    {
        code: "28.93",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til nærings- og nytelsesmiddelindustri",
        short_name_no: "Mask./utstyr næringsmiddelindustri",
        name_en: "Manufacture of machinery for food, beverage and tobacco processing",
        short_name_en: "Manuf. mach. food/beverages/tobacco",
    },
    {
        code: "28.94",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til tekstil-, konfeksjons- og lærvareindustri",
        short_name_no: "Mask./utstyr tekstilindustri",
        name_en: "Manufacture of machinery for textile, apparel and leather production",
        short_name_en: "Manuf. mach. text./appar./leather prod.",
    },
    {
        code: "28.95",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til papir- og pappvareindustri",
        short_name_no: "Mask./utstyr papir- og pappvareindustri",
        name_en: "Manufacture of machinery for paper and paperboard production",
        short_name_en: "Manuf. mach paper/paperboard prod.",
    },
    {
        code: "28.96",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til plast- og gummiindustri",
        short_name_no: "Mask./utstyr plast- og gummiindustri",
        name_en: "Manufacture of plastics and rubber machinery",
        short_name_en: "Manuf. plastics and rubber mach.",
    },
    {
        code: "28.99",
        score: "medium",
        name_no: "Produksjon av spesialmaskiner ikke nevnt annet sted",
        short_name_no: "Spesialmaskinproduksjon el.",
        name_en: "Manufacture of other special-purpose machinery n.e.c.",
        short_name_en: "Manuf. other spec. purp. mach.",
    },
    {
        code: "29.10",
        score: "medium",
        name_no: "Produksjon av motorvogner",
        short_name_no: "Motorvognproduksjon",
        name_en: "Manufacture of motor vehicles",
        short_name_en: "Manuf. of motor vehicles",
    },
    {
        code: "29.20",
        score: "medium",
        name_no: "Produksjon av karosserier og tilhengere",
        short_name_no: "Karosseri- og tilhengerproduksjon",
        name_en: "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
        short_name_en: "Bodies for motor vehicles etc.",
    },
    {
        code: "29.31",
        score: "medium",
        name_no: "Produksjon av elektrisk og elektronisk utstyr til motorvogner",
        short_name_no: "Elektrisk/elektron. utstyr motorv.",
        name_en: "Manufacture of electrical and electronic equipment for motor vehicles",
        short_name_en: "Electric/electron. equip. motor veh.",
    },
    {
        code: "29.32",
        score: "medium",
        name_no: "Produksjon av andre deler og annet utstyr til motorvogner",
        short_name_no: "Annet utstyr-/delprod. til motorv.",
        name_en: "Manufacture of other parts and accessories for motor vehicles",
        short_name_en: "Other parts/access. for motor veh.",
    },
    {
        code: "30.11",
        score: "high",
        name_no: "Bygging av skip og flytende materiell",
        short_name_no: "Bygg av skip og flytende materiell",
        name_en: "Building of ships and floating structures",
        short_name_en: "Build. ships/floating struct.",
    },
    {
        code: "30.12",
        score: "medium",
        name_no: "Bygging av fritidsbåter",
        short_name_no: "Bygging av fritidsbåter",
        name_en: "Building of pleasure and sporting boats",
        short_name_en: "Build. pleasure/sporting boats",
    },
    {
        code: "30.20",
        score: "medium",
        name_no: "Produksjon av lokomotiver og annet rullende materiell til jernbane og sporvei",
        short_name_no: "Jernbane- og sporvognmateriell mv.",
        name_en: "Manufacture of railway locomotives and rolling stock",
        short_name_en: "Manuf. of locom. and rolling stock",
    },
    {
        code: "30.30",
        score: "medium",
        name_no: "Produksjon av luftfartøyer og romfartøyer og lignende utstyr",
        short_name_no: "Prod. luft- og romfartøy og lignende",
        name_en: "Manufacture of air and spacecraft and related machinery",
        short_name_en: "Air/space craft rel. machinery",
    },
    {
        code: "30.40",
        score: "medium",
        name_no: "Produksjon av militære stridskjøretøyer",
        short_name_no: "Produksjon militære stridskjøretøy",
        name_en: "Manufacture of military fighting machinery",
        short_name_en: "Manuf. of military fight. mach.",
    },
    {
        code: "30.91",
        score: "medium",
        name_no: "Produksjon av motorsykler",
        short_name_no: "Motorsykkelproduksjon",
        name_en: "Manufacture of motorcycles",
        short_name_en: "Manuf. of motorcycles",
    },
    {
        code: "30.92",
        score: "medium",
        name_no: "Produksjon av sykler og invalidevogner",
        short_name_no: "Sykkel- og invalidevognprod.",
        name_en: "Manufacture of bicycles and invalid carriages",
        short_name_en: "Manuf. bicycles/invalid carriages",
    },
    {
        code: "30.99",
        score: "medium",
        name_no: "Produksjon av andre transportmidler ikke nevnt annet sted",
        short_name_no: "Transportmiddelproduksjon el.",
        name_en: "Manufacture of other transport equipment n.e.c.",
        short_name_en: "Manuf. other transp. equip. n.e.c.",
    },
    {
        code: "31.01",
        score: "medium",
        name_no: "Produksjon av kontor- og butikkmøbler",
        short_name_no: "Kontor- og butikkmøbelprod.",
        name_en: "Manufacture of office and shop furniture",
        short_name_en: "Manuf. office and shop furniture",
    },
    {
        code: "31.02",
        score: "medium",
        name_no: "Produksjon av kjøkkenmøbler",
        short_name_no: "Kjøkkenmøbelproduksjon",
        name_en: "Manufacture of kitchen furniture",
        short_name_en: "Manuf. of kitchen furniture",
    },
    {
        code: "31.03",
        score: "medium",
        name_no: "Produksjon av madrasser",
        short_name_no: "Madrassproduksjon",
        name_en: "Manufacture of mattresses",
        short_name_en: "Manuf. of mattresses",
    },
    {
        code: "31.09",
        score: "medium",
        name_no: "Produksjon av møbler ellers",
        short_name_no: "Møbelproduksjon el.",
        name_en: "Manufacture of other furniture",
        short_name_en: "Manuf. of other furniture",
    },
    {
        code: "32.11",
        score: "medium",
        name_no: "Preging av mynter og medaljer",
        short_name_no: "Mynt- og medaljepreging",
        name_en: "Striking of coins",
        short_name_en: "Striking of coins",
    },
    {
        code: "32.12",
        score: "medium",
        name_no: "Produksjon av gull- og sølvvarer og lignende artikler",
        short_name_no: "Gull- og sølvvareprod. mv.",
        name_en: "Manufacture of jewellery and related articles",
        short_name_en: "Manuf. jewellery and rel. art.",
    },
    {
        code: "32.13",
        score: "medium",
        name_no: "Produksjon av bijouteri og lignende artikler",
        short_name_no: "Bijouterivareproduksjon mv.",
        name_en: "Manufacture of imitation jewellery and related articles",
        short_name_en: "Manuf. imit. jewellery/re. art.",
    },
    {
        code: "32.20",
        score: "medium",
        name_no: "Produksjon av musikkinstrumenter",
        short_name_no: "Musikkinstrumentproduksjon",
        name_en: "Manufacture of musical instruments",
        short_name_en: "Manuf. of musical instruments",
    },
    {
        code: "32.30",
        score: "medium",
        name_no: "Produksjon av sportsartikler",
        short_name_no: "Sportsartikkelproduksjon",
        name_en: "Manufacture of sports goods",
        short_name_en: "Manuf. of sports goods",
    },
    {
        code: "32.40",
        score: "medium",
        name_no: "Produksjon av spill og leker",
        short_name_no: "Spill- og leketøyproduksjon",
        name_en: "Manufacture of games and toys",
        short_name_en: "Manuf. of games and toys",
    },
    {
        code: "32.50",
        score: "medium",
        name_no: "Produksjon av medisinske og tanntekniske instrumenter og utstyr",
        short_name_no: "Medisinske og tanntekniske instr.",
        name_en: "Manufacture of medical and dental instruments and supplies",
        short_name_en: "Manuf. of med./dental instr. etc.",
    },
    {
        code: "32.91",
        score: "medium",
        name_no: "Produksjon av koster og børster",
        short_name_no: "Kost- og børsteproduksjon",
        name_en: "Manufacture of brooms and brushes",
        short_name_en: "Manuf. brooms and brushes",
    },
    {
        code: "32.99",
        score: "medium",
        name_no: "Annen industriproduksjon ikke nevnt annet sted",
        short_name_no: "Industriproduksjon ellers",
        name_en: "Other manufacturing n.e.c.",
        short_name_en: "Manufacturing n.e.c.",
    },
    {
        code: "33.11",
        score: "medium",
        name_no: "Reparasjon av bearbeidede metallprodukter",
        short_name_no: "Rep. av bearbeidede metallprodukter",
        name_en: "Repair of fabricated metal products",
        short_name_en: "Rep. of fabricated metal prod.",
    },
    {
        code: "33.12",
        score: "medium",
        name_no: "Reparasjon av maskiner",
        short_name_no: "Reparasjon av maskiner",
        name_en: "Repair of machinery",
        short_name_en: "Rep. of machinery",
    },
    {
        code: "33.13",
        score: "medium",
        name_no: "Reparasjon av elektronisk og optisk utstyr",
        short_name_no: "Rep. av elektronisk og optisk utstyr",
        name_en: "Repair of electronic and optical equipment",
        short_name_en: "Rep. of electron. and optical equip.",
    },
    {
        code: "33.14",
        score: "medium",
        name_no: "Reparasjon av elektrisk utstyr",
        short_name_no: "Rep. av elektrisk utstyr",
        name_en: "Repair of electrical equipment",
        short_name_en: "Rep. of electrical equip.",
    },
    {
        code: "33.15",
        score: "medium",
        name_no: "Reparasjon og vedlikehold av skip og båter",
        short_name_no: "Rep./vedlikehold av skip og båter",
        name_en: "Repair and maintenance of ships and boats",
        short_name_en: "Rep./maint. of ships and boats",
    },
    {
        code: "33.16",
        score: "medium",
        name_no: "Reparasjon og vedlikehold av luftfartøyer og romfartøyer",
        short_name_no: "Rep./vedlikeh. luft- og romfartøyer",
        name_en: "Repair and maintenance of aircraft and spacecraft",
        short_name_en: "Rep./maint. aircraft and spacecraft",
    },
    {
        code: "33.17",
        score: "medium",
        name_no: "Reparasjon og vedlikehold av andre transportmidler",
        short_name_no: "Rep./vedlikeh. av andre transportmidler",
        name_en: "Repair and maintenance of other transport equipment",
        short_name_en: "Rep./maint. other transp. equip.",
    },
    {
        code: "33.19",
        score: "medium",
        name_no: "Reparasjon av annet utstyr",
        short_name_no: "Rep. av annet utstyr",
        name_en: "Repair of other equipment",
        short_name_en: "Rep. of other equip.",
    },
    {
        code: "33.20",
        score: "medium",
        name_no: "Installasjon av industrimaskiner og -utstyr",
        short_name_no: "Installasjon industrimask. og utstyr",
        name_en: "Installation of industrial machinery and equipment",
        short_name_en: "Install. of ind. mach. and equip.",
    },
    {
        code: "35.11",
        score: "high",
        name_no: "Produksjon av elektrisitet",
        short_name_no: "Produksjon av elektrisitet",
        name_en: "Production of electricity",
        short_name_en: "Prod of electricity",
    },
    {
        code: "35.12",
        score: "high",
        name_no: "Overføring av elektrisitet",
        short_name_no: "Overføring av elektrisitet",
        name_en: "Transmission of electricity",
        short_name_en: "Transmission of electricity",
    },
    {
        code: "35.13",
        score: "high",
        name_no: "Distribusjon av elektrisitet",
        short_name_no: "Distribusjon av elektrisitet",
        name_en: "Distribution of electricity",
        short_name_en: "Distrib. of electricity",
    },
    {
        code: "35.14",
        score: "low",
        name_no: "Handel med elektrisitet",
        short_name_no: "Handel med elektrisitet",
        name_en: "Trade of electricity",
        short_name_en: "Trade of electricity",
    },
    {
        code: "35.21",
        score: "high",
        name_no: "Produksjon av gass",
        short_name_no: "Gassproduksjon",
        name_en: "Manufacture of gas",
        short_name_en: "Manuf. of gas",
    },
    {
        code: "35.22",
        score: "medium",
        name_no: "Distribusjon av gass gjennom ledningsnett",
        short_name_no: "Gassdistrib. via. ledningsnett",
        name_en: "Distribution of gaseous fuels through mains",
        short_name_en: "Distrib. gaseous fuels through mains",
    },
    {
        code: "35.23",
        score: "low",
        name_no: "Handel med gass gjennom ledningsnett",
        short_name_no: "Handel med gass via ledningsnett",
        name_en: "Trade of gas through mains",
        short_name_en: "Trade of gas through mains",
    },
    {
        code: "35.30",
        score: "medium",
        name_no: "Damp- og varmtvannsforsyning",
        short_name_no: "Damp- og varmtvannsforsyning",
        name_en: "Steam and air conditioning supply",
        short_name_en: "Steam and air conditioning supply",
    },
    {
        code: "36.00",
        score: "medium",
        name_no: "Uttak fra kilde, rensing og distribusjon av vann",
        short_name_no: "Vannforsyning",
        name_en: "Water collection, treatment and supply",
        short_name_en: "Water supply",
    },
    {
        code: "37.00",
        score: "high",
        name_no: "Oppsamling og behandling av avløpsvann",
        short_name_no: "Håndtering av avløpsvann",
        name_en: "Sewerage",
        short_name_en: "Sewerage",
    },
    {
        code: "38.11",
        score: "medium",
        name_no: "Innsamling av ikke-farlig avfall",
        short_name_no: "Innsaml. av ikke-farlig avfall",
        name_en: "Collection of non-hazardous waste",
        short_name_en: "Coll. of non-hazardous waste",
    },
    {
        code: "38.12",
        score: "high",
        name_no: "Innsamling av farlig avfall",
        short_name_no: "Innsamling av farlig avfall",
        name_en: "Collection of hazardous waste",
        short_name_en: "Coll. of hazardous waste",
    },
    {
        code: "38.21",
        score: "high",
        name_no: "Behandling og disponering av ikke-farlig avfall",
        short_name_no: "Behandl. og disp. av ikke-farlig avfall",
        name_en: "Treatment and disposal of non-hazardous waste",
        short_name_en: "Treat./disp. of non-hazard. waste",
    },
    {
        code: "38.22",
        score: "high",
        name_no: "Behandling og disponering av farlig avfall",
        short_name_no: "Behandl. og disp. av farlig avfall",
        name_en: "Treatment and disposal of hazardous waste",
        short_name_en: "Treat./disp. of hazardous waste",
    },
    {
        code: "38.31",
        score: "high",
        name_no: "Demontering av vrakede gjenstander",
        short_name_no: "Demont. av vrakede gjenstander",
        name_en: "Dismantling of wrecks",
        short_name_en: "Dismantling of wrecks",
    },
    {
        code: "38.32",
        score: "high",
        name_no: "Sortering og bearbeiding av avfall for materialgjenvinning",
        short_name_no: "Sort./bearb. avfall for matr.gjenv.",
        name_en: "Recovering of sorted materials",
        short_name_en: "Recov. of sorted materials",
    },
    {
        code: "39.00",
        score: "high",
        name_no: "Miljørydding, miljørensing og lignende virksomhet",
        short_name_no: "Miljørydding og miljørensing",
        name_en: "Remediation activities and other waste management services",
        short_name_en: "Remediation, other waste managem.",
    },
    {
        code: "41.10",
        score: "low",
        name_no: "Utvikling av byggeprosjekter",
        short_name_no: "Utvikling av byggeprosjekter",
        name_en: "Development of building projects",
        short_name_en: "Development of building projects",
    },
    {
        code: "41.20",
        score: "high",
        name_no: "Oppføring av bygninger",
        short_name_no: "Oppføring av bygninger",
        name_en: "Construction of residential and non-residential buildings",
        short_name_en: "Construction of buildings",
    },
    {
        code: "42.11",
        score: "high",
        name_no: "Bygging av veier og motorveier",
        short_name_no: "Bygging av veier og motorveier",
        name_en: "Construction of roads and motorways",
        short_name_en: "Construc. of roads and motorways",
    },
    {
        code: "42.12",
        score: "high",
        name_no: "Bygging av jernbaner og undergrunnsbaner",
        short_name_no: "Bygg. av jernbaner/undergrunnsbaner",
        name_en: "Construction of railways and underground railways",
        short_name_en: "Construc. railways etc.",
    },
    {
        code: "42.13",
        score: "high",
        name_no: "Bygging av bruer og tunneler",
        short_name_no: "Bygging av bruer og tunneler",
        name_en: "Construction of bridges and tunnels",
        short_name_en: "Construc. bridges and tunnels",
    },
    {
        code: "42.21",
        score: "high",
        name_no: "Bygging av vann- og kloakkanlegg",
        short_name_no: "Bygging av vann- og kloakkanl.",
        name_en: "Construction of utility projects for fluids",
        short_name_en: "Construc. utility proj. for fluids",
    },
    {
        code: "42.22",
        score: "high",
        name_no: "Bygging av anlegg for elektrisitet og telekommunikasjon",
        short_name_no: "Bygg. av el.anl. og telekomm.anl.",
        name_en: "Construction of utility projects for electricity and telecommunications",
        short_name_en: "Constr. utility proj. el./telecomm.",
    },
    {
        code: "42.91",
        score: "high",
        name_no: "Bygging av havne- og damanlegg",
        short_name_no: "Bygging av havne- og damanlegg",
        name_en: "Construction of water projects",
        short_name_en: "Construc. of water projects",
    },
    {
        code: "42.99",
        score: "high",
        name_no: "Bygging av andre anlegg ikke nevnt annet sted",
        short_name_no: "Bygging av andre anlegg",
        name_en: "Construction of other civil engineering projects n.e.c.",
        short_name_en: "Construc. other civ. eng. proj. n.e.c.",
    },
    {
        code: "43.11",
        score: "high",
        name_no: "Riving av bygninger og andre konstruksjoner",
        short_name_no: "Riv. av bygn./andre konstruksjoner",
        name_en: "Demolition",
        short_name_en: "Demolition",
    },
    {
        code: "43.12",
        score: "high",
        name_no: "Grunnarbeid",
        short_name_no: "Grunnarbeid",
        name_en: "Site preparation",
        short_name_en: "Site preparation",
    },
    {
        code: "43.13",
        score: "high",
        name_no: "Prøveboring",
        short_name_no: "Prøveboring",
        name_en: "Test drilling and boring",
        short_name_en: "Test drilling and boring",
    },
    {
        code: "43.21",
        score: "medium",
        name_no: "Elektrisk installasjonsarbeid",
        short_name_no: "Elektrisk installasjonsarbeid",
        name_en: "Electrical installation",
        short_name_en: "Electrical installation",
    },
    {
        code: "43.22",
        score: "medium",
        name_no: "VVS-arbeid",
        short_name_no: "VVS-arbeid",
        name_en: "Plumbing, heat and air-conditioning installation",
        short_name_en: "Plumbing etc.",
    },
    {
        code: "43.29",
        score: "medium",
        name_no: "Annet installasjonsarbeid",
        short_name_no: "Annet installasjonsarbeid",
        name_en: "Other construction installation",
        short_name_en: "Other constr. installation",
    },
    {
        code: "43.31",
        score: "medium",
        name_no: "Stukkatørarbeid og pussing",
        short_name_no: "Stukkatørarbeid og pussing",
        name_en: "Plastering",
        short_name_en: "Plastering",
    },
    {
        code: "43.32",
        score: "medium",
        name_no: "Snekkerarbeid",
        short_name_no: "Snekkerarbeid",
        name_en: "Joinery installation",
        short_name_en: "Joinery installation",
    },
    {
        code: "43.33",
        score: "medium",
        name_no: "Gulvlegging og tapetsering",
        short_name_no: "Gulvlegging og tapetsering",
        name_en: "Floor and wall covering",
        short_name_en: "Floor and wall covering",
    },
    {
        code: "43.34",
        score: "medium",
        name_no: "Maler- og glassarbeid",
        short_name_no: "Maler- og glassarbeid",
        name_en: "Painting and glazing",
        short_name_en: "Painting and glazing",
    },
    {
        code: "43.39",
        score: "medium",
        name_no: "Annen ferdiggjøring av bygninger",
        short_name_no: "Annen ferdiggjøring av bygninger",
        name_en: "Other building completion and finishing",
        short_name_en: "Other build. compl. and finish.",
    },
    {
        code: "43.91",
        score: "high",
        name_no: "Takarbeid",
        short_name_no: "Takarbeid",
        name_en: "Roofing activities",
        short_name_en: "Roofing activities",
    },
    {
        code: "43.99",
        score: "high",
        name_no: "Annen spesialisert bygge- og anleggsvirksomhet",
        short_name_no: "Annen spes. bygge- og anleggsvirks.",
        name_en: "Other specialised construction activities n.e.c.",
        short_name_en: "Other spec. construc. act. n.e.c.",
    },
    {
        code: "45.11",
        score: "low",
        name_no: "Handel med biler og lette motorvogner, unntatt motorsykler",
        short_name_no: "Handel med biler og lette motorv.",
        name_en: "Sale of cars and light motor vehicles",
        short_name_en: "Sale of cars/light mot veh.",
    },
    {
        code: "45.19",
        score: "low",
        name_no: "Handel med andre motorvogner, unntatt motorsykler",
        short_name_no: "Handel med andre motorvogner",
        name_en: "Sale of other motor vehicles",
        short_name_en: "Sale of other motor vehicles",
    },
    {
        code: "45.20",
        score: "low",
        name_no: "Vedlikehold og reparasjon av motorvogner, unntatt motorsykler",
        short_name_no: "Rep. av motorvogner/unnt. motorsyk.",
        name_en: "Maintenance and repair of motor vehicles",
        short_name_en: "Maint./repair of motor vehicles",
    },
    {
        code: "45.31",
        score: "low",
        name_no: "Agentur- og engroshandel med deler og utstyr til motorvogner, unntatt motorsykler",
        short_name_no: "Agent.-/engrosh. motorv./-deler mv.",
        name_en: "Wholesale trade of motor vehicle parts and accessories",
        short_name_en: "W.sale trade of motor veh. parts",
    },
    {
        code: "45.32",
        score: "low",
        name_no: "Detaljhandel med deler og utstyr til motorvogner, unntatt motorsykler",
        short_name_no: "Detalj. deler/utstyr til motorvogn",
        name_en: "Retail trade of motor vehicle parts and accessories",
        short_name_en: "Ret. trade of motor veh. parts",
    },
    {
        code: "45.40",
        score: "low",
        name_no: "Handel med motorsykler, deler og utstyr. Vedlikehold og reparasjon av motorsykler",
        short_name_no: "Motorsykkelhandel og -rep. mv.",
        name_en: "Sale, maintenance and repair of motorcycles and related parts and accessories",
        short_name_en: "Sale and repair of motorcycles etc.",
    },
    {
        code: "46.11",
        score: "low",
        name_no: "Agenturhandel med jordbruksråvarer, levende dyr, tekstilråvarer og innsatsvarer",
        short_name_no: "Agentur jordbr. og tekstilråv. mv.",
        name_en:
            "Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods",
        short_name_en: "Agents sale of agricult. raw mater. etc.",
    },
    {
        code: "46.12",
        score: "low",
        name_no: "Agenturhandel med brensel, drivstoff, malm, metaller og industrikjemikalier",
        short_name_no: "Agentur brensel, drivstoff mv.",
        name_en: "Agents involved in the sale of fuels, ores, metals and industrial chemicals",
        short_name_en: "Agents sale of fuels, ores etc.",
    },
    {
        code: "46.13",
        score: "low",
        name_no: "Agenturhandel med tømmer, trelast og byggevarer",
        short_name_no: "Agentur tømmer, trelast, byggev.",
        name_en: "Agents involved in the sale of timber and building materials",
        short_name_en: "Agents sale of timber, build. matr.",
    },
    {
        code: "46.14",
        score: "low",
        name_no: "Agenturhandel med maskiner, produksjonsutstyr, båter og luftfartøyer",
        short_name_no: "Agentur maskiner, prod.utstyr mv.",
        name_en: "Agents involved in the sale of machinery, industrial equipment, ships and aircraft",
        short_name_en: "Agents sale of mach., ind. equipm.",
    },
    {
        code: "46.15",
        score: "low",
        name_no: "Agenturhandel med møbler, husholdningsvarer og jernvarer",
        short_name_no: "Agentur møbler, hush.- og jernvarer",
        name_en: "Agents involved in the sale of furniture, household goods, hardware and ironmongery",
        short_name_en: "Agents sale of furniture etc.",
    },
    {
        code: "46.16",
        score: "low",
        name_no: "Agenturhandel med tekstiler, klær, pelsskinn, skotøy og lærvarer",
        short_name_no: "Agentur tekstiler/klær/skotøy mv.",
        name_en: "Agents involved in the sale of textiles, clothing, fur, footwear and leather goods",
        short_name_en: "Agents sale of text., clothes etc.",
    },
    {
        code: "46.17",
        score: "low",
        name_no: "Agenturhandel med nærings- og nytelsesmidler",
        short_name_no: "Agentur nærings- og nytelsesmidler",
        name_en: "Agents involved in the sale of food, beverages and tobacco",
        short_name_en: "Agents sale of food, beverages etc.",
    },
    {
        code: "46.18",
        score: "low",
        name_no: "Agenturhandel med spesialisert vareutvalg ellers",
        short_name_no: "Agentur spesialisert vareutvalg el.",
        name_en: "Agents specialised in the sale of other particular products",
        short_name_en: "Agents sale of other partic. prod.",
    },
    {
        code: "46.19",
        score: "low",
        name_no: "Agenturhandel med bredt vareutvalg",
        short_name_no: "Agentur bredt vareutvalg",
        name_en: "Agents involved in the sale of a variety of goods",
        short_name_en: "Agents sale of variety of goods",
    },
    {
        code: "46.21",
        score: "low",
        name_no: "Engroshandel med korn, råtobakk, såvarer og fôrvarer",
        short_name_no: "Engrosh. korn, såvarer og fôr",
        name_en: "Wholesale of grain, unmanufactured tobacco, seeds and animal feeds",
        short_name_en: "W.sale grain, unmanuf. tobacco etc.",
    },
    {
        code: "46.22",
        score: "low",
        name_no: "Engroshandel med blomster og planter",
        short_name_no: "Engrosh. blomster og planter",
        name_en: "Wholesale of flowers and plants",
        short_name_en: "W.sale of flower and plants",
    },
    {
        code: "46.23",
        score: "low",
        name_no: "Engroshandel med levende dyr",
        short_name_no: "Engrosh. levende dyr",
        name_en: "Wholesale of live animals",
        short_name_en: "W.sale of live animals",
    },
    {
        code: "46.24",
        score: "low",
        name_no: "Engroshandel med huder, skinn og lær",
        short_name_no: "Engrosh. huder, skinn og lær",
        name_en: "Wholesale of hides, skins and leather",
        short_name_en: "W.sale of hides, skins, leather",
    },
    {
        code: "46.31",
        score: "low",
        name_no: "Engroshandel med frukt og grønnsaker",
        short_name_no: "Engrosh. frukt og grønnsaker",
        name_en: "Wholesale of fruit and vegetables",
        short_name_en: "W.sale fruit, vegetables",
    },
    {
        code: "46.32",
        score: "low",
        name_no: "Engroshandel med kjøtt og kjøttvarer",
        short_name_no: "Engrosh. kjøtt og kjøttvarer",
        name_en: "Wholesale of meat and meat products",
        short_name_en: "W.sale meat, meat products",
    },
    {
        code: "46.33",
        score: "low",
        name_no: "Engroshandel med meierivarer, egg, matolje og -fett",
        short_name_no: "Engrosh. meierivarer, egg mv.",
        name_en: "Wholesale of dairy products, eggs and edible oils and fats",
        short_name_en: "W.sale dairy prod. eggs etc.",
    },
    {
        code: "46.34",
        score: "low",
        name_no: "Engroshandel med drikkevarer",
        short_name_no: "Engrosh. drikkevarer",
        name_en: "Wholesale of beverages",
        short_name_en: "W.sale beverages",
    },
    {
        code: "46.35",
        score: "low",
        name_no: "Engroshandel med tobakksvarer",
        short_name_no: "Engrosh. tobakksvarer",
        name_en: "Wholesale of tobacco products",
        short_name_en: "W.sale tobacco prod.",
    },
    {
        code: "46.36",
        score: "low",
        name_no: "Engroshandel med sukker, sjokolade og sukkervarer",
        short_name_no: "Engrosh. sukker, sjok. og sukkerv.",
        name_en: "Wholesale of sugar and chocolate and sugar confectionery",
        short_name_en: "W.sale sugar, chocolate etc.",
    },
    {
        code: "46.37",
        score: "low",
        name_no: "Engroshandel med kaffe, te, kakao og krydder",
        short_name_no: "Engrosh. kaffe, te, kakao mv.",
        name_en: "Wholesale of coffee, tea, cocoa and spices",
        short_name_en: "W.sale coffee, tea, spices etc.",
    },
    {
        code: "46.38",
        score: "low",
        name_no: "Engroshandel med andre næringsmidler, herunder fisk, skalldyr og bløtdyr",
        short_name_no: "Engrosh. andre næringsmidler",
        name_en: "Wholesale of other food, including fish, crustaceans and molluscs",
        short_name_en: "W.sale fish, crustaceans etc.",
    },
    {
        code: "46.39",
        score: "low",
        name_no: "Engroshandel med bredt utvalg av nærings- og nytelsesmidler",
        short_name_no: "Engrosh. bredt utv. nær.-/nyt. midl",
        name_en: "Non-specialised wholesale of food, beverages and tobacco",
        short_name_en: "Non-spec. w.sale food, bever., tobacco",
    },
    {
        code: "46.41",
        score: "low",
        name_no: "Engroshandel med tekstiler og utstyrsvarer",
        short_name_no: "Engrosh. tekstiler og utstyrsvarer",
        name_en: "Wholesale of textiles",
        short_name_en: "W.sale textiles",
    },
    {
        code: "46.42",
        score: "low",
        name_no: "Engroshandel med klær og skotøy",
        short_name_no: "Engrosh. klær og skotøy",
        name_en: "Wholesale of clothing and footwear",
        short_name_en: "W.sale clothing, footwear",
    },
    {
        code: "46.43",
        score: "low",
        name_no: "Engroshandel med elektriske husholdningsapparater",
        short_name_no: "Engrosh. el. husholdningsapp. mv..",
        name_en: "Wholesale of electrical household appliances",
        short_name_en: "W.sale elec. househ. appl.",
    },
    {
        code: "46.44",
        score: "low",
        name_no: "Engroshandel med kjøkkenutstyr, glass, steintøy og rengjøringsmidler",
        short_name_no: "Engrosh. kjøkkenutstyr, steintøy mv.",
        name_en: "Wholesale of china and glassware and cleaning materials",
        short_name_en: "W.sale china, glass, clean. mater.",
    },
    {
        code: "46.45",
        score: "low",
        name_no: "Engroshandel med parfyme og kosmetikk",
        short_name_no: "Engrosh. parfyme og kosmetikk",
        name_en: "Wholesale of perfume and cosmetics",
        short_name_en: "W.sale perfume, cosmetics",
    },
    {
        code: "46.46",
        score: "low",
        name_no: "Engroshandel med sykepleie- og apotekvarer",
        short_name_no: "Engrosh. sykepleie- og apotekvarer",
        name_en: "Wholesale of pharmaceutical goods",
        short_name_en: "W.sale pharmaceut. goods",
    },
    {
        code: "46.47",
        score: "low",
        name_no: "Engroshandel med møbler, gulvbelegg, gulvtepper og belysningsutstyr",
        short_name_no: "Engrosh. møbler og innredningsart.",
        name_en: "Wholesale of furniture, carpets and lighting equipment",
        short_name_en: "W.sale furniture, carpets etc.",
    },
    {
        code: "46.48",
        score: "low",
        name_no: "Engroshandel med klokker, ur, gull- og sølvvarer",
        short_name_no: "Engrosh. klokker/ur/gull-/sølvvarer",
        name_en: "Wholesale of watches and jewellery",
        short_name_en: "W.sale watches, clocks",
    },
    {
        code: "46.49",
        score: "low",
        name_no: "Engroshandel med husholdningsvarer og varer til personlig bruk ellers",
        short_name_no: "Engrosh. husholdningsvarer m.m.",
        name_en: "Wholesale of other household goods",
        short_name_en: "W.sale other househ. goods",
    },
    {
        code: "46.51",
        score: "low",
        name_no: "Engroshandel med datamaskiner, tilleggsutstyr til datamaskiner samt programvare",
        short_name_no: "Engrosh. datamask., programv. mv..",
        name_en: "Wholesale of computers, computer peripheral equipment and software",
        short_name_en: "W.sale computers, software etc.",
    },
    {
        code: "46.52",
        score: "low",
        name_no: "Engroshandel med elektronikkutstyr og telekommunikasjonsutstyr samt deler",
        short_name_no: "Engrosh. elektronikk-/telekomm.uts.",
        name_en: "Wholesale of electronic and telecommunications equipment and parts",
        short_name_en: "W.sale elec. and telecomm. equip.",
    },
    {
        code: "46.61",
        score: "low",
        name_no: "Engroshandel med maskiner og utstyr til jordbruk og skogbruk",
        short_name_no: "Engrosh. jordbr.-/skogbr.mask. mv.",
        name_en: "Wholesale of agricultural machinery, equipment and supplies",
        short_name_en: "W.sale agric. mach., equip. etc.",
    },
    {
        code: "46.62",
        score: "low",
        name_no: "Engroshandel med maskinverktøy",
        short_name_no: "Engrosh. maskinverktøy",
        name_en: "Wholesale of machine tools",
        short_name_en: "W.sale machine tools",
    },
    {
        code: "46.63",
        score: "low",
        name_no:
            "Engroshandel med maskiner og utstyr til bergverksdrift, olje- og gassutvinning og bygge- og anleggsvirksomhet",
        short_name_no: "Engros mask bygg/anl./oljeutv. mv.",
        name_en: "Wholesale of mining, construction and civil engineering machinery",
        short_name_en: "W.sale mining., construc mach. etc.",
    },
    {
        code: "46.64",
        score: "low",
        name_no: "Engroshandel med maskiner og utstyr til tekstilproduksjon",
        short_name_no: "Engrosh. tekstilprod.maskiner mv.",
        name_en: "Wholesale of machinery for textile industry and of sewing and knitting machines",
        short_name_en: "W.sale mach. textile industry etc.",
    },
    {
        code: "46.65",
        score: "low",
        name_no: "Engroshandel med kontormøbler",
        short_name_no: "Engrosh. kontormøbler",
        name_en: "Wholesale of office furniture",
        short_name_en: "W.sale office furniture",
    },
    {
        code: "46.66",
        score: "low",
        name_no: "Engroshandel med maskiner og utstyr til kontor ellers",
        short_name_no: "Engrosh. kontormask., -utstyr el.",
        name_en: "Wholesale of other office machinery and equipment",
        short_name_en: "W.sale other office mach./equip.",
    },
    {
        code: "46.69",
        score: "low",
        name_no: "Engroshandel med maskiner og utstyr ellers",
        short_name_no: "Engrosh. maskiner og utstyr el.",
        name_en: "Wholesale of other machinery and equipment",
        short_name_en: "W.sale other mach. and equip.",
    },
    {
        code: "46.71",
        score: "high",
        name_no: "Engroshandel med drivstoff og brensel",
        short_name_no: "Engrosh. drivstoff og brensel",
        name_en: "Wholesale of solid, liquid and gaseous fuels and related products",
        short_name_en: "W.sale of solid, liquid-/gas.fuels etc.",
    },
    {
        code: "46.72",
        score: "high",
        name_no: "Engroshandel med metaller og metallholdig malm",
        short_name_no: "Engrosh. metaller og metallh. malm",
        name_en: "Wholesale of metals and metal ores",
        short_name_en: "W.sale metal and metal ores",
    },
    {
        code: "46.73",
        score: "low",
        name_no: "Engroshandel med tømmer, trelast, byggevarer og sanitærutstyr",
        short_name_no: "Engrosh. tømmer/trelast/byggev. mv.",
        name_en: "Wholesale of wood, construction materials and sanitary equipment",
        short_name_en: "W.sale wood, construc. mach. etc.",
    },
    {
        code: "46.74",
        score: "low",
        name_no: "Engroshandel med jernvarer, rørleggerartikler og oppvarmingsutstyr",
        short_name_no: "Engrosh. jernv., rørleggerart. m.m.",
        name_en: "Wholesale of hardware, plumbing and heating equipment and supplies",
        short_name_en: "W.sale hardware, plumb, heat equip.",
    },
    {
        code: "46.75",
        score: "high",
        name_no: "Engroshandel med kjemiske produkter",
        short_name_no: "Engrosh. kjemiske produkter",
        name_en: "Wholesale of chemical products",
        short_name_en: "W.sale chemical prod.",
    },
    {
        code: "46.76",
        score: "low",
        name_no: "Engroshandel med innsatsvarer ellers",
        short_name_no: "Engrosh. innsatsvarer el.",
        name_en: "Wholesale of other intermediate products",
        short_name_en: "W.sale other intermediate prod.",
    },
    {
        code: "46.77",
        score: "high",
        name_no: "Engroshandel med avfall og skrap",
        short_name_no: "Engrosh. avfall og skrap",
        name_en: "Wholesale of waste and scrap",
        short_name_en: "W.sale waste and scrap",
    },
    {
        code: "46.90",
        score: "low",
        name_no: "Uspesifisert engroshandel",
        short_name_no: "Uspesifisert engroshandel",
        name_en: "Non-specialised wholesale trade",
        short_name_en: "Non-specialised w.sale trade",
    },
    {
        code: "47.11",
        score: "medium",
        name_no: "Butikkhandel med bredt vareutvalg med hovedvekt på nærings- og nytelsesmidler",
        short_name_no: "Butikkh. bredt utvalg nær.midler mv.",
        name_en: "Retail sale in non-specialised stores with food, beverages or tobacco predominating",
        short_name_en: "Non-spec. stores w.food,bev.etc",
    },
    {
        code: "47.19",
        score: "low",
        name_no: "Butikkhandel med bredt vareutvalg ellers",
        short_name_no: "Butikkh. bredt vareutvalg el.",
        name_en: "Other retail sale in non-specialised stores",
        short_name_en: "Other ret. sale in non-spec. stores",
    },
    {
        code: "47.21",
        score: "low",
        name_no: "Butikkhandel med frukt og grønnsaker",
        short_name_no: "Butikkh. frukt og grønnsaker",
        name_en: "Retail sale of fruit and vegetables in specialised stores",
        short_name_en: "Ret. sale fruit, vegetables",
    },
    {
        code: "47.22",
        score: "low",
        name_no: "Butikkhandel med kjøtt og kjøttvarer",
        short_name_no: "Butikkh. kjøtt og kjøttvarer",
        name_en: "Retail sale of meat and meat products in specialised stores",
        short_name_en: "Ret.sale meat, meat products",
    },
    {
        code: "47.23",
        score: "low",
        name_no: "Butikkhandel med fisk, skalldyr og bløtdyr",
        short_name_no: "Butikkh. fisk, skalldyr og bløtdyr",
        name_en: "Retail sale of fish, crustaceans and molluscs in specialised stores",
        short_name_en: "Ret. sale fish, crustaceans etc.",
    },
    {
        code: "47.24",
        score: "low",
        name_no: "Butikkhandel med bakervarer, konditorvarer og sukkervarer",
        short_name_no: "Butikkh. baker-, konditorvarer mv.",
        name_en: "Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores",
        short_name_en: "Ret. sale bread, cakes etc.",
    },
    {
        code: "47.25",
        score: "low",
        name_no: "Butikkhandel med drikkevarer",
        short_name_no: "Butikkh. drikkevarer",
        name_en: "Retail sale of beverages in specialised stores",
        short_name_en: "Ret. sale beverages",
    },
    {
        code: "47.26",
        score: "low",
        name_no: "Butikkhandel med tobakksvarer",
        short_name_no: "Butikkh. tobakksvarer",
        name_en: "Retail sale of tobacco products in specialised stores",
        short_name_en: "Ret. sale of tobacco products",
    },
    {
        code: "47.29",
        score: "low",
        name_no: "Butikkhandel med nærings- og nytelsesmidler ellers",
        short_name_no: "Butikkh. nær.- og nytelsesmidler el.",
        name_en: "Other retail sale of food in specialised stores",
        short_name_en: "Other ret. sale of food",
    },
    {
        code: "47.30",
        score: "medium",
        name_no: "Detaljhandel med drivstoff til motorvogner",
        short_name_no: "Detaljh. drivstoff til motorvogner",
        name_en: "Retail sale of automotive fuel in specialised stores",
        short_name_en: "Ret. sale of automotive fuel",
    },
    {
        code: "47.41",
        score: "low",
        name_no: "Butikkhandel med datamaskiner og utstyr til datamaskiner",
        short_name_no: "Butikkh. datamask. og tilleggsutst.",
        name_en: "Retail sale of computers, peripheral units and software in specialised stores",
        short_name_en: "Ret. sale of computers, software etc.",
    },
    {
        code: "47.42",
        score: "low",
        name_no: "Butikkhandel med telekommunikasjonsutstyr",
        short_name_no: "Butikkh. telekommunikasjonsutstyr",
        name_en: "Retail sale of telecommunications equipment in specialised stores",
        short_name_en: "Ret. sale of telecomm. equip.",
    },
    {
        code: "47.43",
        score: "low",
        name_no: "Butikkhandel med audio- og videoutstyr",
        short_name_no: "Butikkh. audio- og videoutstyr",
        name_en: "Retail sale of audio and video equipment in specialised stores",
        short_name_en: "Ret. sale of audio/video equip.",
    },
    {
        code: "47.51",
        score: "low",
        name_no: "Butikkhandel med tekstiler og utstyrsvarer",
        short_name_no: "Butikkh. tekstiler og utstyrsvarer",
        name_en: "Retail sale of textiles in specialised stores",
        short_name_en: "Ret. sale of textiles",
    },
    {
        code: "47.52",
        score: "low",
        name_no: "Butikkhandel med jernvarer, fargevarer og glass",
        short_name_no: "Butikkh. jernv., fargev. og glass",
        name_en: "Retail sale of hardware, paints and glass in specialised stores",
        short_name_en: "Ret. sale hardware, paints, glass etc.",
    },
    {
        code: "47.53",
        score: "low",
        name_no: "Butikkhandel med tapeter, gulvtepper og gardiner",
        short_name_no: "Butikkh. tapet/gulvtepper/gardiner",
        name_en: "Retail sale of carpets, rugs, wall and floor coverings in specialised stores",
        short_name_en: "Ret. sale carpets, rugs, wall/floor cover.",
    },
    {
        code: "47.54",
        score: "low",
        name_no: "Butikkhandel med elektriske husholdningsapparater",
        short_name_no: "Butikkh. el. hush.apparater",
        name_en: "Retail sale of electrical household appliances in specialised stores",
        short_name_en: "Ret. sale elec. househ. appliances",
    },
    {
        code: "47.59",
        score: "low",
        name_no: "Butikkhandel med møbler, belysningsutstyr og andre innredningsartikler",
        short_name_no: "Butikkh. møbler/belys.utstyr mv.",
        name_en: "Retail sale of furniture, lighting equipment and other household articles in specialised stores",
        short_name_en: "Ret. sale furniture, light. equip. etc.",
    },
    {
        code: "47.61",
        score: "low",
        name_no: "Butikkhandel med bøker",
        short_name_no: "Butikkh. bøker",
        name_en: "Retail sale of books in specialised stores",
        short_name_en: "Ret. sale books",
    },
    {
        code: "47.62",
        score: "low",
        name_no: "Butikkhandel med aviser og papirvarer",
        short_name_no: "Butikkh. aviser og papirvarer",
        name_en: "Retail sale of newspapers and stationery in specialised stores",
        short_name_en: "Ret. sale newspapers, stationery",
    },
    {
        code: "47.63",
        score: "low",
        name_no: "Butikkhandel med innspillinger av musikk og video",
        short_name_no: "Butikkh. innspill. av musikk/video",
        name_en: "Retail sale of music and video recordings in specialised stores",
        short_name_en: "Ret. sale music/video record.",
    },
    {
        code: "47.64",
        score: "low",
        name_no: "Butikkhandel med sportsutstyr",
        short_name_no: "Butikkh. sportsutstyr",
        name_en: "Retail sale of sporting equipment in specialised stores",
        short_name_en: "Ret. sale sporting equip.",
    },
    {
        code: "47.65",
        score: "low",
        name_no: "Butikkhandel med spill og leker",
        short_name_no: "Butikkh. spill og leker",
        name_en: "Retail sale of games and toys in specialised stores",
        short_name_en: "Ret. sale games, toys",
    },
    {
        code: "47.71",
        score: "low",
        name_no: "Butikkhandel med klær",
        short_name_no: "Butikkh. klær",
        name_en: "Retail sale of clothing in specialised stores",
        short_name_en: "Ret. sale clothing",
    },
    {
        code: "47.72",
        score: "low",
        name_no: "Butikkhandel med skotøy og lærvarer",
        short_name_no: "Butikkh. skotøy og lærvarer",
        name_en: "Retail sale of footwear and leather goods in specialised stores",
        short_name_en: "Ret. sale footwear, leather goods",
    },
    {
        code: "47.73",
        score: "medium",
        name_no: "Butikkhandel med apotekvarer",
        short_name_no: "Butikkh. apotekvarer",
        name_en: "Dispensing chemist in specialised stores",
        short_name_en: "Dispensing chemist",
    },
    {
        code: "47.74",
        score: "medium",
        name_no: "Butikkhandel med medisinske og ortopediske artikler",
        short_name_no: "Butikkh. medisinske/ortop. art.",
        name_en: "Retail sale of medical and orthopaedic goods in specialised stores",
        short_name_en: "Ret. sale medic./orthopaedic goods",
    },
    {
        code: "47.75",
        score: "medium",
        name_no: "Butikkhandel med kosmetikk og toalettartikler",
        short_name_no: "Butikkh. kosmetikk og toalettart.",
        name_en: "Retail sale of cosmetic and toilet articles in specialised stores",
        short_name_en: "Ret. sale cosmetic, toilet art.",
    },
    {
        code: "47.76",
        score: "low",
        name_no: "Butikkhandel med blomster og planter, kjæledyr og fôrvarer til kjæledyr",
        short_name_no: "Butikkh. blomster/planter mv.",
        name_en: "Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores",
        short_name_en: "Ret. sale flowers, plants etc.",
    },
    {
        code: "47.77",
        score: "low",
        name_no: "Butikkhandel med ur, gull- og sølvvarer",
        short_name_no: "Butikkh. ur, gull- og sølvvarer",
        name_en: "Retail sale of watches and jewellery in specialised stores",
        short_name_en: "Ret. sale watches, jewellery etc.",
    },
    {
        code: "47.78",
        score: "low",
        name_no: "Annen butikkhandel med andre nye varer i spesialforretninger",
        short_name_no: "Butikkh. nye varer i spes.forr. el.",
        name_en: "Other retail sale of new goods in specialised stores",
        short_name_en: "Other ret. sale of new goods",
    },
    {
        code: "47.79",
        score: "low",
        name_no: "Butikkhandel med brukte varer",
        short_name_no: "Butikkh. brukte varer",
        name_en: "Retail sale of second-hand goods in stores",
        short_name_en: "Ret. sale of second-hand goods",
    },
    {
        code: "47.81",
        score: "low",
        name_no: "Torghandel med næringsmidler, drikkevarer og tobakksvarer",
        short_name_no: "Torgh. nær.midl./drikkev./tobakksv.",
        name_en: "Retail sale via stalls and markets of food, beverages and tobacco products",
        short_name_en: "Stalls/markets, food, beverages etc",
    },
    {
        code: "47.82",
        score: "low",
        name_no: "Torghandel med tekstiler, klær, skotøy og utstyrsvarer",
        short_name_no: "Torgh. tekstiler, klær, skotøy m.m.",
        name_en: "Retail sale via stalls and markets of textiles, clothing and footwear",
        short_name_en: "Stall, markets  textiles, clothing etc.",
    },
    {
        code: "47.89",
        score: "low",
        name_no: "Torghandel med andre varer",
        short_name_no: "Torgh. andre varer",
        name_en: "Retail sale via stalls and markets of other goods",
        short_name_en: "Stalls/markets sale  of other goods",
    },
    {
        code: "47.91",
        score: "low",
        name_no: "Postordrehandel og handel via Internett",
        short_name_no: "Postordreh. og handel via Internett",
        name_en: "Retail sale via mail order houses or via Internet",
        short_name_en: "Ret. sale mail order houses/Internet",
    },
    {
        code: "47.99",
        score: "low",
        name_no: "Detaljhandel utenom utsalgssted ellers",
        short_name_no: "Detaljh. utenom utsalgssted el.",
        name_en: "Other retail sale not in stores, stalls or markets",
        short_name_en: "Other ret. sale not in stores, stalls etc.",
    },
    {
        code: "49.10",
        score: "low",
        name_no: "Passasjertransport med jernbane",
        short_name_no: "Passasjertransp. med jernbane",
        name_en: "Passenger rail transport, interurban",
        short_name_en: "Passenger rail transp.,  interurban",
    },
    {
        code: "49.20",
        score: "low",
        name_no: "Godstransport med jernbane",
        short_name_no: "Godstransp. med jernbane",
        name_en: "Freight rail transport",
        short_name_en: "Freight rail transport",
    },
    {
        code: "49.31",
        score: "medium",
        name_no: "Transport med rutebil og sporvei i by- og forstadsområde",
        short_name_no: "Rutebil/sporvei i by-/forstadsomr.",
        name_en: "Urban and suburban passenger land transport",
        short_name_en: "Urb./suburb. passenger land trans.",
    },
    {
        code: "49.32",
        score: "medium",
        name_no: "Drosjebiltransport",
        short_name_no: "Drosjebiltransport",
        name_en: "Taxi operation",
        short_name_en: "Taxi operation",
    },
    {
        code: "49.39",
        score: "medium",
        name_no: "Landtransport med passasjerer ikke nevnt annet sted",
        short_name_no: "Landtr. med passasjerer el.",
        name_en: "Other passenger land transport n.e.c.",
        short_name_en: "Other pass. land transp. n.e.c.",
    },
    {
        code: "49.41",
        score: "medium",
        name_no: "Godstransport på vei",
        short_name_no: "Godstransport på vei",
        name_en: "Freight transport by road",
        short_name_en: "Freight transp. by road",
    },
    {
        code: "49.42",
        score: "medium",
        name_no: "Flyttetransport",
        short_name_no: "Flyttetransport",
        name_en: "Removal services",
        short_name_en: "Removal services",
    },
    {
        code: "49.50",
        score: "medium",
        name_no: "Rørtransport",
        short_name_no: "Rørtransport",
        name_en: "Transport via pipeline",
        short_name_en: "Transport via pipeline",
    },
    {
        code: "50.10",
        score: "medium",
        name_no: "Sjøfart og kysttrafikk med passasjerer",
        short_name_no: "Sjøfart og kysttraf. m/passasjerer",
        name_en: "Sea and coastal passenger water transport",
        short_name_en: "Sea/coast. passenger water transp.",
    },
    {
        code: "50.20",
        score: "medium",
        name_no: "Sjøfart og kysttrafikk med gods",
        short_name_no: "Sjøfart og kysttrafikk med gods",
        name_en: "Sea and coastal freight water transport",
        short_name_en: "Sea/coast. freight water transp.",
    },
    {
        code: "50.30",
        score: "low",
        name_no: "Passasjertransport på elver og innsjøer",
        short_name_no: "Passasjertr. på elver og innsjøer",
        name_en: "Inland passenger water transport",
        short_name_en: "Inland passenger water transp.",
    },
    {
        code: "50.40",
        score: "medium",
        name_no: "Godstransport på elver og innsjøer",
        short_name_no: "Godstransp. på elver og innsjøer",
        name_en: "Inland freight water transport",
        short_name_en: "Inland freight water transp.",
    },
    {
        code: "51.10",
        score: "low",
        name_no: "Lufttransport med passasjerer",
        short_name_no: "Lufttransp. med passasjerer",
        name_en: "Passenger air transport",
        short_name_en: "Passenger air transport",
    },
    {
        code: "51.21",
        score: "low",
        name_no: "Lufttransport med gods",
        short_name_no: "Lufttransp. med gods",
        name_en: "Freight air transport",
        short_name_en: "Freight air transport",
    },
    {
        code: "51.22",
        score: "high",
        name_no: "Romfart",
        short_name_no: "Romfart",
        name_en: "Space transport",
        short_name_en: "Space transport",
    },
    {
        code: "52.10",
        score: "medium",
        name_no: "Lagring",
        short_name_no: "Lagring",
        name_en: "Warehousing and storage",
        short_name_en: "Warehousing and storage",
    },
    {
        code: "52.21",
        score: "medium",
        name_no: "Andre tjenester tilknyttet landtransport",
        short_name_no: "Andre tjen. tilkn. landtransport",
        name_en: "Service activities incidental to land transportation",
        short_name_en: "Serv. act. to land transp.",
    },
    {
        code: "52.22",
        score: "medium",
        name_no: "Andre tjenester tilknyttet sjøtransport",
        short_name_no: "Andre tjen. tilkn. sjøtransport",
        name_en: "Service activities incidental to water transportation",
        short_name_en: "Serv. act. to water transp.",
    },
    {
        code: "52.23",
        score: "medium",
        name_no: "Andre tjenester tilknyttet lufttransport",
        short_name_no: "Andre tjen. tilkn. lufttransport",
        name_en: "Service activities incidental to air transportation",
        short_name_en: "Serv. act. to air transp.",
    },
    {
        code: "52.24",
        score: "medium",
        name_no: "Lasting og lossing",
        short_name_no: "Lasting og lossing",
        name_en: "Cargo handling",
        short_name_en: "Cargo handling",
    },
    {
        code: "52.29",
        score: "medium",
        name_no: "Andre tjenester tilknyttet transport",
        short_name_no: "Andre tjenester tilknyttet transport",
        name_en: "Other transportation supply services",
        short_name_en: "Other transp. supply serv.",
    },
    {
        code: "53.10",
        score: "low",
        name_no: "Landsdekkende posttjenester",
        short_name_no: "Landsdekkende posttjenester",
        name_en: "Postal activities under universal service obligation",
        short_name_en: "Postal act. (univ. serv. obligation)",
    },
    {
        code: "53.20",
        score: "low",
        name_no: "Andre post- og budtjenester",
        short_name_no: "Andre post- og budtjenester",
        name_en: "Other postal and courier activities",
        short_name_en: "Other postal and courier act.",
    },
    {
        code: "55.10",
        score: "medium",
        name_no: "Hotellvirksomhet",
        short_name_no: "Hotellvirksomhet",
        name_en: "Hotels and similar accommodation",
        short_name_en: "Hotels and sim. accomm.",
    },
    {
        code: "55.20",
        score: "medium",
        name_no: "Drift av vandrerhjem og ferieleiligheter",
        short_name_no: "Drift av vandrerhjem/ferieleil.",
        name_en: "Holiday and short-stay accommodation",
        short_name_en: "Holiday/short-stay accomm.",
    },
    {
        code: "55.30",
        score: "medium",
        name_no: "Drift av campingplasser",
        short_name_no: "Drift av campingplasser",
        name_en: "Camping grounds, recreational vehicle parks and trailer parks",
        short_name_en: "Camping grounds etc.",
    },
    {
        code: "55.90",
        score: "medium",
        name_no: "Annen overnatting",
        short_name_no: "Annen overnatting",
        name_en: "Other accommodation",
        short_name_en: "Other accommodation",
    },
    {
        code: "56.10",
        score: "medium",
        name_no: "Restaurantvirksomhet",
        short_name_no: "Restaurantvirksomhet",
        name_en: "Restaurants and mobile food service activities",
        short_name_en: "Restaurants etc.",
    },
    {
        code: "56.21",
        score: "medium",
        name_no: "Cateringvirksomhet",
        short_name_no: "Cateringvirksomhet",
        name_en: "Event catering activities",
        short_name_en: "Event catering act.",
    },
    {
        code: "56.29",
        score: "medium",
        name_no: "Kantiner drevet som selvstendig virksomhet",
        short_name_no: "Kantinedrift, selvst. virksomhet",
        name_en: "Other food services",
        short_name_en: "Other food services",
    },
    {
        code: "56.30",
        score: "medium",
        name_no: "Drift av barer",
        short_name_no: "Drift av barer",
        name_en: "Beverage serving activities",
        short_name_en: "Beverage serving activities",
    },
    {
        code: "58.11",
        score: "low",
        name_no: "Utgivelse av bøker",
        short_name_no: "Utgivelse av bøker",
        name_en: "Book publishing",
        short_name_en: "Book publishing",
    },
    {
        code: "58.12",
        score: "low",
        name_no: "Utgivelse av kataloger og adresselister",
        short_name_no: "Utgiv. av kataloger/adresselister",
        name_en: "Publishing of directories and mailing lists",
        short_name_en: "Publ. directories/mailing lists",
    },
    {
        code: "58.13",
        score: "low",
        name_no: "Utgivelse av aviser",
        short_name_no: "Utgivelse av aviser",
        name_en: "Publishing of newspapers",
        short_name_en: "Publishing of newspapers",
    },
    {
        code: "58.14",
        score: "low",
        name_no: "Utgivelse av blader og tidsskrifter",
        short_name_no: "Utgivelse av blader og tidsskrifter",
        name_en: "Publishing of journals and periodicals",
        short_name_en: "Publ. journals and periodicals",
    },
    {
        code: "58.19",
        score: "low",
        name_no: "Forlagsvirksomhet ellers",
        short_name_no: "Forlagsvirksomhet ellers",
        name_en: "Other publishing activities",
        short_name_en: "Other publishing act.",
    },
    {
        code: "58.21",
        score: "low",
        name_no: "Utgivelse av programvare for dataspill",
        short_name_no: "Utgiv. av programv. for dataspill",
        name_en: "Publishing of computer games",
        short_name_en: "Publishing of computer games",
    },
    {
        code: "58.29",
        score: "low",
        name_no: "Utgivelse av annen programvare",
        short_name_no: "Utgiv. av annen programvare",
        name_en: "Other software publishing",
        short_name_en: "Other software publishing",
    },
    {
        code: "59.11",
        score: "low",
        name_no: "Produksjon av film, video og fjernsynsprogrammer",
        short_name_no: "Prod. av film/video/fjernsynsprogr.",
        name_en: "Motion picture, video and television programme production activities",
        short_name_en: "Prod. motion pict./video etc.",
    },
    {
        code: "59.12",
        score: "low",
        name_no: "Etterarbeid knyttet til produksjon av film, video og fjernsynsprogrammer",
        short_name_no: "Etterarb. til film-/video-/TV-prod.",
        name_en: "Motion picture, video and television programme post-production activities",
        short_name_en: "Post-prod. motion pict., video etc.",
    },
    {
        code: "59.13",
        score: "low",
        name_no: "Distribusjon av film, video og fjernsynsprogrammer",
        short_name_no: "Distrib. film/video/fjernsynsprogr.",
        name_en: "Motion picture, video and television programme distribution activities",
        short_name_en: "Distrib. motion pict., video etc.",
    },
    {
        code: "59.14",
        score: "low",
        name_no: "Filmframvisning",
        short_name_no: "Filmframvisning",
        name_en: "Motion picture projection activities",
        short_name_en: "Motion pict. projection act.",
    },
    {
        code: "59.20",
        score: "low",
        name_no: "Produksjon og utgivelse av musikk- og lydopptak",
        short_name_no: "Prod./utgiv. av musikk-/lydopptak",
        name_en: "Sound recording and music publishing activities",
        short_name_en: "Sound record./music publishing act.",
    },
    {
        code: "60.10",
        score: "low",
        name_no: "Radiokringkasting",
        short_name_no: "Radiokringkasting",
        name_en: "Radio broadcasting",
        short_name_en: "Radio broadcasting",
    },
    {
        code: "60.20",
        score: "low",
        name_no: "Fjernsynskringkasting",
        short_name_no: "Fjernsynskringkasting",
        name_en: "Television programming and broadcasting activities",
        short_name_en: "Television progr./broadcast. act.",
    },
    {
        code: "61.10",
        score: "medium",
        name_no: "Kabelbasert telekommunikasjon",
        short_name_no: "Kabelbasert telekomm.",
        name_en: "Wired telecommunications activities",
        short_name_en: "Wired telecommunications act.",
    },
    {
        code: "61.20",
        score: "medium",
        name_no: "Trådløs telekommunikasjon",
        short_name_no: "Trådløs telekomm.",
        name_en: "Wireless telecommunications activities",
        short_name_en: "Wireless telecommunications act.",
    },
    {
        code: "61.30",
        score: "medium",
        name_no: "Satellittbasert telekommunikasjon",
        short_name_no: "Satellittbasert telekomm.",
        name_en: "Satellite telecommunications activities",
        short_name_en: "Satellite telecommunications act.",
    },
    {
        code: "61.90",
        score: "medium",
        name_no: "Telekommunikasjon ellers",
        short_name_no: "Telekommunikasjon ellers",
        name_en: "Other telecommunications activities",
        short_name_en: "Other telecommunications act.",
    },
    {
        code: "62.01",
        score: "low",
        name_no: "Programmeringstjenester",
        short_name_no: "Programmeringstjenester",
        name_en: "Computer programming activities",
        short_name_en: "Computer programming act.",
    },
    {
        code: "62.02",
        score: "low",
        name_no: "Konsulentvirksomhet tilknyttet informasjonsteknologi",
        short_name_no: "Kons.virks. tilkn. informasj.tekn.",
        name_en: "Computer consultancy activities",
        short_name_en: "Computer consultancy act.",
    },
    {
        code: "62.03",
        score: "low",
        name_no: "Forvaltning og drift av IT-systemer",
        short_name_no: "Forvaltning og drift av IT-systemer",
        name_en: "Computer facilities management activities",
        short_name_en: "Computer fac. managem. act.",
    },
    {
        code: "62.09",
        score: "low",
        name_no: "Andre tjenester tilknyttet informasjonsteknologi",
        short_name_no: "Tjen. tilkn. informasjonsteknologi el.",
        name_en: "Other information technology and computer service activities",
        short_name_en: "Other inf. tech./computer serv. act.",
    },
    {
        code: "63.11",
        score: "low",
        name_no: "Databehandling, datalagring og tilknyttede tjenester",
        short_name_no: "Databeh./-lagring og tilkn. tjen.",
        name_en: "Data processing, hosting and related activities",
        short_name_en: "Data process., hosting and rel act.",
    },
    {
        code: "63.12",
        score: "low",
        name_no: "Drift av web-portaler",
        short_name_no: "Drift av web-portaler",
        name_en: "Web portals",
        short_name_en: "Web portals",
    },
    {
        code: "63.91",
        score: "low",
        name_no: "Nyhetsbyråer",
        short_name_no: "Nyhetsbyråer",
        name_en: "News agency activities",
        short_name_en: "News agency act.",
    },
    {
        code: "63.99",
        score: "low",
        name_no: "Andre informasjonstjenester ikke nevnt annet sted",
        short_name_no: "Andre informasjonstjenester",
        name_en: "Other information service activities n.e.c.",
        short_name_en: "Other inf. serv. act. n.e.c.",
    },
    {
        code: "64.11",
        score: "low",
        name_no: "Sentralbankvirksomhet",
        short_name_no: "Sentralbankvirksomhet",
        name_en: "Central banking",
        short_name_en: "Central banking",
    },
    {
        code: "64.19",
        score: "low",
        name_no: "Bankvirksomhet ellers",
        short_name_no: "Bankvirksomhet ellers",
        name_en: "Other monetary intermediation",
        short_name_en: "Other monetary intermed.",
    },
    {
        code: "64.20",
        score: "low",
        name_no: "Holdingselskaper",
        short_name_no: "Holdingselskaper",
        name_en: "Activities of holding companies",
        short_name_en: "Activities of holding companies",
    },
    {
        code: "64.30",
        score: "low",
        name_no: "Verdipapirfond, investeringsselskaper o.l.",
        short_name_no: "Verdipapirfond/invest.selskap. o.l.",
        name_en: "Trusts, funds and similar financial entities",
        short_name_en: "Trusts, funds, sim. financ. entities",
    },
    {
        code: "64.91",
        score: "low",
        name_no: "Finansiell leasing",
        short_name_no: "Finansiell leasing",
        name_en: "Financial leasing",
        short_name_en: "Financial leasing",
    },
    {
        code: "64.92",
        score: "low",
        name_no: "Annen kredittgivning",
        short_name_no: "Annen kredittgivning",
        name_en: "Other credit granting",
        short_name_en: "Other credit granting",
    },
    {
        code: "64.99",
        score: "low",
        name_no: "Annen finansieringsvirksomhet ikke nevnt annet sted",
        short_name_no: "Finansieringsvirksomhet el.",
        name_en: "Other financial service activities, except insurance and pension funding n.e.c.",
        short_name_en: "Other financial serv. act.",
    },
    {
        code: "65.11",
        score: "low",
        name_no: "Livsforsikring",
        short_name_no: "Livsforsikring",
        name_en: "Life insurance",
        short_name_en: "Life insurance",
    },
    {
        code: "65.12",
        score: "low",
        name_no: "Skadeforsikring",
        short_name_no: "Skadeforsikring",
        name_en: "Non-life insurance",
        short_name_en: "Non-life insurance",
    },
    {
        code: "65.20",
        score: "low",
        name_no: "Gjenforsikring",
        short_name_no: "Gjenforsikring",
        name_en: "Reinsurance",
        short_name_en: "Reinsurance",
    },
    {
        code: "65.30",
        score: "low",
        name_no: "Pensjonskasser",
        short_name_no: "Pensjonskasser",
        name_en: "Pension funding",
        short_name_en: "Pension funding",
    },
    {
        code: "66.11",
        score: "low",
        name_no: "Administrasjon av finansmarkeder",
        short_name_no: "Administrasjon av finansmarkeder",
        name_en: "Administration of financial markets",
        short_name_en: "Adm. of financial markets",
    },
    {
        code: "66.12",
        score: "low",
        name_no: "Verdipapirmegling",
        short_name_no: "Verdipapirmegling",
        name_en: "Security and commodity contracts brokerage",
        short_name_en: "Security, comm.contr. brokerage",
    },
    {
        code: "66.19",
        score: "low",
        name_no: "Andre tjenester tilknyttet finansieringsvirksomhet",
        short_name_no: "Tjen. tilkn. finansieringsvirks. el.",
        name_en: "Other activities auxiliary to financial services, except insurance and pension funding",
        short_name_en: "Other act. aux. to financ. serv.",
    },
    {
        code: "66.21",
        score: "low",
        name_no: "Risiko- og skadevurdering",
        short_name_no: "Risiko- og skadevurdering",
        name_en: "Risk and damage evaluation",
        short_name_en: "Risk and damage evaluation",
    },
    {
        code: "66.22",
        score: "low",
        name_no: "Forsikringsformidling",
        short_name_no: "Forsikringsformidling",
        name_en: "Activities of insurance agents and brokers",
        short_name_en: "Act. on insurance agents/brokers",
    },
    {
        code: "66.29",
        score: "low",
        name_no: "Andre tjenester tilknyttet forsikringsvirksomhet og pensjonskasser",
        short_name_no: "Tjen. fors.virks./pensj.kasser el.",
        name_en: "Other activities auxiliary to insurance and pension funding",
        short_name_en: "Oth. act. aux. to insur./pension fund.",
    },
    {
        code: "66.30",
        score: "low",
        name_no: "Fondsforvaltningsvirksomhet",
        short_name_no: "Fondsforvaltningsvirksomhet",
        name_en: "Fund management activities",
        short_name_en: "Fund management activities",
    },
    {
        code: "68.10",
        score: "low",
        name_no: "Kjøp og salg av egen fast eiendom",
        short_name_no: "Kjøp/salg av egen fast eiendom",
        name_en: "Buying and selling of own real estate",
        short_name_en: "Buying/selling of own real estate",
    },
    {
        code: "68.20",
        score: "medium",
        name_no: "Utleie av egen eller leid fast eiendom",
        short_name_no: "Utleie av egen/leid fast eiendom",
        name_en: "Renting and operating of own real estate",
        short_name_en: "Rent./operat. own real estate",
    },
    {
        code: "68.31",
        score: "low",
        name_no: "Eiendomsmegling",
        short_name_no: "Eiendomsmegling",
        name_en: "Real estate agencies",
        short_name_en: "Real estate agencies",
    },
    {
        code: "68.32",
        score: "low",
        name_no: "Eiendomsforvaltning",
        short_name_no: "Eiendomsforvaltning",
        name_en: "Management of real estate on a fee or contract basis",
        short_name_en: "Man. real estate fee/contract basis",
    },
    {
        code: "69.10",
        score: "low",
        name_no: "Juridisk tjenesteyting",
        short_name_no: "Juridisk tjenesteyting",
        name_en: "Legal activities",
        short_name_en: "Legal activities",
    },
    {
        code: "69.20",
        score: "low",
        name_no: "Regnskap, revisjon og skatterådgivning",
        short_name_no: "Regnskap/revisjon/skatterådgiv.",
        name_en: "Accounting, bookkeeping and auditing activities; tax consultancy",
        short_name_en: "Account./bookkeep./tax consult.",
    },
    {
        code: "70.10",
        score: "low",
        name_no: "Hovedkontortjenester",
        short_name_no: "Hovedkontortjenester",
        name_en: "Activities of head offices",
        short_name_en: "Activities of head offices",
    },
    {
        code: "70.21",
        score: "low",
        name_no: "PR og kommunikasjonstjenester",
        short_name_no: "PR og kommunikasjonstjenester",
        name_en: "Public relations and communication activities",
        short_name_en: "PR and communication act.",
    },
    {
        code: "70.22",
        score: "low",
        name_no: "Bedriftsrådgivning og annen administrativ rådgivning",
        short_name_no: "Bedriftsrådgiv./annen adm. rådgiv.",
        name_en: "Business and other management consultancy activities",
        short_name_en: "Business/oth. manage. consult act.",
    },
    {
        code: "71.11",
        score: "low",
        name_no: "Arkitektvirksomhet",
        short_name_no: "Arkitektvirksomhet",
        name_en: "Architectural activities",
        short_name_en: "Architectural act.",
    },
    {
        code: "71.12",
        score: "low",
        name_no: "Teknisk konsulentvirksomhet",
        short_name_no: "Teknisk konsulentvirksomhet",
        name_en: "Engineering activities and related technical consultancy",
        short_name_en: "Engineer. act./rel. tech. consult",
    },
    {
        code: "71.20",
        score: "low",
        name_no: "Teknisk prøving og analyse",
        short_name_no: "Teknisk prøving og analyse",
        name_en: "Technical testing and analysis",
        short_name_en: "Technical testing and analysis",
    },
    {
        code: "72.11",
        score: "low",
        name_no: "Forskning og utviklingsarbeid innen bioteknologi",
        short_name_no: "Forsk./utvikl.arb. innen bioteknologi",
        name_en: "Research and experimental development on biotechnology",
        short_name_en: "R&D on  biotechnology",
    },
    {
        code: "72.19",
        score: "low",
        name_no: "Annen forskning og annet utviklingsarbeid innen naturvitenskap og teknikk",
        short_name_no: "Annen forsk. innen naturvit. og teknikk",
        name_en: "Other research and experimental development on natural sciences and engineering",
        short_name_en: "Other R&D on natural sciences etc.",
    },
    {
        code: "72.20",
        score: "low",
        name_no: "Forskning og utviklingsarbeid innen samfunnsvitenskap og humanistiske fag",
        short_name_no: "Forskning samfunnsvit., human. fag",
        name_en: "Research and experimental development on social sciences and humanities",
        short_name_en: "R&D on. social scien./humanities",
    },
    {
        code: "73.11",
        score: "low",
        name_no: "Reklamebyråer",
        short_name_no: "Reklamebyråer",
        name_en: "Advertising agencies",
        short_name_en: "Advertising agencies",
    },
    {
        code: "73.12",
        score: "low",
        name_no: "Medieformidlingstjenester",
        short_name_no: "Medieformidlingstjenester",
        name_en: "Media representation",
        short_name_en: "Media representation",
    },
    {
        code: "73.20",
        score: "low",
        name_no: "Markeds- og opinionsundersøkelser",
        short_name_no: "Markeds- og opinionsundersøkelser",
        name_en: "Market research and public opinion polling",
        short_name_en: "Market research/publ opin. polling",
    },
    {
        code: "74.10",
        score: "low",
        name_no: "Spesialisert designvirksomhet",
        short_name_no: "Spesialisert designvirksomhet",
        name_en: "Specialised design activities",
        short_name_en: "Specialised design act.",
    },
    {
        code: "74.20",
        score: "low",
        name_no: "Fotografvirksomhet",
        short_name_no: "Fotografvirksomhet",
        name_en: "Photographic activities",
        short_name_en: "Photographic activities",
    },
    {
        code: "74.30",
        score: "low",
        name_no: "Oversettelses- og tolkevirksomhet",
        short_name_no: "Oversettelses- og tolkevirksomhet",
        name_en: "Translation and interpretation activities",
        short_name_en: "Translation/interpretation",
    },
    {
        code: "74.90",
        score: "low",
        name_no: "Annen faglig, vitenskapelig og teknisk virksomhet",
        short_name_no: "Annen faglig/vit.skap./tekn. virks.",
        name_en: "Other professional, scientific and technical activities n.e.c.",
        short_name_en: "Prof. scient/tech. act. n.e.c.",
    },
    {
        code: "75.00",
        score: "low",
        name_no: "Veterinærtjenester",
        short_name_no: "Veterinærtjenester",
        name_en: "Veterinary activities",
        short_name_en: "Veterinary activities",
    },
    {
        code: "77.11",
        score: "low",
        name_no: "Utleie og leasing av biler og andre lette motorvogner",
        short_name_no: "Utl./leas. biler/andre lette motorv.",
        name_en: "Renting and leasing of cars and light motor vehicles",
        short_name_en: "Rent./leas. cars/light motor veh.",
    },
    {
        code: "77.12",
        score: "medium",
        name_no: "Utleie og leasing av lastebiler",
        short_name_no: "Utleie og leasing av lastebiler",
        name_en: "Renting and leasing of trucks",
        short_name_en: "Rent./leas. trucks",
    },
    {
        code: "77.21",
        score: "low",
        name_no: "Utleie og leasing av sports- og fritidsutstyr",
        short_name_no: "Utl./leas. sports- og fritidsutstyr",
        name_en: "Renting and leasing of recreational and sports goods",
        short_name_en: "Rent./leas. recreat./sports goods",
    },
    {
        code: "77.22",
        score: "low",
        name_no: "Utleie av videofilm, DVD og lignende",
        short_name_no: "Utleie videofilm, DVD og lignende",
        name_en: "Renting of video tapes and disks",
        short_name_en: "Rent. video tapes/disks",
    },
    {
        code: "77.29",
        score: "low",
        name_no: "Utleie og leasing av andre husholdningsvarer og varer til personlig bruk",
        short_name_no: "Utl./leas. husholdningsvarer el.",
        name_en: "Renting and leasing of other personal and household goods",
        short_name_en: "Rent./leas. oth. pers. househ. goods",
    },
    {
        code: "77.31",
        score: "medium",
        name_no: "Utleie og leasing av landbruksmaskiner og -utstyr",
        short_name_no: "Utl./leas. landbruksmaskiner og -utstyr",
        name_en: "Renting and leasing of agricultural machinery and equipment",
        short_name_en: "Rent/leas agric. mach./equip",
    },
    {
        code: "77.32",
        score: "medium",
        name_no: "Utleie og leasing av bygge- og anleggsmaskiner og -utstyr",
        short_name_no: "Utl./leas. bygge- og anleggsmask.",
        name_en: "Renting and leasing of construction and civil engineering machinery and equipment",
        short_name_en: "Rent/leas constr/civ.eng. mach.",
    },
    {
        code: "77.33",
        score: "low",
        name_no: "Utleie og leasing av kontor- og datamaskiner",
        short_name_no: "Utl./leas. kontor- og datamaskiner",
        name_en: "Renting and leasing of office machinery and equipment (including computers)",
        short_name_en: "Rent/leas office mach./equip",
    },
    {
        code: "77.34",
        score: "medium",
        name_no: "Utleie og leasing av sjøtransportmateriell",
        short_name_no: "Utl./leas. sjøtransportmateriell",
        name_en: "Renting and leasing of water transport equipment",
        short_name_en: "Rent/leas water transp. equip",
    },
    {
        code: "77.35",
        score: "medium",
        name_no: "Utleie og leasing av lufttransportmateriell",
        short_name_no: "Utl./leas. lufttransportmateriell",
        name_en: "Renting and leasing of air transport equipment",
        short_name_en: "Rent/leas air transp. equip.",
    },
    {
        code: "77.39",
        score: "medium",
        name_no: "Utleie og leasing av andre maskiner og annet utstyr og materiell ikke nevnt annet sted",
        short_name_no: "Utl./leas. andre mask./annet utstyr",
        name_en: "Renting and leasing of other machinery, equipment and tangible goods n.e.c.",
        short_name_en: "Rent/leas other mach/equip etc.",
    },
    {
        code: "77.40",
        score: "low",
        name_no: "Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker",
        short_name_no: "Leas. av immateriell eiendom mv.",
        name_en: "Leasing of intellectual property and similar products, except copyrighted works",
        short_name_en: "Leas of intellect. property etc.",
    },
    {
        code: "78.10",
        score: "medium",
        name_no: "Rekruttering og formidling av arbeidskraft",
        short_name_no: "Rekrutt./formidling av arbeidskraft",
        name_en: "Activities of employment placement agencies",
        short_name_en: "Act. employment placement agencies",
    },
    {
        code: "78.20",
        score: "medium",
        name_no: "Utleie av arbeidskraft",
        short_name_no: "Utleie av arbeidskraft",
        name_en: "Temporary employment agency activities",
        short_name_en: "Temporary employment agency act.",
    },
    {
        code: "78.30",
        score: "medium",
        name_no: "Andre personaladministrative tjenester",
        short_name_no: "Andre personaladm. tjen.",
        name_en: "Other human resources provision",
        short_name_en: "Other human resources provision",
    },
    {
        code: "79.11",
        score: "medium",
        name_no: "Reisebyråvirksomhet",
        short_name_no: "Reisebyråvirksomhet",
        name_en: "Travel agency activities",
        short_name_en: "Travel agency act.",
    },
    {
        code: "79.12",
        score: "medium",
        name_no: "Reisearrangørvirksomhet",
        short_name_no: "Reisearrangørvirksomhet",
        name_en: "Tour operator activities",
        short_name_en: "Tour operator act.",
    },
    {
        code: "79.90",
        score: "low",
        name_no: "Annen arrangørvirksomhet og tilknyttede tjenester",
        short_name_no: "Annen arrangørvirks. og tilkn. tjen.",
        name_en: "Other reservation service and related activities",
        short_name_en: "Other reserv. serv. /rel. act.",
    },
    {
        code: "80.10",
        score: "high",
        name_no: "Private vakttjenester",
        short_name_no: "Private vakttjenester",
        name_en: "Private security activities",
        short_name_en: "Private security activities",
    },
    {
        code: "80.20",
        score: "low",
        name_no: "Tjenester tilknyttet vakttjenester",
        short_name_no: "Tjenester tilknyttet vakttjenester",
        name_en: "Security systems service activities",
        short_name_en: "Security systems service act.",
    },
    {
        code: "80.30",
        score: "medium",
        name_no: "Etterforsking",
        short_name_no: "Etterforsking",
        name_en: "Investigation activities",
        short_name_en: "Investigation activities",
    },
    {
        code: "81.10",
        score: "medium",
        name_no: "Kombinerte tjenester tilknyttet eiendomsdrift",
        short_name_no: "Komb. tjen. tilkn. eiendomsdrift",
        name_en: "Combined facilities support activities",
        short_name_en: "Combined facilities support act.",
    },
    {
        code: "81.21",
        score: "medium",
        name_no: "Rengjøring av bygninger",
        short_name_no: "Rengjøring av bygninger",
        name_en: "General cleaning of buildings",
        short_name_en: "General cleaning of buildings",
    },
    {
        code: "81.22",
        score: "medium",
        name_no: "Utvendig rengjøring av bygninger og industriell rengjøring",
        short_name_no: "Utv. rengj. bygn./ind. rengjøring",
        name_en: "Other building and industrial cleaning activities",
        short_name_en: "Other build./ind. clean. act.",
    },
    {
        code: "81.29",
        score: "medium",
        name_no: "Annen rengjøringsvirksomhet",
        short_name_no: "Annen rengjøringsvirksomhet",
        name_en: "Other cleaning activities",
        short_name_en: "Other cleaning act.",
    },
    {
        code: "81.30",
        score: "medium",
        name_no: "Beplantning av hager og parkanlegg",
        short_name_no: "Beplantning av hager og parkanl.",
        name_en: "Landscape service activities",
        short_name_en: "Landscape service activities",
    },
    {
        code: "82.11",
        score: "low",
        name_no: "Kombinerte kontortjenester",
        short_name_no: "Kombinerte kontortjenester",
        name_en: "Combined office administrative activities",
        short_name_en: "Combined office adm. act.",
    },
    {
        code: "82.19",
        score: "low",
        name_no: "Fotokopiering, forberedelse av dokumenter og andre spesialiserte kontortjenester",
        short_name_no: "Fotokopiering/spes. kontortjen. el.",
        name_en: "Photocopying, document preparation and other specialised office support activities",
        short_name_en: "Photocopying, document prep. etc.",
    },
    {
        code: "82.20",
        score: "low",
        name_no: "Telefonvakttjenester og telefonsalg",
        short_name_no: "Telefonvakttjenester/telefonsalg",
        name_en: "Activities of call centres",
        short_name_en: "Activities of call centres",
    },
    {
        code: "82.30",
        score: "low",
        name_no: "Kongress-, messe- og utstillingsvirksomhet",
        short_name_no: "Kongress-/messe-/utstillingsvirks.",
        name_en: "Organisation of conventions and trade shows",
        short_name_en: "Org. of conventions and trade shows",
    },
    {
        code: "82.91",
        score: "high",
        name_no: "Inkasso- og kredittopplysningsvirksomhet",
        short_name_no: "Inkasso- og kredittopplysningsvirks.",
        name_en: "Activities of collection agencies and credit bureaus",
        short_name_en: "Act. collect. agen./credit bureaus",
    },
    {
        code: "82.92",
        score: "medium",
        name_no: "Pakkevirksomhet",
        short_name_no: "Pakkevirksomhet",
        name_en: "Packaging activities",
        short_name_en: "Packaging act.",
    },
    {
        code: "82.99",
        score: "low",
        name_no: "Annen forretningsmessig tjenesteyting ikke nevnt annet sted",
        short_name_no: "Forr.messig tj.yting ikke nevnt el.",
        name_en: "Other business service activities n.e.c.",
        short_name_en: "Other business serv. act. n.e.c.",
    },
    {
        code: "84.11",
        score: "low",
        name_no: "Generell offentlig administrasjon",
        short_name_no: "Generell offentlig administrasjon",
        name_en: "General public administration activities",
        short_name_en: "Gen. public adm. act.",
    },
    {
        code: "84.12",
        score: "low",
        name_no:
            "Offentlig administrasjon tilknyttet helsestell, sosial virksomhet, undervisning, kirke, kultur og miljøvern",
        short_name_no: "Off.adm. helse, sos.virks. m.m.",
        name_en:
            "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security",
        short_name_en: "Act. provid. health care, educ. etc.",
    },
    {
        code: "84.13",
        score: "low",
        name_no: "Offentlig administrasjon tilknyttet næringsvirksomhet og arbeidsmarked",
        short_name_no: "Off.adm. nær.virks. og arb.marked",
        name_en: "Regulation of and contribution to more efficient operation of businesses",
        short_name_en: "Reg. of operation of business",
    },
    {
        code: "84.21",
        score: "low",
        name_no: "Utenrikssaker",
        short_name_no: "Utenrikssaker",
        name_en: "Foreign affairs",
        short_name_en: "Foreign affairs",
    },
    {
        code: "84.22",
        score: "low",
        name_no: "Forsvar",
        short_name_no: "Forsvar",
        name_en: "Defence activities",
        short_name_en: "Defence activities",
    },
    {
        code: "84.23",
        score: "low",
        name_no: "Retts- og fengselsvesen",
        short_name_no: "Retts- og fengselsvesen",
        name_en: "Justice and judicial activities",
        short_name_en: "Justice and judicial activities",
    },
    {
        code: "84.24",
        score: "low",
        name_no: "Politi- og påtalemyndighet",
        short_name_no: "Politi- og påtalemyndighet",
        name_en: "Public order and safety activities",
        short_name_en: "Public order and safety act.",
    },
    {
        code: "84.25",
        score: "low",
        name_no: "Brannvern",
        short_name_no: "Brannvern",
        name_en: "Fire service activities",
        short_name_en: "Fire service activities",
    },
    {
        code: "84.30",
        score: "low",
        name_no: "Trygdeordninger underlagt offentlig forvaltning",
        short_name_no: "Sosialforsikring, trygd",
        name_en: "Compulsory social security activities",
        short_name_en: "Comp. social security activities",
    },
    {
        code: "85.10",
        score: "low",
        name_no: "Førskoleundervisning",
        short_name_no: "Førskoleundervisning",
        name_en: "Pre-primary education",
        short_name_en: "Pre-primary education",
    },
    {
        code: "85.20",
        score: "low",
        name_no: "Grunnskoleundervisning",
        short_name_no: "Grunnskoleundervisning",
        name_en: "Primary education",
        short_name_en: "Primary education",
    },
    {
        code: "85.31",
        score: "low",
        name_no: "Videregående opplæring innen allmennfaglige studieretninger",
        short_name_no: "Undervisning i allmennfag",
        name_en: "General secondary education",
        short_name_en: "General secondary education",
    },
    {
        code: "85.32",
        score: "low",
        name_no: "Videregående opplæring innen tekniske og andre yrkesfaglige studieretninger",
        short_name_no: "Undervisn. i tekn./yrkesrettede fag",
        name_en: "Technical and vocational secondary education",
        short_name_en: "Tech., vocation. sec. education",
    },
    {
        code: "85.41",
        score: "low",
        name_no: "Undervisning ved fagskoler",
        short_name_no: "Undervisning ved fagskoler",
        name_en: "Post-secondary non-tertiary education",
        short_name_en: "Post-second. non-tert. education",
    },
    {
        code: "85.42",
        score: "low",
        name_no: "Undervisning på universitets- og høgskolenivå",
        short_name_no: "Undervisning universitet, høgskoler",
        name_en: "Tertiary education",
        short_name_en: "Tertiary education",
    },
    {
        code: "85.51",
        score: "low",
        name_no: "Undervisning innen idrett og rekreasjon",
        short_name_no: "Underv. innen idrett og rekreasjon",
        name_en: "Sports and recreation education",
        short_name_en: "Sports and recreation educ.",
    },
    {
        code: "85.52",
        score: "low",
        name_no: "Undervisning innen kultur",
        short_name_no: "Undervisning innen kultur",
        name_en: "Cultural education",
        short_name_en: "Cultural education",
    },
    {
        code: "85.53",
        score: "low",
        name_no: "Trafikkskoleundervisning",
        short_name_no: "Trafikkskoleundervisning",
        name_en: "Driving school activities",
        short_name_en: "Driving school activities",
    },
    {
        code: "85.59",
        score: "low",
        name_no: "Annen undervisning ikke nevnt annet sted",
        short_name_no: "Undervisning el.",
        name_en: "Other education n.e.c.",
        short_name_en: "Other education n.e.c.",
    },
    {
        code: "85.60",
        score: "low",
        name_no: "Tjenester tilknyttet undervisning",
        short_name_no: "Tjenester tilkn. undervisning",
        name_en: "Educational support activities",
        short_name_en: "Educational support act.",
    },
    {
        code: "86.10",
        score: "medium",
        name_no: "Sykehustjenester",
        short_name_no: "Sykehustjenester",
        name_en: "Hospital activities",
        short_name_en: "Hospital activities",
    },
    {
        code: "86.21",
        score: "medium",
        name_no: "Legetjeneste",
        short_name_no: "Legetjeneste",
        name_en: "General medical practice activities",
        short_name_en: "General medical practice act.",
    },
    {
        code: "86.22",
        score: "medium",
        name_no: "Spesialisert legetjeneste",
        short_name_no: "Spesialisert legetjeneste",
        name_en: "Specialist medical practice activities",
        short_name_en: "Spec. medical practice act.",
    },
    {
        code: "86.23",
        score: "medium",
        name_no: "Tannhelsetjenester",
        short_name_no: "Tannhelsetjenester",
        name_en: "Dental practice activities",
        short_name_en: "Dental practice activities",
    },
    {
        code: "86.90",
        score: "medium",
        name_no: "Andre helsetjenester",
        short_name_no: "Andre helsetjenester",
        name_en: "Other human health activities",
        short_name_en: "Other human health act.",
    },
    {
        code: "87.10",
        score: "medium",
        name_no: "Pleie- og omsorgstjenester i institusjon",
        short_name_no: "Pleie- og omsorg i institusjon",
        name_en: "Residential nursing care activities",
        short_name_en: "Residential nursing care act.",
    },
    {
        code: "87.20",
        score: "medium",
        name_no:
            "Institusjoner og bofellesskap innen omsorg for psykisk utviklingshemmede, psykisk helsearbeid og rusmiddelomsorg",
        short_name_no: "Inst./bof.skap psyk. utv.hem. mv.",
        name_en: "Residential care activities for mental retardation, mental health and substance abuse",
        short_name_en: "Res. care act. mental retard. etc.",
    },
    {
        code: "87.30",
        score: "medium",
        name_no: "Institusjoner og bofellesskap innen omsorg for eldre og funksjonshemmede",
        short_name_no: "Inst./bofellesskap eldre/funksj.hem.",
        name_en: "Residential care activities for the elderly and disabled",
        short_name_en: "Res. care act. elderly/disabled",
    },
    {
        code: "87.90",
        score: "medium",
        name_no: "Andre omsorgsinstitusjoner",
        short_name_no: "Andre omsorgsinstitusjoner",
        name_en: "Other residential care activities",
        short_name_en: "Other residential care act.",
    },
    {
        code: "88.10",
        score: "medium",
        name_no: "Sosialtjenester uten botilbud for eldre og funksjonshemmede",
        short_name_no: "Sosialtj. u/botilbud eldre/funksj.hem.",
        name_en: "Social work activities without accommodation for the elderly and disabled",
        short_name_en: "Soc. work elder/dis. without acc.",
    },
    {
        code: "88.91",
        score: "medium",
        name_no: "Sosialtjenester uten botilbud for barn og ungdom",
        short_name_no: "Sosialtj. u/botilbud barn/ungdom",
        name_en: "Child day-care activities",
        short_name_en: "Child day-care activities",
    },
    {
        code: "88.99",
        score: "medium",
        name_no: "Andre sosialtjenester uten botilbud ikke nevnt annet sted",
        short_name_no: "Sosialtjenester u/botilbud el.",
        name_en: "Other social work activities without accommodation n.e.c.",
        short_name_en: "Soc. work act. without accomm.",
    },
    {
        code: "90.01",
        score: "low",
        name_no: "Utøvende kunstnere og underholdningsvirksomhet",
        short_name_no: "Utøv. kunstnere og underhold.virks.",
        name_en: "Performing arts",
        short_name_en: "Performing arts",
    },
    {
        code: "90.02",
        score: "low",
        name_no: "Tjenester tilknyttet underholdningsvirksomhet",
        short_name_no: "Tjen. tilkn. underholdningsvirks.",
        name_en: "Support activities to performing arts",
        short_name_en: "Support act. to performing arts",
    },
    {
        code: "90.03",
        score: "low",
        name_no: "Selvstendig kunstnerisk virksomhet",
        short_name_no: "Selvstendig kunstnerisk virks.",
        name_en: "Artistic creation",
        short_name_en: "Artistic creation",
    },
    {
        code: "90.04",
        score: "low",
        name_no: "Drift av lokaler tilknyttet kunstnerisk virksomhet",
        short_name_no: "Drift lokaler tilkn. kunst.virks.",
        name_en: "Operation of arts facilities",
        short_name_en: "Operation of arts facilities",
    },
    {
        code: "91.01",
        score: "low",
        name_no: "Drift av biblioteker og arkiver",
        short_name_no: "Drift av biblioteker og arkiver",
        name_en: "Libraries and archives activities",
        short_name_en: "Libraries and archives act.",
    },
    {
        code: "91.02",
        score: "low",
        name_no: "Drift av museer",
        short_name_no: "Drift av museer",
        name_en: "Museums activities",
        short_name_en: "Museums activities",
    },
    {
        code: "91.03",
        score: "low",
        name_no: "Drift av historiske steder og bygninger og lignende severdigheter",
        short_name_no: "Drift av hist. steder og bygn. mv.",
        name_en: "Operation of historical sites and buildings and similar visitor attractions",
        short_name_en: "Oper. hist. sites/build. etc.",
    },
    {
        code: "91.04",
        score: "low",
        name_no: "Drift av botaniske og zoologiske hager og naturreservater",
        short_name_no: "Drift av bot./zool. hager/naturres.",
        name_en: "Botanical and zoological gardens and nature reserves activities",
        short_name_en: "Boot. and zool. gardens etc.",
    },
    {
        code: "92.00",
        score: "medium",
        name_no: "Lotteri og totalisatorspill",
        short_name_no: "Lotteri og totalisatorspill",
        name_en: "Gambling and betting activities",
        short_name_en: "Gambling and betting activities",
    },
    {
        code: "93.11",
        score: "low",
        name_no: "Drift av idrettsanlegg",
        short_name_no: "Drift av idrettsanlegg",
        name_en: "Operation of sports facilities",
        short_name_en: "Operation of sports facilities",
    },
    {
        code: "93.12",
        score: "low",
        name_no: "Idrettslag og -klubber",
        short_name_no: "Idrettslag og -klubber",
        name_en: "Activities of sport clubs",
        short_name_en: "Activities of sport clubs",
    },
    {
        code: "93.13",
        score: "low",
        name_no: "Treningssentre",
        short_name_no: "Treningssentre",
        name_en: "Fitness facilities",
        short_name_en: "Fitness facilities",
    },
    {
        code: "93.19",
        score: "low",
        name_no: "Andre sportsaktiviteter",
        short_name_no: "Andre sportsaktiviteter",
        name_en: "Other sports activities",
        short_name_en: "Other sports activities",
    },
    {
        code: "93.21",
        score: "low",
        name_no: "Drift av fornøyelses- og temaparker",
        short_name_no: "Drift av fornøyelses- og temaparker",
        name_en: "Activities of amusement parks and theme parks",
        short_name_en: "Act. of amusement parks/theme parks",
    },
    {
        code: "93.29",
        score: "low",
        name_no: "Andre fritidsaktiviteter",
        short_name_no: "Andre fritidsaktiviteter",
        name_en: "Other amusement and recreation activities",
        short_name_en: "Other amusement/recreation act.",
    },
    {
        code: "94.11",
        score: "low",
        name_no: "Næringslivs- og arbeidsgiverorganisasjoner",
        short_name_no: "Næringslivs-/arbeidsgiverorg. mv.",
        name_en: "Activities of business and employers membership organisations",
        short_name_en: "Business, employers members. org.",
    },
    {
        code: "94.12",
        score: "low",
        name_no: "Yrkessammenslutninger",
        short_name_no: "Yrkessammenslutninger",
        name_en: "Activities of professional membership organisations",
        short_name_en: "Professional membership org.",
    },
    {
        code: "94.20",
        score: "low",
        name_no: "Arbeidstakerorganisasjoner",
        short_name_no: "Arbeidstakerorganisasjoner",
        name_en: "Activities of trade unions",
        short_name_en: "Activities of trade unions",
    },
    {
        code: "94.91",
        score: "low",
        name_no: "Religiøse organisasjoner",
        short_name_no: "Religiøse organisasjoner",
        name_en: "Activities of religious organisations",
        short_name_en: "Act. of religious organisations",
    },
    {
        code: "94.92",
        score: "low",
        name_no: "Partipolitiske organisasjoner",
        short_name_no: "Partipolitiske organisasjoner",
        name_en: "Activities of political organisations",
        short_name_en: "Act. of political organisations",
    },
    {
        code: "94.99",
        score: "low",
        name_no: "Aktiviteter i andre medlemsorganisasjoner ikke nevnt annet sted",
        short_name_no: "Aktiviteter i andre medlemsorg. el.",
        name_en: "Activities of other membership organisations n.e.c.",
        short_name_en: "Act. of other membership org. n.e.c.",
    },
    {
        code: "95.11",
        score: "low",
        name_no: "Reparasjon av datamaskiner og tilleggsutstyr",
        short_name_no: "Rep. av datamask. og tilleggsutstyr",
        name_en: "Repair of computers and peripheral equipment",
        short_name_en: "Rep. of computer/periph. equip.",
    },
    {
        code: "95.12",
        score: "low",
        name_no: "Reparasjon av kommunikasjonsutstyr",
        short_name_no: "Rep. av kommunikasjonsutstyr",
        name_en: "Repair of communication equipment",
        short_name_en: "Rep. of communication equip.",
    },
    {
        code: "95.21",
        score: "low",
        name_no: "Reparasjon av elektronikk til husholdningsbruk",
        short_name_no: "Rep. av elektronikk til hush.bruk",
        name_en: "Repair of consumer electronics",
        short_name_en: "Rep. of consumer electronics",
    },
    {
        code: "95.22",
        score: "low",
        name_no: "Reparasjon av husholdningsvarer og hageredskaper",
        short_name_no: "Rep. av hush.varer og hageredskaper",
        name_en: "Repair of household appliances and home and garden equipment",
        short_name_en: "Rep. househ. appl./home garden equip",
    },
    {
        code: "95.23",
        score: "low",
        name_no: "Reparasjon av skotøy og lærvarer",
        short_name_no: "Rep. av skotøy og lærvarer",
        name_en: "Repair of footwear and leather goods",
        short_name_en: "Rep. of footwear and leather goods",
    },
    {
        code: "95.24",
        score: "low",
        name_no: "Reparasjon av møbler og boliginnredning",
        short_name_no: "Rep. av møbler og boliginnredning",
        name_en: "Repair of furniture and home furnishings",
        short_name_en: "Rep. of furniture and home furnishing",
    },
    {
        code: "95.25",
        score: "low",
        name_no: "Reparasjon av ur, gull- og sølvvarer",
        short_name_no: "Rep. av ur, gull- og sølvvarer",
        name_en: "Repair of watches, clocks and jewellery",
        short_name_en: "Rep. of watches, clocks, jewellery",
    },
    {
        code: "95.29",
        score: "low",
        name_no: "Reparasjon av andre husholdningsvarer og varer til personlig bruk",
        short_name_no: "Rep. av husholdningsvarer el.",
        name_en: "Repair of other personal and household goods",
        short_name_en: "Rep. of other pers./househ. goods",
    },
    {
        code: "96.01",
        score: "medium",
        name_no: "Vaskeri- og renserivirksomhet",
        short_name_no: "Vaskeri- og renserivirksomhet",
        name_en: "Washing and (dry-)cleaning of textile and fur products",
        short_name_en: "Wash/(dry)-clean of text./fur prod.",
    },
    {
        code: "96.02",
        score: "low",
        name_no: "Frisering og annen skjønnhetspleie",
        short_name_no: "Frisering og skjønnhetspleie el.",
        name_en: "Hairdressing and other beauty treatment",
        short_name_en: "Hairdressing/other beauty treat.",
    },
    {
        code: "96.03",
        score: "low",
        name_no: "Begravelsesbyråvirksomhet og drift av kirkegårder og krematorier",
        short_name_no: "Begrav.byrå, kirkegårdsdrift mv.",
        name_en: "Funeral and related activities",
        short_name_en: "Funeral and related act.",
    },
    {
        code: "96.04",
        score: "low",
        name_no: "Virksomhet knyttet til kroppspleie og fysisk velvære",
        short_name_no: "Helsestudiovirksomhet mv..",
        name_en: "Physical well-being activities",
        short_name_en: "Physical well-being act.",
    },
    {
        code: "96.09",
        score: "low",
        name_no: "Personlig tjenesteyting ikke nevnt annet sted",
        short_name_no: "Personlig tjenesteyting el.",
        name_en: "Other personal service activities n.e.c.",
        short_name_en: "Other pers. service act. n.e.c.",
    },
    {
        code: "97.00",
        score: "medium",
        name_no: "Lønnet arbeid i private husholdninger",
        short_name_no: "Lønnet arbeid i private husholdn.",
        name_en: "Activities of households as employers of domestic personnel",
        short_name_en: "Households as employers act.",
    },
    {
        code: "99.00",
        score: "low",
        name_no: "Internasjonale organisasjoner og organer",
        short_name_no: "Internasjonale organer",
        name_en: "Activities of extraterritorial organisations and bodies",
        short_name_en: "Extra-terr. organisations and bodies",
    },
];
