import { gql, useMutation } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
    AppRoutes_GetSupplierDocument,
    CopyFileFromAssessmentsInput,
    DocumentsList_AssessmentsFileFragment,
} from "@/gql/graphql";
import { DocumentTypeOption, documentTypes, guessContentType } from "@/lib/files";
import { track } from "@/lib/track";
import { useAlert } from "@/providers";
import { usePolling } from "@/providers/PollingContext";

interface AssessmentDocumentEditModalProps {
    open: boolean;
    setClose: () => void;
    file: DocumentsList_AssessmentsFileFragment;
    supplierId: string;
}

const AssessmentDocumentEditModal_UpdateFileMutation = gql`
    mutation AssessmentDocumentEditModal_UpdateFileMutation($input: CopyFileFromAssessmentsInput!) {
        copyFileFromAssessments(input: $input) {
            success
        }
    }
`;

const documentActions_AssessmentsDownloadUrlMutation = gql(`
    mutation DocumentsActions_CreateAssessmentsDownloadURL($input: CreateAssessmentsDownloadURLInput!) {
        createAssessmentsDownloadURL(input: $input) {
            url
        }
    }
`);

export const AssessmentDocumentEditModal = ({ open, setClose, file, supplierId }: AssessmentDocumentEditModalProps) => {
    const metaJson = file.metaJson ? JSON.parse(file.metaJson) : {};
    const currentDocType = metaJson.doctype;
    const currentExpiryDate = metaJson.expirydate && metaJson.expirydate !== "" ? metaJson.expirydate : undefined;

    const [expiryDate, setExpiryDate] = useState<Date | null>(
        currentExpiryDate ? dayjs(currentExpiryDate).toDate() : null
    );
    const [documentType, setDocumentType] = useState<DocumentTypeOption | null>(
        currentDocType ? documentTypes.find((type) => type.tag === currentDocType) ?? null : null
    );
    const [downloadAssessmentsFile] = useMutation(documentActions_AssessmentsDownloadUrlMutation);

    async function getFileUrl(selectedFile: DocumentsList_AssessmentsFileFragment) {
        const assessmentsUrlData = await downloadAssessmentsFile({
            variables: {
                input: {
                    referenceId: selectedFile.referenceId,
                    fileName: selectedFile.name,
                },
            },
        });
        return assessmentsUrlData.data?.createAssessmentsDownloadURL?.url ?? "";
    }

    const handleViewFile = async (selectedFile: DocumentsList_AssessmentsFileFragment) => {
        const url = await getFileUrl(selectedFile);
        const contentType = guessContentType(selectedFile.name);
        if (!url) {
            return Promise.resolve();
        }
        fetch(url, {
            method: "GET",
        })
            .then((response) => response.blob())
            .then((blob) => {
                if (contentType === "application/octet-stream") {
                    const blobUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.setAttribute("download", decodeURIComponent(file.name));
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                } else {
                    // For PDFs and other content types, attempt to force inline viewing
                    const blobUrl = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
                    const newWindow = window.open("", "_blank");
                    if (newWindow) {
                        newWindow.location.href = blobUrl;
                    }
                }
                track("Supplier Profile: Inbox - Viewed Document", { supplierId });
            })
            .catch((err) => {
                throw err;
            });
    };

    useEffect(() => {
        if (open) {
            setExpiryDate(currentExpiryDate ? dayjs(currentExpiryDate).toDate() : null);
            setDocumentType(currentDocType ? documentTypes.find((type) => type.tag === currentDocType) ?? null : null);
        }
    }, [open, currentDocType, currentExpiryDate]);

    const [updateFileMetadata, { loading }] = useMutation(AssessmentDocumentEditModal_UpdateFileMutation);

    const isDocTypeChanged = documentType?.tag !== currentDocType;
    const isExpiryDateChanged = expiryDate?.toISOString() !== currentExpiryDate;

    const isChanged = isDocTypeChanged || isExpiryDateChanged;

    const { alertUser } = useAlert();
    const { handleStartPolling } = usePolling();

    const handleSaveChanges = () => {
        const input: CopyFileFromAssessmentsInput = {
            fileName: file.name,
            supplierID: supplierId,
            referenceID: file.referenceId,
            documentType: documentType?.tag ?? "", // rework this as it can't be null
        };

        if (isExpiryDateChanged) {
            input.expiryDate = expiryDate?.toISOString(); // not ever "removing" an expiration here
        }

        updateFileMetadata({
            variables: { input },
            refetchQueries: [AppRoutes_GetSupplierDocument],
        })
            .then(() => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "Document reviewed",
                        description: "Snackbar success message for reviewing supplier document",
                    }),
                    severity: "success",
                });
                track("Supplier Profile: Inbox - Reviewed AssessmentDocument", {
                    supplierId,
                    documentType: documentType?.tag,
                    expiryDate: expiryDate?.toISOString(),
                });
                setClose();
                handleStartPolling(3000, 21000);
            })
            .catch(() => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "Error reviewing document",
                        description: "Snackbar error message for reviewing supplier document",
                    }),
                    severity: "error",
                });
            });
    };

    const { formatMessage } = useIntl();
    return (
        <Dialog open={open} onClose={setClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        <FormattedMessage defaultMessage="Review document" />
                    </Typography>
                    <Button color="ghostGray" onClick={setClose} sx={{ minWidth: "auto", p: 1 }}>
                        <X />
                    </Button>
                </Stack>
                <Typography variant="textSm" color="textTextHelper" fontWeight={400}>
                    <FormattedMessage defaultMessage="Edit document type and expiry date" />
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Stack spacing={3} sx={{ mt: 2 }}>
                    <Divider />
                    <Stack spacing={5}>
                        <Stack spacing={1}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="textMd" color="textTextPrimary" fontWeight={500}>
                                    {file.name}
                                </Typography>
                                <Button color="linkPrimary" onClick={() => handleViewFile(file)}>
                                    <FormattedMessage defaultMessage="View document" />
                                </Button>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                                <Autocomplete
                                    options={documentTypes}
                                    value={documentType ?? undefined}
                                    size="small"
                                    onChange={(_, newValue) => setDocumentType(newValue)}
                                    getOptionLabel={(option) => option.name}
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={<FormattedMessage defaultMessage="Document type" />}
                                            placeholder={formatMessage({
                                                defaultMessage: "Select a document type",
                                            })}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.tag}>
                                            <Stack>
                                                <Typography>{option.name.split("-")[0]}</Typography>
                                                {option.name.includes("-") && (
                                                    <Typography variant="textXs" color="textTextHelper">
                                                        {option.name.split("-")[1]}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Box>
                                    )}
                                    sx={{ width: "70%" }}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={<FormattedMessage defaultMessage="Expiry date" />}
                                        value={expiryDate ? dayjs(expiryDate) : null}
                                        onChange={(newValue) => {
                                            if (!newValue || newValue.isValid()) {
                                                setExpiryDate(newValue?.toDate() ?? null);
                                            }
                                        }}
                                        format="DD/MM/YYYY"
                                        slotProps={{
                                            textField: {
                                                sx: { width: "40%" },
                                                InputProps: {
                                                    sx: {
                                                        "& .MuiInputAdornment-root": {
                                                            order: -1,
                                                            marginLeft: 0,
                                                        },
                                                    },
                                                },
                                            },
                                            actionBar: {
                                                actions: ["clear"],
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={setClose} color="secondary">
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button
                    variant="contained"
                    disabled={loading || !isChanged || !documentType}
                    onClick={handleSaveChanges}
                >
                    <FormattedMessage defaultMessage="Confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
