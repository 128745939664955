import { DocumentListFiles } from "@/components/Pages/Overview/MainColumn/Documents/DocumentsList";

export const MAX_FILE_SIZE = 20 * 1024 * 1024;
export const ACCEPTED_FILE_EXTENSIONS =
    ".pdf,.csv,.eml,.txt,.rtf,.xls,.xlsx,.json,.ppt,.pptx,.xls,.xlsx,.doc,.docx,.xml,.tif,.tiff,.jpeg,.jpg,.png,.xlsm";

const EXTENSION_TO_CONTENT_TYPE_MAP: { [key: string]: string } = {
    ".pdf": "application/pdf",
    ".csv": "text/csv",
    ".eml": "message/rfc822",
    ".txt": "text/plain",
    ".rtf": "application/rtf",
    ".xls": "application/vnd.ms-excel",
    ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".json": "application/json",
    ".ppt": "application/vnd.ms-powerpoint",
    ".pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ".doc": "application/msword",
    ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".xml": "application/xml",
    ".tif": "image/tiff",
    ".tiff": "image/tiff",
    ".jpeg": "image/jpeg",
    ".jpg": "image/jpeg",
    ".png": "image/png",
    ".xlsm": "application/vnd.ms-excel.sheet.macroEnabled.12",
};

export function getContentType(extension: string): string {
    return EXTENSION_TO_CONTENT_TYPE_MAP[extension.toLowerCase()];
}

export function getFileExtension(fileName: string): string | undefined {
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex === -1 || lastDotIndex === fileName.length - 1) {
        // No dot found or dot is at the end with no extension
        return undefined;
    }
    return fileName.substring(lastDotIndex).toLowerCase();
}

/*  
    After generating random names for files stored in Cloud Storage we now save the name 
    as a metadata field and extract it again here. Falls back to the bucket filename
    if this filename field is not found. Mimetypes before this were hardcoded as 
    octet-stream hence the fallback to that, too. 
*/
export const getNameAndContentType = (file: DocumentListFiles): { originalFileName: string; contentType: string } => {
    if (!file.metaJson || JSON.parse(file.metaJson).originalfilename === undefined) {
        return { originalFileName: encodeURIComponent(file.name), contentType: "application/octet-stream" };
    }

    const { originalfilename } = JSON.parse(file.metaJson);
    const fileExt = getFileExtension(originalfilename) ?? "";

    return {
        originalFileName: originalfilename,
        contentType: getContentType(fileExt) || "application/octet-stream",
    };
};

export const middleEllipsis = (text: string, maxLength: number = 35): string => {
    if (text.length > maxLength) {
        return text.slice(0, 20) + "..." + text.slice(-10);
    }
    return text;
};

export type DocumentTypeOption = { name: string; tag: string };

export const documentTypes: DocumentTypeOption[] = [
    { name: "Code of Conduct", tag: "code-of-conduct" },
    {
        name: "ISO 9001 - Quality Management Systems",
        tag: "certification-iso-9001-quality-management",
    },
    {
        name: "ISO 10002 - Customer Satisfaction Management",
        tag: "certification-iso-10002-customer-satisfaction",
    },
    {
        name: "ISO 10018 - People Engagement Management",
        tag: "certification-iso-10018-people-engagement",
    },
    {
        name: "ISO 13485 - Medical Devices Quality Management",
        tag: "certification-iso-13485-medical-devices",
    },
    {
        name: "ISO 14001 - Environmental Management Systems",
        tag: "certification-iso-14001-environmental-management",
    },
    {
        name: "ISO 14064 - Greenhouse Gas Management",
        tag: "certification-iso-14064-greenhouse-gas",
    },
    {
        name: "ISO 17025 - Laboratory Testing and Calibration",
        tag: "certification-iso-17025-laboratory-testing",
    },
    {
        name: "ISO 20000 - IT Service Management",
        tag: "certification-iso-20000-it-service-management",
    },
    {
        name: "ISO 21001 - Educational Organizations Management",
        tag: "certification-iso-21001-educational-organizations",
    },
    {
        name: "ISO 21500 - Project Management in Construction",
        tag: "certification-iso-21500-project-management",
    },
    {
        name: "ISO 21502 - Project, Program, and Portfolio Management",
        tag: "certification-iso-21502-project-program",
    },
    {
        name: "ISO 22000 - Food Safety Management",
        tag: "certification-iso-22000-food-safety",
    },
    {
        name: "ISO 22301 - Business Continuity Management",
        tag: "certification-iso-22301-business-continuity",
    },
    {
        name: "ISO 22316 - Organizational Resilience",
        tag: "certification-iso-22316-organizational-resilience",
    },
    {
        name: "ISO 22716 - Good Manufacturing Practices (GMP) in Cosmetics",
        tag: "certification-iso-22716-gmp-cosmetics",
    },
    {
        name: "ISO 26000 - Social Responsibility Guidance",
        tag: "certification-iso-26000-social-responsibility",
    },
    {
        name: "ISO 27001 - Information Security Management",
        tag: "certification-iso-27001-information-security",
    },
    {
        name: "ISO 27017 - Cloud Security Management",
        tag: "certification-iso-27017-cloud-security",
    },
    {
        name: "ISO 27701 - Privacy Information Management",
        tag: "certification-iso-27701-privacy-information",
    },
    {
        name: "ISO 28000 - Supply Chain Security Management",
        tag: "certification-iso-28000-supply-chain-security",
    },
    {
        name: "ISO 28007 - Private Maritime Security",
        tag: "certification-iso-28007-maritime-security",
    },
    {
        name: "ISO 29001 - Petroleum, Petrochemical, and Natural Gas Quality",
        tag: "certification-iso-29001-petroleum-quality",
    },
    {
        name: "ISO 29993 - Learning Services Outside Formal Education",
        tag: "certification-iso-29993-learning-services",
    },
    {
        name: "ISO 30301 - Records Management",
        tag: "certification-iso-30301-records-management",
    },
    {
        name: "ISO 31000 - Risk Management",
        tag: "certification-iso-31000-risk-management",
    },
    {
        name: "ISO 3834 - Certification of Welding Quality",
        tag: "certification-iso-3834-welding-quality",
    },
    {
        name: "ISO 41001 - Facility Management",
        tag: "certification-iso-41001-facility-management",
    },
    {
        name: "ISO 45001 - Occupational Health and Safety",
        tag: "certification-iso-45001-occupational-health",
    },
    {
        name: "ISO 45005 - COVID-19 Safe Work Practices",
        tag: "certification-iso-45005-covid-19-safety",
    },
    {
        name: "ISO 50001 - Energy Management",
        tag: "certification-iso-50001-energy-management",
    },
    {
        name: "ISO 55001 - Asset Management",
        tag: "certification-iso-55001-asset-management",
    },
    {
        name: "ISO/IEC 17024 - Certification of Persons",
        tag: "certification-iso-iec-17024-persons",
    },
    {
        name: "ISO/TS 16949 - Automotive Production",
        tag: "certification-iso-ts-16949-automotive-production",
    },
    {
        name: "EN 1090 - Certification of Steel Structures",
        tag: "certification-en-1090-steel-structures",
    },
    {
        name: "ABET (Engineering and Technology Programs) Accreditation",
        tag: "certification-abet-engineering-programs",
    },
    {
        name: "Amfori BSCI – Social Performance in Global Supply Chains",
        tag: "certification-amfori-bsci",
    },
    {
        name: "Bank Account Certificate",
        tag: "certification-bank-account-certificate",
    },
    {
        name: "Basel II/III Compliance Certification",
        tag: "certification-basel-ii-iii-compliance",
    },
    {
        name: "BRC – British Retail Consortium Global Standards",
        tag: "certification-brc-global-standards",
    },
    {
        name: "Debio",
        tag: "certification-debio",
    },
    {
        name: "Ecovadis – Sustainability Rating",
        tag: "certification-ecovadis-sustainability",
    },
    {
        name: "EFQM - European Foundation for Quality Management Excellence Model",
        tag: "certification-efqm-quality-management",
    },
    {
        name: "Fair Trade Certification",
        tag: "certification-fair-trade",
    },
    {
        name: "FSC - Forest Stewardship Council",
        tag: "certification-fsc-forest-stewardship",
    },
    {
        name: "FSSC 22000 – Food Safety Certification Based on ISO 22000",
        tag: "certification-fssc-22000-food-safety",
    },
    {
        name: "GDPR Compliance Certification",
        tag: "certification-gdpr-compliance",
    },
    {
        name: "GRESB - Global Real Estate Sustainability Benchmark",
        tag: "certification-gresb-real-estate",
    },
    {
        name: "IATF 16949 - Automotive Quality Management",
        tag: "certification-iatf-16949-automotive-quality",
    },
    {
        name: "IFS – International Featured Standards",
        tag: "certification-ifs-standards",
    },
    {
        name: "Liability Insurance",
        tag: "certification-liability-insurance",
    },
    {
        name: "LEED - Leadership in Energy and Environmental Design",
        tag: "certification-leed-environmental-design",
    },
    {
        name: "Miljøfyrtårn",
        tag: "certification-miljofyrtarn",
    },
    {
        name: "NABET - National Accreditation Board for Education and Training",
        tag: "certification-nabet-education-training",
    },
    {
        name: "OSHA - Occupational Safety and Health",
        tag: "certification-osha-safety-health",
    },
    {
        name: "PMP - Project Management Professional",
        tag: "certification-pmp-project-management",
    },
    {
        name: "PRINCE2 - Projects in Controlled Environments",
        tag: "certification-prince2-projects",
    },
    {
        name: "Rainforest Alliance Certification",
        tag: "certification-rainforest-alliance",
    },
    {
        name: "Science Based Targets - (SBTi)",
        tag: "certification-science-based-targets",
    },
    {
        name: "Six Sigma Certification",
        tag: "certification-six-sigma",
    },
    {
        name: "SMETA – Sedex Members Ethical Trade Audit",
        tag: "certification-smeta-ethical-trade",
    },
    {
        name: "SOC 1/2/3 - System and Organization Controls",
        tag: "certification-soc-1-2-3-controls",
    },
    { name: "Other", tag: "other" },
];

export function getDocumentTypeName(tag: string): string {
    const documentType = documentTypes.find((doc) => doc.tag === tag);
    return documentType?.name ?? "";
}

export function getShortDocumentTypeName(tag: string): string {
    const documentType = documentTypes.find((doc) => doc.tag === tag);
    if (!documentType?.name) return "";
    return documentType.name.split(" - ")[0];
}
