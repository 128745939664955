import type { ResultOf } from "@graphql-typed-document-node/core";
import { Autocomplete, TextField, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";

import { useCountries } from "../../../hooks/countries";

const SupplierCountryFragment = graphql(`
    fragment CountrySelect_SupplierCountry on SupplierCountry {
        id
        name(language: $language)
    }
`);

interface CountrySelectProps {
    defaultValue: ResultOf<typeof SupplierCountryFragment> | null;
    onChange: (country: ResultOf<typeof SupplierCountryFragment> | null) => void;
}

export const CountrySelect: React.FC<CountrySelectProps> = ({ defaultValue, onChange }) => {
    const {
        data: {
            getCountries: { countries },
        },
        loading,
    } = useCountries();
    const { formatMessage } = useIntl();

    return (
        <Autocomplete
            size="small"
            loading={loading}
            loadingText={
                <FormattedMessage
                    defaultMessage="Loading..."
                    description="Loading autocomplete options placeholder text"
                />
            }
            options={countries}
            defaultValue={defaultValue}
            fullWidth
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, value) => onChange(value)}
            getOptionLabel={(country) => country.name}
            renderOption={(props, option) => (
                <Typography variant="textSm" {...props}>
                    {option.name}
                </Typography>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={formatMessage({
                        defaultMessage: "Headquarter country",
                        description: "Placeholder for country select",
                    })}
                />
            )}
        />
    );
};
