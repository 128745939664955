import { Chip, ChipProps as MuiChipProps } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { RiskEstimate, RiskScore } from "@/gql/graphql";

interface RiskScoreLabelProps {
    riskScore?: (RiskScore | "MISSING") | RiskEstimate | null;
}

export function RiskScoreLabel({ riskScore }: RiskScoreLabelProps): React.JSX.Element {
    switch (riskScore) {
        case "VERY_LOW":
            return <FormattedMessage defaultMessage="Very low" description="Risk score" />;
        case "LOW":
            return <FormattedMessage defaultMessage="Low" description="Risk score" />;
        case "MEDIUM":
            return <FormattedMessage defaultMessage="Medium" description="Risk score" />;
        case "HIGH":
            return <FormattedMessage defaultMessage="High" description="Risk score" />;
        case "VERY_HIGH":
            return <FormattedMessage defaultMessage="Very high" description="Risk score" />;
        default:
            return <FormattedMessage defaultMessage="N/A" description="Risk score not available" />;
    }
}

interface RiskChipProps extends Omit<MuiChipProps, "variant" | "label" | "color"> {
    riskScore?: (RiskScore | "MISSING") | RiskEstimate | null;
}

const RiskChip = React.forwardRef<HTMLDivElement, RiskChipProps>(function RiskChip(
    { riskScore, ...props },
    ref
): React.JSX.Element {
    let color: "error" | "warning" | "success" | "neutral";
    switch (riskScore) {
        case "VERY_LOW":
        case "LOW":
            color = "success";
            break;
        case "MEDIUM":
            color = "warning";
            break;
        case "HIGH":
        case "VERY_HIGH":
            color = "error";
            break;
        default:
            color = "neutral";
    }

    return (
        <Chip
            ref={ref}
            size="small"
            variant="status"
            label={<RiskScoreLabel riskScore={riskScore} />}
            color={color}
            {...props}
        />
    );
});

export { RiskChip };
