import { SearchField } from "@ignite-analytics/components";
import { Card, CardContent, Chip, Skeleton, Stack, Typography, Button } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoData } from "@/components/Common/NoData";
import { OverviewPage_SupplierFragment } from "@/gql/graphql";
import { filterUnreviewedDocuments } from "@/lib/files";
import { useSupplierPermission } from "@/lib/permissions";

import { InboxDocumentsList } from "./InboxDocumentsList";

interface DocumentTabProps {
    supplier?: OverviewPage_SupplierFragment;
    loading: boolean;
}

export const DocumentsTab: React.FC<DocumentTabProps> = ({ supplier, loading }) => {
    const { formatMessage } = useIntl();

    const { numberOfUnreviewedFiles, unreviewedFiles } = filterUnreviewedDocuments(
        supplier?.files ?? [],
        supplier?.assessmentsFiles ?? []
    );

    const [search, setSearch] = useState("");
    const [showAllDocuments, setShowAllDocuments] = useState(false);

    const filteredFiles = unreviewedFiles.filter((file) => file.name.toLowerCase().includes(search.toLowerCase()));
    const showShowMoreButton = filteredFiles.length > 10;
    const documentsToShow = showAllDocuments ? filteredFiles : filteredFiles.slice(0, 10);

    const isEditor = useSupplierPermission({ object: "general", relation: "write" });

    return (
        <Stack>
            <Stack spacing={3}>
                {numberOfUnreviewedFiles > 0 && (
                    <Stack spacing={2}>
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="Review and tag the following documents with a document type to have them available along with your supplier documents."
                                description="Documents header"
                            />
                        </Typography>
                        <Stack direction="row" spacing={3} alignItems="center">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="textLg" fontWeight={500}>
                                    <FormattedMessage defaultMessage="Total items" />
                                </Typography>
                                <Chip
                                    label={`${numberOfUnreviewedFiles}`}
                                    size="small"
                                    clickable={false}
                                    color="primary"
                                />
                            </Stack>
                            <SearchField
                                slotProps={{
                                    textField: {
                                        placeholder: formatMessage({ defaultMessage: "Search" }),
                                    },
                                }}
                                onChange={(_, value) => setSearch(value)}
                            />
                        </Stack>
                    </Stack>
                )}
                <InboxDocumentsList files={documentsToShow} supplierId={supplier?.id ?? ""} isEditor={isEditor} />
                {loading ? (
                    <Card>
                        <CardContent>
                            <Stack direction="row" justifyContent="space-between">
                                <Stack spacing={2}>
                                    <Skeleton width="171px" height="17px" sx={{ borderRadius: 0.5 }} />
                                    <Skeleton width="365px" height="17px" sx={{ borderRadius: 0.5 }} />
                                </Stack>
                                <Skeleton width="126px" height="40px" sx={{ borderRadius: 0.5 }} />
                            </Stack>
                        </CardContent>
                    </Card>
                ) : numberOfUnreviewedFiles === 0 ? (
                    <NoData
                        height="300px"
                        title={formatMessage({ defaultMessage: "No documents available" })}
                        message={formatMessage({
                            defaultMessage: "You don't have any incoming documents from Assessments to review yet.",
                        })}
                    />
                ) : null}
                {showShowMoreButton && !showAllDocuments && (
                    <Button color="linkPrimary" size="xsmall" onClick={() => setShowAllDocuments(true)}>
                        <FormattedMessage defaultMessage="Show more" />
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};
