import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { useAlert } from "@/providers";

import { calculateRisk } from "./helpers";

const getFinancialsSupplier = graphql(`
    query Financials_GetSupplier($input: GetSupplierInput!) {
        getSupplier(input: $input) {
            supplier {
                ...Financials_Supplier
            }
        }
    }
`);

graphql(`
    fragment Financials_FinancialStatementNew on FinancialStatementNew {
        year
        currencyCode
        ebit
        totalRevenue
        totalAssets
        totalCurrentLiabilities
        currentRatio
        ebitMargin
        equityRatio
    }
`);

graphql(`
    fragment Financials_Supplier on Supplier {
        id
        enrichmentsNew {
            latestFinancialStatement {
                ...Financials_FinancialStatementNew
            }
            financialStatements {
                ...Financials_FinancialStatementNew
            }
        }
        enrichmentsId
        savingsEstimate {
            potential
            factors {
                shareOfWallet {
                    value
                }
                ebitMargin {
                    value
                }
                roce {
                    value
                }
                spendGrowth {
                    value
                }
            }
        }
    }
`);

export type FinancialData = {
    year: number;
    revenue: number;
    ebitMargin: number;
    currency: string;
};

export const useFinancialsData = (supplierId: string) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const { data, loading, error } = useQuery(getFinancialsSupplier, {
        errorPolicy: "all",
        variables: {
            input: { id: supplierId, getNormalized: false },
        },
        skip: supplierId === "",
        onError: (error) => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Could not get financial data",
                }),
                severity: "error",
            });
            Sentry.captureException(error, {
                tags: { app: "supplier-profile-app", message: "Failed to get financials supplier" },
            });
        },
    });

    const enrichments = data?.getSupplier?.supplier?.enrichmentsNew;

    const financialData: FinancialData[] = (
        enrichments?.financialStatements.map((statement) => ({
            year: Number(statement.year ?? ""),
            revenue: statement.totalRevenue ?? 0,
            ebitMargin: statement.ebitMargin ?? 0,
            currency: statement.currencyCode ?? "",
        })) ?? []
    )
        .reverse()
        .slice(-4);

    const createChartOptions = (financialData: FinancialData[]) => ({
        revenuesMax: Math.max(...financialData.map((d) => d.revenue)),
        ebitMarginMin: Math.min(...financialData.map((d) => d.ebitMargin)),
        ebitMarginMax: Math.max(...financialData.map((d) => d.ebitMargin)),
    });

    const chartOptions = createChartOptions(financialData);

    const roce =
        (enrichments?.latestFinancialStatement?.ebit ?? 0) /
        ((enrichments?.latestFinancialStatement?.totalAssets ?? 1) -
            (enrichments?.latestFinancialStatement?.totalCurrentLiabilities ?? 0));

    const risk = {
        risk: [
            {
                name: "Risk",
                value:
                    calculateRisk(
                        roce,
                        enrichments?.latestFinancialStatement?.currentRatio ?? 0,
                        enrichments?.latestFinancialStatement?.equityRatio ?? 0
                    ) ?? 0,
            },
        ],
        liquidity:
            (enrichments?.latestFinancialStatement?.totalAssets ?? 0) /
            (enrichments?.latestFinancialStatement?.totalCurrentLiabilities ?? 1),
        solvency: enrichments?.latestFinancialStatement?.equityRatio ?? 0,
        roce: roce,
    };

    const potential = {
        potential: [
            {
                name: "Potential",
                value: Number(data?.getSupplier.supplier.savingsEstimate?.potential.toFixed(0)),
            },
        ],
        spendGrowth: data?.getSupplier.supplier.savingsEstimate?.factors?.spendGrowth?.value ?? 0,
        roce: data?.getSupplier.supplier.savingsEstimate?.factors?.roce?.value ?? 0,
        shareOfWallet: data?.getSupplier.supplier.savingsEstimate?.factors?.shareOfWallet?.value ?? 0,
        ebitMargin: data?.getSupplier.supplier.savingsEstimate?.factors?.ebitMargin?.value ?? 0,
    };

    return {
        loading,
        error,
        noMatch: data?.getSupplier.supplier.enrichmentsId === "",
        financialData: financialData,
        chartOptions: chartOptions,
        risk: risk,
        potential,
        hasFinancialData: financialData.length > 0,
        hasLatestFinancialStatement: enrichments?.latestFinancialStatement,
        hasSavingsEstimate: data?.getSupplier.supplier.savingsEstimate,
    };
};
