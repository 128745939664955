const NORDIC_COUNTRIES = ["Norway", "Sweden", "Denmark", "Finland", "Norge", "Sverige", "Danmark", "Finland"];
const COMPANY_SUBSTRINGS_TO_TRIM = [
    "Ltd",
    "L.T.D",
    "LT",
    "L.T",
    "GmbH",
    "G.m.b.H",
    "e.V.",
    "eV",
    "SPA",
    "S.P.A",
    "SL",
    "S.L",
    "SARL",
    "S.A.R.L",
    "SRL",
    "S.R.L",
    "AB",
    "A.B",
    "SA",
    "S.A",
    "APS",
    "A.P.S",
    "SAS",
    "S.A.S",
    "SASU",
    "S.A.S.U",
    "BV",
    "B.V",
    "CIB",
    "C.I.B",
    "AS",
    "A.S",
    "ASA",
    "A.S.A",
    "OY",
    "O.Y",
    "OYJ",
    "O.Y.J",
    "FIE",
    "F.I.E",
    "OÜ",
    "O.Ü",
    "AG",
    "A.G",
    "NV",
    "N.V",
];

// The regex to remove any substrings including a leading space that are found in the name and any text after them if there is any
const COMPANY_SUBSTRINGS_TO_TRIM_REGEX = new RegExp(
    `(${COMPANY_SUBSTRINGS_TO_TRIM.map((s) => ` ${s}`).join("|")})( .*)?`
);

// The regex to remove any country from the NORDIC_COUNTRIES array
const NORDIC_COUNTRIES_REGEX = new RegExp(` ${NORDIC_COUNTRIES.map((c) => ` ${c}`).join("|")}`);

/**
 * Given a company name, remove any company type substrings that are found in the name and any text that may come after them,
 * and if the company name contains a country from the NORDIC_COUNTRIES array, remove the country from the name
 * E.g. Talkmore AS Norway -> Talkmore or Telia Norge AS -> Telia
 */
export const cleanCompanyName = (name: string) => {
    // Given the list above, remove any substrings that are found in the name and any text after them
    // E.g. Company Name {Substring above} Norway -> Company Name

    let cleanedName = name.replace(COMPANY_SUBSTRINGS_TO_TRIM_REGEX, "");
    cleanedName = cleanedName.replace(NORDIC_COUNTRIES_REGEX, "");
    return cleanedName.trim();
};
