import { useIntl } from "react-intl";

import { SupplierCountryLanguage } from "@/gql/graphql";

function useSupplierCountryLanguage(): SupplierCountryLanguage {
    const { locale } = useIntl();
    switch (locale) {
        case "en-US":
            return SupplierCountryLanguage.EnUs;
        case "nb-NO":
            return SupplierCountryLanguage.NbNo;
        default:
            return SupplierCountryLanguage.EnUs;
    }
}

export { useSupplierCountryLanguage };
