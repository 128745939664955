import { useQuery } from "@apollo/client";
import { CircleSolid } from "@ignite-analytics/icons";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { RiskScore } from "@/gql/graphql";

import { useSupplierCountryLanguage } from "../../useSupplierCountryLanguage";

interface GeoRiskProps {
    country: string;
}

export const GeoRisk: React.FC<GeoRiskProps> = ({ country }) => {
    const theme = useTheme();
    const language = useSupplierCountryLanguage();
    const { data } = useQuery(
        graphql(`
            query GeographyRisk_GetCountry($id: ID!, $language: SupplierCountryLanguage) {
                getCountry(id: $id) {
                    country {
                        id
                        iso2Code
                        name(language: $language)
                        risk {
                            score
                        }
                    }
                }
            }
        `),
        {
            variables: {
                id: country,
                language,
            },
        }
    );
    const riskCountry = data?.getCountry?.country;
    const color = (() => {
        switch (riskCountry?.risk?.score) {
            case RiskScore.VeryLow:
            case RiskScore.Low:
                return theme.palette.tokens?.icon.iconSuccess;
            case RiskScore.Medium:
                return theme.palette.tokens?.icon.iconWarning;
            case RiskScore.High:
            case RiskScore.VeryHigh:
                return theme.palette.tokens?.icon.iconError;
            default:
                return theme.palette.tokens?.icon.iconGray;
        }
    })();

    return (
        <Stack direction="row" alignItems="center">
            {country ? (
                <Tooltip title={riskCountry?.name} followCursor>
                    <Stack direction="row" alignItems="center" spacing={1} maxWidth="100%" height="100%">
                        <CircleSolid sx={{ color, height: "8px", width: "8px" }} />
                        <Typography
                            variant="textSm"
                            noWrap
                            sx={{ textOverflow: "ellipsis", overflow: "ellipsis" }}
                        >{`${riskCountry?.iso2Code}: ${riskCountry?.name}`}</Typography>
                    </Stack>
                </Tooltip>
            ) : (
                <Typography>
                    <FormattedMessage defaultMessage="N/A" />
                </Typography>
            )}
        </Stack>
    );
};
