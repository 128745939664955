import { useMutation, useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { X } from "@ignite-analytics/icons";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import {
    AboutSupplierModal_ColumnsFragment,
    AboutSupplierModal_SupplierFragment,
    AboutSupplierModal_SupplierFragmentDoc,
    ClassificationOptions,
    ColumnType,
    SupplierCustomFieldType,
} from "@/gql/graphql";
import { track } from "@/lib/track";
import { useAlert } from "@/providers";

import { IgniteEstimateChip, ManualStatusChip } from "../../../../Common/StatusChip";

import { GeoRisk } from "./RiskFields/GeoRisk";
import { IndustryRisk } from "./RiskFields/IndustryRisk";
import { ProductionCountries } from "./RiskFields/ProductionCountries";
import { InfoRow } from "./utils";

graphql(`
    fragment AboutSupplierModal_Supplier on Supplier {
        id
        name
        country
        orgNumber
        nace
        productionCountries
        socialRiskScore {
            value
        }
        hasCodeOfConduct
        customFields {
            name
            dataJson
            fieldType
            fieldId
        }
        risk {
            social
            industry
            geography
        }
        onboarding {
            status
            approverId
            evaluatedAt
        }
    }

    fragment AboutSupplierModal_Columns on SupplierTableColumn {
        id
        name
        type
        typeOptions {
            ... on ClassificationOptions {
                groups {
                    id
                    value
                    level
                }
            }
            ... on SelectOptions {
                choices
            }
        }
    }
`);

const getSupplierTableColumnsQuery = graphql(`
    query getSupplierTableColumns {
        getSupplierTableMeta {
            columns {
                type
                ...AboutSupplierModal_Columns
            }
        }
    }
`);

const updateSupplierFieldMutation = graphql(`
    mutation SupplierInfo_UpdateSupplierField($input: UpdateSupplierFieldInput!) {
        updateSupplierField(input: $input) {
            supplier {
                ...AboutSupplierModal_Supplier
            }
        }
    }
`);

interface AboutSupplierModalProps {
    open: boolean;
    onClose: () => void;
    isEditor: boolean;
    supplier: AboutSupplierModal_SupplierFragment;
    approver?: string;
    edit: boolean;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    onboardingEnabled: boolean;
}

export const AboutSupplierModal: React.FC<AboutSupplierModalProps> = ({
    open,
    onClose,
    supplier,
    approver,
    isEditor,
    edit,
    setEdit,
    onboardingEnabled,
}) => {
    const [loading, setLoading] = useState(false);
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const newSocialRisk = useFeatureToggle("new-social-risk", false);
    const [customFields] = supplier.customFields.reduce(
        (acc, field) => {
            if (
                field.fieldType !== SupplierCustomFieldType.Assessment &&
                field.fieldType !== SupplierCustomFieldType.Nace
            ) {
                acc[0].push(field);
            }
            return acc;
        },
        [[] as typeof supplier.customFields]
    );
    const [updateSupplierField] = useMutation(updateSupplierFieldMutation, {
        update: (cache, { data }) => {
            const updatedSupplier = data?.updateSupplierField?.supplier;
            cache.writeFragment({
                id: `Supplier:${supplier.id}`,
                fragment: AboutSupplierModal_SupplierFragmentDoc,
                fragmentName: "AboutSupplierModal_Supplier",
                data: {
                    ...updatedSupplier,
                },
            });
        },
        onError: (error) => {
            alertUser({
                value: formatMessage({ defaultMessage: `Could not update supplier` }),
                severity: "error",
            });
            Sentry.captureException(error, {
                tags: { app: "supplier-profile-app", message: "Failed to update supplier" },
            });
        },
    });

    const { data } = useQuery(getSupplierTableColumnsQuery);
    const columns = data?.getSupplierTableMeta.columns ?? [];
    const supplierUpdates: Record<string, string> = {};
    const selectOptions = columns
        .filter((c) => c.type == "SELECT")
        .reduce(
            (acc, c) => {
                if (c.typeOptions?.__typename === "SelectOptions") {
                    acc[c.id] = c.typeOptions.choices;
                }
                return acc;
            },
            {} as Record<string, string[]>
        );

    const onFieldChange = (fieldId: string, value: string) => {
        supplierUpdates[fieldId] = value;
    };

    const handleClose = () => {
        track("Supplier Profile: Show More Supplier Info Closed");
        onClose();
    };

    const handleSave = async () => {
        setLoading(true);
        for (const [key, value] of Object.entries(supplierUpdates)) {
            await updateSupplierField({
                variables: {
                    input: {
                        id: supplier.id,
                        fieldId: key,
                        fieldData: JSON.stringify(value),
                    },
                },
            });
        }
        track("Supplier Profile: Save Supplier Info Clicked");
        setEdit(false);
        setLoading(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    maxHeight: "80vh",
                },
            }}
        >
            <DialogTitle>
                <IconButton onClick={handleClose} sx={{ position: "absolute", top: 8, right: 8 }}>
                    <X />
                </IconButton>
                <Stack>
                    <Typography variant="textXl">
                        <FormattedMessage defaultMessage="About" description="supplier information header" />
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 3, marginBottom: 3 }}>
                <Stack rowGap={2}>
                    {/* General information */}
                    <Grid container rowSpacing={2}>
                        <InfoRow
                            id="name"
                            name={formatMessage({ defaultMessage: "Name", description: "Name of the supplier" })}
                            value={supplier.name ?? ""}
                            type={SupplierCustomFieldType.Text}
                            edit={edit}
                            onFieldChange={onFieldChange}
                        />
                        <InfoRow
                            id="country"
                            name={formatMessage({ defaultMessage: "Country", description: "Country name" })}
                            value={supplier.country ?? ""}
                            type={SupplierCustomFieldType.Text}
                            edit={edit}
                            onFieldChange={onFieldChange}
                        />

                        <InfoRow
                            id={columns.find((c) => c.type === ColumnType.Nace)?.id ?? ""}
                            name={formatMessage({ defaultMessage: "Industry", description: "Industry name" })}
                            value={supplier.nace ?? ""}
                            type={SupplierCustomFieldType.Nace}
                            edit={edit}
                            onFieldChange={onFieldChange}
                        />
                        <InfoRow
                            id="reg_nr"
                            name={formatMessage({ defaultMessage: "VAT id", description: "VAT id name" })}
                            value={supplier.orgNumber ?? ""}
                            type={SupplierCustomFieldType.Text}
                            edit={edit}
                            onFieldChange={onFieldChange}
                        />
                    </Grid>
                    <Divider />
                    {/* Onboarding */}
                    {supplier.onboarding && onboardingEnabled && (
                        <>
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Onboarding" description="Onboarding info header" />
                            </Typography>
                            <Grid container rowSpacing={2}>
                                <InfoRow
                                    name={formatMessage({ defaultMessage: "Approver", description: "Approver name" })}
                                    value={approver ?? ""}
                                    type={SupplierCustomFieldType.Text}
                                    edit={false}
                                />
                                <InfoRow
                                    name={formatMessage({
                                        defaultMessage: "Approved at",
                                        description: "Approved at name",
                                    })}
                                    value={supplier.onboarding.evaluatedAt ?? ""}
                                    type={SupplierCustomFieldType.Date}
                                    edit={false}
                                />
                                <InfoRow
                                    name={formatMessage({
                                        defaultMessage: "Onboarding status",
                                        description: "Onboarding status name",
                                    })}
                                    value={supplier.onboarding.status}
                                    type="onboarding"
                                    edit={false}
                                />
                            </Grid>
                            <Divider />
                        </>
                    )}
                    <Typography variant="textMd" fontWeight={500}>
                        <FormattedMessage defaultMessage="Social Risk" description="Social Risk info header" />
                    </Typography>
                    <Grid container rowSpacing={2}>
                        {newSocialRisk ? (
                            <InfoRow
                                name={formatMessage({ defaultMessage: "Estimated Risk", description: "Social risk" })}
                                value=""
                                type={SupplierCustomFieldType.Risk}
                                component={
                                    supplier.socialRiskScore?.value ? (
                                        <ManualStatusChip status={supplier.socialRiskScore.value} />
                                    ) : (
                                        <IgniteEstimateChip status={supplier.risk?.social} />
                                    )
                                }
                            />
                        ) : (
                            <InfoRow
                                name={formatMessage({ defaultMessage: "Social risk", description: "Social risk" })}
                                value={supplier.risk?.social ?? ""}
                                type={SupplierCustomFieldType.Risk}
                            />
                        )}
                        <InfoRow
                            name={formatMessage({ defaultMessage: "Geography risk", description: "Geography risk" })}
                            value=""
                            type={SupplierCustomFieldType.Risk}
                            component={
                                <GeoRisk country={(supplier.productionCountries[0] || supplier.country) ?? ""} />
                            }
                        />
                        {newSocialRisk && (
                            <InfoRow
                                name={formatMessage({
                                    defaultMessage: "Production countries",
                                    description: "Production countries",
                                })}
                                value=""
                                type={SupplierCustomFieldType.Text}
                                component={<ProductionCountries countries={supplier.productionCountries ?? []} />}
                            />
                        )}
                        <InfoRow
                            name={formatMessage({ defaultMessage: "Industry risk", description: "Industry risk" })}
                            value=""
                            type={SupplierCustomFieldType.Risk}
                            component={<IndustryRisk nace={supplier.nace ?? ""} />}
                        />
                    </Grid>
                    <Divider />
                    {/* Custom fields */}
                    <Typography variant="textMd" fontWeight={500}>
                        <FormattedMessage defaultMessage="Custom Data" description="Custom data info header" />
                    </Typography>
                    <Grid container rowSpacing={2}>
                        {customFields
                            .sort((a, b) => a.fieldType?.toString().localeCompare(b.fieldType?.toString()))
                            // we display risk fields in a different section
                            .filter((field) => field.fieldType !== SupplierCustomFieldType.Risk)
                            .reduce((acc: JSX.Element[], field) => {
                                // need special handling of classification fields
                                if (field.fieldType !== SupplierCustomFieldType.Classification) {
                                    const value = JSON.parse(field.dataJson);
                                    return [
                                        ...acc,
                                        <InfoRow
                                            id={field.fieldId}
                                            key={field.name}
                                            name={field.name}
                                            value={value}
                                            type={field.fieldType}
                                            edit={edit}
                                            onFieldChange={onFieldChange}
                                            selectOptions={selectOptions[field.fieldId]}
                                        />,
                                    ];
                                }
                                const column = columns.find(
                                    (tc: AboutSupplierModal_ColumnsFragment) => tc.id === field.fieldId
                                );
                                // get the classification column
                                const c = JSON.parse(field.dataJson);
                                if (!column || !c) {
                                    return [
                                        ...acc,
                                        <InfoRow
                                            key={field.name}
                                            name={field.name}
                                            value=""
                                            type={field.fieldType}
                                            edit={false}
                                        />,
                                    ];
                                }

                                // get the values of the classification groups from the ids in the custom field (dataJson)
                                const values = c.reduce((a: string[], v: string) => {
                                    const group = (column.typeOptions as ClassificationOptions)?.groups?.find(
                                        (g) => g.id === v
                                    );
                                    if (!group?.id) {
                                        return a;
                                    }
                                    return [...a, group?.value];
                                }, []);

                                if (values.length === 0) {
                                    return acc;
                                }
                                return [
                                    ...acc,
                                    <InfoRow
                                        key={field.name}
                                        name={field.name}
                                        value={values}
                                        type={field.fieldType}
                                        edit={false}
                                    />,
                                ];
                            }, [])}
                    </Grid>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ boxShadow: "0px -1px 2px #0D10171F", paddingTop: 3 }}>
                {edit ? (
                    <Stack direction="row" spacing={2}>
                        <NoPermissionTooltip hasPermission={isEditor}>
                            <Button
                                size="small"
                                color="secondary"
                                disabled={!isEditor || loading}
                                onClick={() => {
                                    track("Supplier Profile: Cancel Supplier Edit Clicked");
                                    setEdit((prev) => !prev);
                                }}
                            >
                                <FormattedMessage defaultMessage="Cancel" description="Cancel" />
                            </Button>
                        </NoPermissionTooltip>
                        <Button color="primary" size="small" disabled={!isEditor || loading} onClick={handleSave}>
                            {loading ? (
                                <CircularProgress size={25} />
                            ) : (
                                <FormattedMessage defaultMessage="Save" description="Save button supplier info" />
                            )}
                        </Button>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2}>
                        <NoPermissionTooltip hasPermission={isEditor}>
                            <Button
                                size="small"
                                color="secondary"
                                disabled={!isEditor || loading}
                                onClick={() => {
                                    track("Supplier Profile: Edit Supplier Info Clicked");
                                    setEdit((prev) => !prev);
                                }}
                            >
                                <FormattedMessage defaultMessage="Edit" description="Edit" />
                            </Button>
                        </NoPermissionTooltip>
                        <Button color="secondary" size="small" disabled={loading} onClick={handleClose}>
                            <FormattedMessage defaultMessage="Close" description="Close" />
                        </Button>
                    </Stack>
                )}
            </DialogActions>
        </Dialog>
    );
};
