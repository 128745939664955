import { Plus } from "@ignite-analytics/icons";
import { Button, Checkbox, Paper, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { Task_DetailsFragment } from "@/gql/graphql";

const mockTasks = [
    { id: "1", name: "Request documents", completed: false },
    { id: "2", name: "Book stakeholder meeting", completed: false },
];

// Define our own task fragment
graphql(`
    fragment Task_Details on Task {
        id
        title
        status
        supplierID
    }

    mutation TaskRow_CreateTask($input: CreateTaskInput!) {
        createTask(input: $input) {
            task {
                ...Task_Details
            }
        }
    }

    mutation TaskRow_UpdateTask($input: UpdateTaskInput!) {
        updateTask(input: $input) {
            task {
                ...Task_Details
            }
        }
    }

    mutation TaskRow_DeleteTask($input: DeleteTaskInput!) {
        deleteTask(input: $input) {
            deleted
        }
    }
`);

export const TasksSection: React.FC<{ tasks: Task_DetailsFragment[] }> = () => {
    return (
        <Paper sx={{ p: 2 }} elevation={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="textLg" fontWeight={500}>
                    <FormattedMessage defaultMessage="Tasks to do" />
                </Typography>
            </Stack>

            <Stack spacing={1}>
                {mockTasks.map((task) => (
                    <Stack key={task.id} direction="row" alignItems="center" spacing={1}>
                        <Checkbox checked={task.completed} size="small" />
                        <Typography>{task.name}</Typography>
                    </Stack>
                ))}
                <Button variant="text" color="secondary" size="small" startIcon={<Plus />}>
                    <FormattedMessage defaultMessage="Add task" />
                </Button>
            </Stack>
        </Paper>
    );
};
