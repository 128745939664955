import { File } from "@ignite-analytics/icons";
import { Button, Chip, Grid2, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const mockRequirements = [
    { id: "1", name: "ISO 7001-12", status: "not_requested" },
    { id: "2", name: "ISO 9001", status: "not_requested" },
    { id: "3", name: "ISO 14001", status: "not_requested" },
];

export const RequirementsSection = () => {
    return (
        <Paper sx={{ p: 2 }} elevation={1}>
            <Grid2 container spacing={2}>
                <Grid2 size={12} container justifyContent="space-between" alignItems="center">
                    <Grid2>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Supplier requirements" />
                        </Typography>
                    </Grid2>
                    <Grid2>
                        <Button variant="outlined" color="secondary" size="small">
                            <FormattedMessage defaultMessage="Add requirement" />
                        </Button>
                    </Grid2>
                </Grid2>

                {mockRequirements.map((req) => (
                    <Grid2 size={12} key={req.id} container justifyContent="space-between" alignItems="center">
                        <Grid2 container alignItems="center" spacing={1}>
                            <Grid2>
                                <File />
                            </Grid2>
                            <Grid2>
                                <Typography variant="textMd" fontWeight={500}>
                                    {req.name}
                                </Typography>
                            </Grid2>
                        </Grid2>
                        <Grid2>
                            <Chip
                                label={<FormattedMessage defaultMessage="Not requested" />}
                                color="error"
                                size="large"
                            />
                        </Grid2>
                    </Grid2>
                ))}
            </Grid2>
        </Paper>
    );
};
