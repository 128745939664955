import { Chip, ChipProps } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { RiskScore } from "@/gql/graphql";

const LevelText: React.FC<{ status: RiskScore }> = ({ status }) => {
    switch (status) {
        case RiskScore.VeryLow:
            return <FormattedMessage defaultMessage="Very low" description="Very low risk chip label" />;
        case RiskScore.Low:
            return <FormattedMessage defaultMessage="Low" description="Low risk chip label" />;
        case RiskScore.Medium:
            return <FormattedMessage defaultMessage="Medium" description="Medium risk chip label" />;
        case RiskScore.High:
            return <FormattedMessage defaultMessage="High" description="High risk chip label" />;
        case RiskScore.VeryHigh:
            return <FormattedMessage defaultMessage="Very high" description="Very high risk chip label" />;
    }
};

const getLevelColor = (status: RiskScore) => {
    switch (status) {
        case RiskScore.VeryLow:
            return "success";
        case RiskScore.Low:
            return "success";
        case RiskScore.Medium:
            return "warning";
        case RiskScore.High:
            return "error";
        case RiskScore.VeryHigh:
            return "error";
    }
};

const stringToRiskScore = (status: string): RiskScore | undefined => {
    switch (status) {
        case "very_low":
        case "veryLow":
            return RiskScore.VeryLow;
        case "low":
            return RiskScore.Low;
        case "medium":
            return RiskScore.Medium;
        case "high":
            return RiskScore.High;
        case "very_high":
        case "veryHigh":
            return RiskScore.VeryHigh;
    }
};

interface IgniteEstimateChipProps extends ChipProps {
    status?: RiskScore | null;
}

export const IgniteEstimateChip = ({ status, ...props }: IgniteEstimateChipProps) => {
    if (!status) return null;

    return (
        <Chip
            label={<LevelText status={status} />}
            variant="status"
            color={getLevelColor(status)}
            sx={{ border: "1px, dashed" }}
            size="small"
            {...props}
        />
    );
};

interface ManualStatusChipProps extends ChipProps {
    status: string | undefined;
}

export const ManualStatusChip = ({ status, ...props }: ManualStatusChipProps) => {
    const convertedStatus = status ? stringToRiskScore(status) : null;
    if (!convertedStatus) return null;

    return (
        <Chip
            label={<LevelText status={convertedStatus} />}
            size="small"
            variant="status"
            color={getLevelColor(convertedStatus)}
            sx={{ border: "1px" }}
            {...props}
        />
    );
};
