import { ShapeIcon } from "@ignite-analytics/components";
import { MinusCircle } from "@ignite-analytics/icons";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

interface UnapplyRequirementDialogProps {
    open: boolean;
    requirementId: string;
    onClose: () => void;
}

export const UnapplyRequirementDialog: React.FC<UnapplyRequirementDialogProps> = ({ open, requirementId, onClose }) => {
    const handleUnapply = () => {
        console.log(requirementId);
    };
    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: "416px" } }}>
            <DialogTitle>
                <Stack alignItems="center" spacing={2}>
                    <ShapeIcon color="error" size="medium" icon={<MinusCircle />} />
                    <FormattedMessage
                        defaultMessage="Unapply requirement"
                        description="Unapply requirement dialog title"
                    />
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText paddingTop={2}>
                    <FormattedMessage
                        defaultMessage="This action will remove the requirement from this supplier. Are you sure you want to proceed?"
                        description="Unapply requirement dialog content"
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" spacing={1} width="100%">
                    <Button fullWidth onClick={onClose} color="secondary">
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <Button fullWidth onClick={handleUnapply}>
                        <FormattedMessage defaultMessage="Unapply" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
