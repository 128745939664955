import { useQuery } from "@apollo/client";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import React, { useState } from "react";

import { graphql } from "@/gql";
import { SelectSupplier_SupplierFragment } from "@/gql/graphql";

graphql(`
    fragment SelectSupplier_Supplier on Supplier {
        id
        name
        country
        orgNumber
    }
`);

const SUPPLIERS_QUERY = graphql(`
    query OnboardingSuppliers($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                ...SelectSupplier_Supplier
            }
        }
    }
`);

const SelectSupplier: React.FC = () => {
    const [search, setSearch] = useState<string>("");
    const [availableSuppliers, setAvailableSuppliers] = useState<SelectSupplier_SupplierFragment[]>([]);
    const handleOnInputChange = React.useMemo(
        () =>
            debounce((val) => {
                setSearch(val);
            }, 500),
        [setSearch]
    );

    useQuery(SUPPLIERS_QUERY, {
        variables: {
            input: {
                pageIndex: 0,
                pageRange: 100,
                fallbackToOldDMS: false,
                ...(search != "" ? { nameSearch: search } : {}),
            },
        },
        onCompleted: (data) => setAvailableSuppliers(data.getSuppliers.suppliers),
    });

    return (
        <Stack alignItems="center" padding={3} gap={2} sx={{ height: "100%" }}>
            <Autocomplete
                key="select-supplier"
                options={availableSuppliers}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => value.name === option.name}
                renderOption={(props, sup) => (
                    <Stack component="li" {...props} key={sup.id}>
                        <Typography>{sup.name}</Typography>
                        <Typography variant="caption">{sup.id}</Typography>
                    </Stack>
                )}
                renderInput={(params) => <TextField {...params} label="Select supplier" />}
                onInputChange={(_, phrase) => {
                    handleOnInputChange(phrase);
                }}
                onChange={(_, value) => {
                    if (!value) return;
                    window.location.href = `/supplier-profile/${value.id}/overview`;
                }}
                ListboxProps={{
                    sx: {
                        "& .MuiAutocomplete-option": {
                            minHeight: "50px",
                            display: "flex",
                            justifyContent: "center",
                        },
                    },
                }}
                sx={{ width: 500 }}
            />
        </Stack>
    );
};

export default SelectSupplier;
