import { CoachMark, CoachMarkContent, CoachMarkHeader } from "@ignite-analytics/components";
import { Alert, AlertTitle, Link, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

interface Props {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

export const EmissionFactorCoachMark: React.FC<Props> = ({ open, anchorEl, onClose }) => {
    const { formatMessage } = useIntl();

    const exiobaseLink = useMemo(
        () => (
            <Link href="https://www.exiobase.eu/index.php/about-exiobase" target="_blank" underline="none">
                EXIOBASE
            </Link>
        ),
        []
    );
    return (
        <CoachMark anchorEl={anchorEl} open={open} width="900px" onClose={onClose} zIndex={3}>
            <CoachMarkHeader
                title={formatMessage({ defaultMessage: "Calculating spend-based emissions" })}
                onClose={onClose}
            />
            <CoachMarkContent>
                <Stack width="100%" gap={4}>
                    <Typography variant="textSm" color="textTextBody">
                        {formatMessage(
                            {
                                defaultMessage:
                                    "Spend-based estimations enable carbon footprint calculations for purchased goods and services (GHG Protocol Category 3.1) using expenditure data. They are especially valuable when precise activity data is not readily available. However, the application of spend-based emission factors can be complex. Some emission factor sources, like the UK-specific BEIS spend-based dataset, use the purchaser price directly. On the other hand, sources like {exiobaseLink}, the most popular provider of spend emission factors, call for the use of the basic price. It's essential to apply the basic price with EXIOBASE emission factors, as using purchaser prices may result in an overestimation of the calculated footprint.",
                            },
                            { exiobaseLink }
                        )}
                    </Typography>

                    <Alert severity="info">
                        <AlertTitle>
                            {formatMessage({ defaultMessage: "Understanding Basic Price and Purchaser Prices" })}
                        </AlertTitle>
                        <Typography variant="textSm" color="textTextBody">
                            {formatMessage({
                                defaultMessage:
                                    "The basic price represents the initial cost set by a producer for a product or service, without additional fees like taxes or delivery costs (referred to as tax and transport margins). Trade margins and additional charges are often added to the basic price if you're not buying directly from the producer. The purchaser price, the total amount you usually pay, combines the basic price, trade margin, tax margin, and transport margin.",
                            })}
                        </Typography>
                    </Alert>

                    <Typography variant="textSm" color="textTextBody">
                        {formatMessage({
                            defaultMessage:
                                "Another challenge with spend-based emission calculations is accurately adjusting for changes in currency exchange rates and inflation. Particularly, when your expenditure occurred in a different year than the emission factor's year, your expenditure amount has to be adjusted to match that emission factor's year. This requires taking into account both inflation adjustment and exchange rate fluctuations to arrive at the adjusted spend amount.",
                        })}
                    </Typography>

                    <Typography variant="textSm" color="textTextBody">
                        {formatMessage(
                            {
                                defaultMessage:
                                    "We automatically calculate basic prices for use with {exiobaseLink}. We account for tax, trade and transport margins using per-sector and per-country margins from EXIOBASE. Currency exchange rates and inflation adjustments are also considered, using rates from the UN Treasury, the IRS and the World Bank, supplemented with per-industry inflation numbers from Eurostat.",
                            },
                            { exiobaseLink }
                        )}
                    </Typography>
                </Stack>
            </CoachMarkContent>
        </CoachMark>
    );
};
