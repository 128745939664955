import { useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";

import { graphql } from "@/gql";
import {
    Activities_UserFragment,
    ActivityBar_SupplierFragment,
    Activity_NoteFragment,
    DocumentActivityMetadata,
} from "@/gql/graphql";

import { isValidDate } from "../Pages/Overview/SideColumn/SupplierInfo/utils";

import { DocumentActivity } from "./DocumentActivity";
import { NoteActivity } from "./NoteActivity";
import { OnboardingEvaluatedActivity, OnboardingInitiatedActivity } from "./OnboardingActivity";
import { RiskReasonActivity } from "./RiskReasonActivity";
import { OnboardingEvaluated, OnboardingInitiated } from "./types";
import { aggregateActivityData } from "./utils";

graphql(`
    fragment Activity_Note on Note {
        id
        note
        createdBy
        createdAt
        updatedBy
        updatedAt
        supplierId
        type
        reactions {
            emojiCode
            userIDs
        }
        metadata {
            prevValue
            newValue
        }
        activityMetadata {
            ... on DocumentActivityMetadata {
                documentType
                documentEvent
                userId
                fileName
                originalFilename
                field
                prevValue
                newValue
            }
            ... on ChangeActivityMetadata {
                prevValue
                newValue
            }
        }
    }

    fragment Activities_User on RoleUser {
        id
        firstName
        lastName
        fullName
        ...RiskReasonActivity_User
    }

    fragment Onboarding on Onboarding {
        status
        approverId
        evaluatedAt
        createdAt
    }
    fragment ActivityBar_Supplier on Supplier {
        id
        name
        riskV3 {
            social {
                gross
            }
        }
        onboarding {
            ...Onboarding
        }
        notes {
            ...Activity_Note
        }
    }
`);

const getUsersDocument = graphql(`
    query Note_GetUser($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...Activities_User
            }
        }
    }
`);

export const Activities: React.FC<{ supplier: ActivityBar_SupplierFragment; isEditor: boolean }> = ({
    supplier,
    isEditor,
}) => {
    const onboardingEnabled = useFeatureToggle("onboarding-enabled", true);

    const { data: usersData } = useQuery(getUsersDocument, {
        variables: { input: {} },
        onError: () => {},
    });

    if (!usersData || !supplier) {
        return;
    }
    const users = usersData?.getUsers?.result;
    const activities = aggregateActivityData(supplier)
        .filter((activity) => isValidDate(activity.timestamp))
        .sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp));

    return activities.map((activity) => {
        switch (activity.type) {
            case "OnboardingInitiated": {
                if (!onboardingEnabled) {
                    return;
                }
                const onboardingInitiated = activity.content as OnboardingInitiated;
                return (
                    <OnboardingInitiatedActivity
                        key={`${activity.type}${supplier.id}`}
                        supplierName={onboardingInitiated.name}
                        createdAt={onboardingInitiated.createdAt}
                    />
                );
            }
            case "OnboardingEvaluated": {
                if (!onboardingEnabled) {
                    return;
                }
                const onboardingEvaluated = activity.content as OnboardingEvaluated;
                const user = users?.find((user: Activities_UserFragment) => user.id === onboardingEvaluated.approverId);
                const reason = (
                    activities.find((a) => (a.content as Activity_NoteFragment).type === "onboarding")
                        ?.content as Activity_NoteFragment
                )?.note;
                return (
                    <OnboardingEvaluatedActivity
                        key={`${activity.type}${supplier.id}`}
                        approvedBy={user?.firstName + " " + user?.lastName}
                        onboardingStatus={onboardingEvaluated?.status}
                        evaluatedAt={onboardingEvaluated.evaluatedAt}
                        supplierName={supplier.name}
                        reason={reason}
                    />
                );
            }
            case "Risk": {
                const noteActivity = activity.content as Activity_NoteFragment;
                if (!noteActivity || noteActivity.type === "onboarding") {
                    return;
                }
                const user = noteActivity.updatedBy
                    ? users?.find((user: Activities_UserFragment) => user.id === noteActivity.updatedBy)
                    : users?.find((user: Activities_UserFragment) => user.id === noteActivity.createdBy);

                return (
                    user && (
                        <RiskReasonActivity
                            note={noteActivity}
                            user={user}
                            igniteRiskScore={supplier.riskV3?.social?.gross ?? undefined}
                        />
                    )
                );
            }
            case "Document": {
                const activityDetails = activity.content as Activity_NoteFragment;
                const documentActivityData = activityDetails.activityMetadata as DocumentActivityMetadata;
                const user = users?.find((user: Activities_UserFragment) => user.id === documentActivityData.userId);
                if (!documentActivityData || !activityDetails) {
                    return;
                }

                return (
                    <DocumentActivity
                        documentDetails={documentActivityData}
                        name={user ? user.fullName : supplier.name}
                        noteCreatedAt={activityDetails.createdAt}
                    />
                );
            }
            case "Note": {
                const noteActivity = activity.content as Activity_NoteFragment;
                if (!noteActivity || noteActivity.type === "onboarding") {
                    return;
                }
                const user = noteActivity.updatedBy
                    ? users?.find((user: Activities_UserFragment) => user.id === noteActivity.updatedBy)
                    : users?.find((user: Activities_UserFragment) => user.id === noteActivity.createdBy);
                return (
                    user && (
                        <NoteActivity
                            note={noteActivity}
                            key={noteActivity.id}
                            createdBy={user}
                            isEditor={isEditor}
                            users={users}
                        />
                    )
                );
            }
        }
    });
};
