import { useQuery } from "@apollo/client";
import { CircleSolid } from "@ignite-analytics/icons";
import { Chip, chipClasses, Stack, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { RiskScore } from "@/gql/graphql";

import { useSupplierCountryLanguage } from "../../useSupplierCountryLanguage";

interface ProductionCountriesProps {
    countries: string[];
}

export const ProductionCountries: React.FC<ProductionCountriesProps> = ({ countries }) => {
    const theme = useTheme();

    const language = useSupplierCountryLanguage();
    const { data } = useQuery(
        graphql(`
            query ProductionCountries_GetCountries($language: SupplierCountryLanguage) {
                getCountries {
                    countries {
                        id
                        iso2Code
                        name(language: $language)
                        risk {
                            score
                        }
                    }
                }
            }
        `),
        {
            variables: {
                language,
            },
        }
    );

    const getCountryColor = (score: RiskScore | null | undefined) => {
        switch (score) {
            case RiskScore.VeryLow:
            case RiskScore.Low:
                return theme.palette.tokens?.icon.iconSuccess;
            case RiskScore.Medium:
                return theme.palette.tokens?.icon.iconWarning;
            case RiskScore.High:
            case RiskScore.VeryHigh:
                return theme.palette.tokens?.icon.iconError;
            default:
                return theme.palette.tokens?.icon.iconGray;
        }
    };
    return (
        <Stack direction="row" gap={1.25} flexWrap="wrap">
            {countries.length > 0 ? (
                countries.map((country) => {
                    const riskCountry = data?.getCountries.countries.find((c) => c.id === country);
                    return (
                        <Chip
                            key={country}
                            size="small"
                            color="neutral"
                            sx={{
                                [`& .${chipClasses.icon}`]: {
                                    color: getCountryColor(riskCountry?.risk?.score),
                                },
                            }}
                            icon={<CircleSolid fontSize="small" />}
                            label={`${riskCountry?.iso2Code}: ${riskCountry?.name}`}
                        />
                    );
                })
            ) : (
                <Typography>
                    <FormattedMessage defaultMessage="N/A" />
                </Typography>
            )}
        </Stack>
    );
};
