import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import React, { useMemo } from "react";
import { createBrowserRouter, Navigate, NavigateFunction, RouterProvider } from "react-router-dom";

import { Layout } from "@/components/Layout";
import { AssessmentsPage } from "@/components/Pages/Assessments";
import { EmissionsPage } from "@/components/Pages/Emissions";
import { FinancialsPage } from "@/components/Pages/Financials";
import { OverviewPage } from "@/components/Pages/Overview";
import { SelectSupplierPage } from "@/components/Pages/SelectSupplier";
import { SpendPage } from "@/components/Pages/Spend";
import { graphql } from "@/gql";
import { ElectricityBasis } from "@/gql/graphql";
import { useAlert } from "@/providers";
import { NavigationContextProvider } from "@/providers/NavigationContext";
import { PollingContextProvider } from "@/providers/PollingContext";

interface SupplierProfileRouteParams {
    navigate?: NavigateFunction;
}

const getNormalizedSupplier = graphql(`
    query AppRoutes_GetSupplier($input: GetSupplierInput!, $electricityBasis: ElectricityBasis!) {
        getSupplier(input: $input) {
            supplier {
                id
                ...OverviewPage_Supplier
                ...Layout_Supplier
            }
        }
    }
`);

export const AppRoutes: React.FC<SupplierProfileRouteParams> = ({ navigate }) => {
    const pathSegments = window.location.pathname.split("/");
    const id = pathSegments[2];
    const { alertUser } = useAlert();

    const getNormalized = useMemo(() => {
        // If ?getNormalized=true is in the URL, we fetch the normalized supplier
        const currentParams = window.location.search;
        const searchParams = new URLSearchParams(currentParams);
        return searchParams.get("getNormalized") === "true";
    }, []);

    const { data, refetch, loading, startPolling, stopPolling } = useQuery(getNormalizedSupplier, {
        errorPolicy: "all",
        variables: { input: { id, getNormalized: getNormalized }, electricityBasis: ElectricityBasis.MarketBased },
        fetchPolicy: "cache-and-network",
        skip: id === "",
        onError: (error) => {
            if (data?.getSupplier.supplier !== null) {
                alertUser({ value: `Could not get all data for supplier: ${error.message}`, severity: "error" });
            } else {
                alertUser({ value: "Could not get supplier data", severity: "error" });
            }
            Sentry.captureException(error, {
                tags: { app: "supplier-profile-app", message: "Failed to get federated supplier" },
            });
        },
    });

    const router = createBrowserRouter([
        {
            id: "root",
            path: "/",
            element: (
                <NavigationContextProvider navigateFunc={navigate}>
                    <Layout supplier={data?.getSupplier.supplier} loading={loading} />
                </NavigationContextProvider>
            ),
            children: [
                {
                    path: "supplier-profile/:id/overview",
                    element: (
                        <PollingContextProvider startPolling={startPolling} stopPolling={stopPolling}>
                            <OverviewPage supplier={data?.getSupplier.supplier} loading={loading} refetch={refetch} />
                        </PollingContextProvider>
                    ),
                },
                {
                    path: "supplier-profile/:id/spend",
                    element: <SpendPage supplierId={data?.getSupplier.supplier.id ?? ""} />,
                },
                {
                    path: "supplier-profile/:id/emissions",
                    element: <EmissionsPage supplierId={data?.getSupplier.supplier.id ?? ""} />,
                },
                {
                    path: "supplier-profile/:id/financials",
                    element: <FinancialsPage supplierId={data?.getSupplier.supplier.id ?? ""} />,
                },
                {
                    path: "supplier-profile/:id/assessments",
                    element: <AssessmentsPage supplierId={data?.getSupplier.supplier.id ?? ""} />,
                },
                { path: "supplier-profile/select", element: <SelectSupplierPage refetch={refetch} /> },
                { path: "supplier-profile/", element: <Navigate to="/supplier-profile/select" /> },
                { path: "", element: <Navigate to="/supplier-profile/select" /> },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
};
