import { Plus } from "@ignite-analytics/icons";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";

import { RequirementsList } from "./RequirementsList";

const Requirements: React.FC = () => {
    useSetBreadcrumbs("requirements");

    const handleCreateNew = () => {
        // TODO: Implement create new functionality
        console.log("Create new requirement clicked");
    };

    return (
        <Stack direction="column" gap={2} padding={4}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom="24px">
                <Typography variant="displayXs" fontWeight="bold">
                    <FormattedMessage
                        defaultMessage="Supplier requirements"
                        description="Supplier requirements title"
                    />
                </Typography>
                <Button variant="contained" color="primary" size="small" startIcon={<Plus />} onClick={handleCreateNew}>
                    <FormattedMessage defaultMessage="Create new" description="Create new requirement button" />
                </Button>
            </Stack>
            <RequirementsList />
        </Stack>
    );
};

export default Requirements;
