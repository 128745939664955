import type { ResultOf } from "@graphql-typed-document-node/core";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { useCountries } from "@/hooks/countries";

const SupplierCountryFragment = graphql(`
    fragment ProductionCountrySelect_SupplierCountry on SupplierCountry {
        id
        name(language: $language)
    }
`);

interface ProductionCountrySelectProps {
    defaultValue: ResultOf<typeof SupplierCountryFragment>[];
    onChange: (country: ResultOf<typeof SupplierCountryFragment>[] | null) => void;
}

function ProductionCountriesSelect(props: ProductionCountrySelectProps): React.ReactNode {
    const { formatMessage } = useIntl();
    const {
        data: {
            getCountries: { countries },
        },
        loading,
    } = useCountries();

    return (
        <Autocomplete
            defaultValue={props.defaultValue}
            options={countries ?? []}
            loading={loading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, value) => props.onChange(value)}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            loadingText={
                <FormattedMessage defaultMessage="Loading..." description="Automplete loading placeholder text" />
            }
            multiple
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    fullWidth
                    placeholder={formatMessage({
                        defaultMessage: "Production countries",
                        description: "Placeholder for country select",
                    })}
                />
            )}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => {
                    const { key, ...otherProps } = getTagProps({ index });
                    return <Chip color="neutral" label={option.name} size="small" key={key} {...otherProps} />;
                });
            }}
        />
    );
}

export { ProductionCountriesSelect };
