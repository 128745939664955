import { gql, useQuery } from "@apollo/client";
import { Box, Button, Grid2, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { Onboarding_SupplierFragment } from "@/gql/graphql";

import { QuestionnairesSection } from "./sections/QuestionnairesSection";
import { RequirementsSection } from "./sections/RequirementsSection";
import { SupplierInformationSection } from "./sections/SupplierInformationSection";
import { TasksSection } from "./sections/TasksSection";

graphql(`
    fragment Onboarding_Supplier on Supplier {
        id
        name
        country
        orgNumber
        tasks {
            id
            ...Task_Details
        }
        onboarding {
            status
            approverId
            evaluatedAt
        }
        contracts {
            id
        }
    }
`);

const GetSupplier_OnboardingQuery = gql(`
    query Onboarding_GetSupplier($input: GetSupplierInput!) {
        getSupplier(input: $input) {
            supplier {
                ...Onboarding_Supplier
            }
        }
    }
`);

interface Props {
    supplier?: Onboarding_SupplierFragment;
}

export const OnboardingPage: React.FC<Props> = ({ supplier }) => {
    const { data } = useQuery(GetSupplier_OnboardingQuery, {
        variables: {
            input: {
                id: supplier?.id,
            },
        },
    });

    return (
        <Box>
            <Grid2 container spacing={2}>
                <Grid2 size={8}>
                    <Stack spacing={2}>
                        <SupplierInformationSection />
                        <RequirementsSection />
                        <QuestionnairesSection />
                    </Stack>
                </Grid2>
                <Grid2 size={4} spacing={2}>
                    <Stack spacing={2}>
                        <TasksSection tasks={data?.getSupplier.supplier.tasks} />
                        <Grid2 container spacing={2} justifyContent="space-between">
                            <Stack spacing={1} direction="row" width="100%">
                                <Button variant="outlined" color="secondary" fullWidth>
                                    <Typography noWrap>
                                        <FormattedMessage defaultMessage="Request information" />
                                    </Typography>
                                </Button>
                                <Button variant="contained">
                                    <FormattedMessage defaultMessage="Finalize" />
                                </Button>
                            </Stack>
                        </Grid2>
                    </Stack>
                </Grid2>
            </Grid2>
        </Box>
    );
};
