import { RiskScore } from "@/gql/graphql";

function isRiskScore(value: string | undefined | null): value is RiskScore | "MISSING" {
    if (value === undefined || value === null) {
        return false;
    }
    const scores: string[] = [
        RiskScore.VeryLow,
        RiskScore.Low,
        RiskScore.Medium,
        RiskScore.High,
        RiskScore.VeryHigh,
        "MISSING",
    ];
    return scores.includes(value);
}
export { isRiskScore };
