import { Check, DotsHorizontal, X } from "@ignite-analytics/icons";
import { Chip, Grid2, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { RequirementItem, RequirementStatus } from "@/components/Pages/ProfileRequirements";
import { requirementRowEstimatedWidths } from "@/components/Pages/ProfileRequirements/constants";

interface SubRequirementRowProps {
    item: RequirementItem;
}

const SubRequirementRow: React.FC<SubRequirementRowProps> = ({ item }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [modal, setModal] = useState<
        "add-contact" | "upload-code-of-conduct" | "upload-certification" | "add-field" | "send-reminder" | null
    >(null);
    console.log(modal);

    const getStatusIcon = (status: RequirementStatus) => {
        const isFulfilled = status === "Fulfilled";
        return isFulfilled ? (
            <Chip
                label={
                    <Stack>
                        <Check />
                    </Stack>
                }
                size="small"
                color="success"
                sx={{ padding: 1 }}
            />
        ) : (
            <Chip
                label={
                    <Stack>
                        <X />
                    </Stack>
                }
                size="small"
                color="error"
                sx={{ padding: 1 }}
            />
        );
    };

    const getMenuItems = () => {
        switch (item.type) {
            case "Fields":
                return (
                    <MenuItem onClick={() => setModal("add-field")}>
                        <FormattedMessage defaultMessage="Add manually" />
                    </MenuItem>
                );
            case "Contact":
                return (
                    <MenuItem onClick={() => setModal("add-contact")}>
                        <FormattedMessage defaultMessage="Add contact" />
                    </MenuItem>
                );
            case "CodeOfConduct":
                return (
                    <MenuItem onClick={() => setModal("upload-code-of-conduct")}>
                        <FormattedMessage defaultMessage="Upload" />
                    </MenuItem>
                );
            case "Certification":
                return (
                    <MenuItem onClick={() => setModal("upload-certification")}>
                        <FormattedMessage defaultMessage="Upload" />
                    </MenuItem>
                );
            case "Questionnaire":
                return (
                    <MenuItem onClick={() => setModal("send-reminder")}>
                        <FormattedMessage defaultMessage="Send reminder" />
                    </MenuItem>
                );
        }
    };
    return (
        <Grid2
            container
            spacing={2}
            sx={{ padding: 1, borderTop: 1, borderColor: "divider" }}
            display="flex"
            alignItems="center"
        >
            <Grid2 size={requirementRowEstimatedWidths.initial} />
            <Grid2 size={requirementRowEstimatedWidths.name}>
                <Typography variant="textSm">{item.name}</Typography>
            </Grid2>
            <Grid2 size={requirementRowEstimatedWidths.status} textAlign="center">
                {getStatusIcon(item.status)}
            </Grid2>
            <Grid2 size={requirementRowEstimatedWidths.types}>
                <Chip key={item.type} label={item.type} size="small" />
            </Grid2>
            <Grid2 size={requirementRowEstimatedWidths.owner + requirementRowEstimatedWidths.date} />
            <Grid2 size={requirementRowEstimatedWidths.actions} textAlign="right">
                <IconButton size="small" onClick={handleClick} disabled={item.status === "Fulfilled"}>
                    <DotsHorizontal />
                </IconButton>
                <Menu id="sub-requirement-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {getMenuItems()}
                </Menu>
            </Grid2>
        </Grid2>
    );
};

export default SubRequirementRow;
