import { DotsVertical } from "@ignite-analytics/icons";
import { Card, CardContent, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

export interface Contact {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    position?: string;
}

interface ContactCardProps {
    contact: Contact;
    onEdit: (contact: Contact) => void;
    onDelete: (contactId: string) => void;
}

export const ContactCard: React.FC<ContactCardProps> = ({ contact, onEdit, onDelete }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        onEdit(contact);
        handleClose();
    };

    const handleDelete = () => {
        onDelete(contact.id);
        handleClose();
    };

    return (
        <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="textSm" fontWeight={500}>
                        {contact.firstName} {contact.lastName}
                    </Typography>
                    <IconButton
                        aria-label="more"
                        id="contact-menu-button"
                        aria-controls={open ? "contact-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        size="small"
                    >
                        <DotsVertical />
                    </IconButton>
                    <Menu
                        id="contact-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "contact-menu-button",
                        }}
                    >
                        <MenuItem onClick={handleEdit}>
                            <FormattedMessage defaultMessage="Edit" />
                        </MenuItem>
                        <MenuItem onClick={handleDelete}>
                            <FormattedMessage defaultMessage="Delete" />
                        </MenuItem>
                    </Menu>
                </Stack>
                <Stack>
                    {contact.position && (
                        <Typography variant="textSm" color="text.secondary">
                            {contact.position}
                        </Typography>
                    )}
                    <Typography variant="textSm">{contact.email}</Typography>
                    <Typography variant="textSm">{contact.phoneNumber}</Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};
