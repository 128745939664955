import { gql, useMutation } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
    AppRoutes_GetSupplierDocument,
    DocumentsList_SupplierFileFragment,
    UpdateSupplierFileMetadataInput,
} from "@/gql/graphql";
import { DocumentTypeOption, documentTypes } from "@/lib/files";
import { useAlert } from "@/providers";

interface DocumentEditModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    file: DocumentsList_SupplierFileFragment;
}

const DocumentEditModal_UpdateFileMutation = gql(`
    mutation DocumentEditModal_UpdateFileMutation($input: UpdateSupplierFileMetadataInput!) {
        updateSupplierFileMetadata(input: $input) {
            fileMetaJson
        }
    }
`);
export const DocumentEditModal = ({ open, setOpen, file }: DocumentEditModalProps) => {
    const metaJson = file.metaJson ? JSON.parse(file.metaJson) : {};
    const currentDocType = metaJson.doctype;
    const currentExpiryDate = metaJson.expirydate && metaJson.expirydate !== "" ? metaJson.expirydate : undefined;

    const [expiryDate, setExpiryDate] = useState<Date | null>(
        currentExpiryDate ? dayjs(currentExpiryDate).toDate() : null
    );
    const [documentType, setDocumentType] = useState<DocumentTypeOption | null>(
        currentDocType ? documentTypes.find((type) => type.tag === currentDocType) ?? null : null
    );

    useEffect(() => {
        if (open) {
            setExpiryDate(currentExpiryDate ? dayjs(currentExpiryDate).toDate() : null);
            setDocumentType(currentDocType ? documentTypes.find((type) => type.tag === currentDocType) ?? null : null);
        }
    }, [open, currentDocType, currentExpiryDate]);

    const [updateFileMetadata, { loading }] = useMutation(DocumentEditModal_UpdateFileMutation);

    const isDocTypeChanged = documentType?.tag !== currentDocType;
    const isExpiryDateChanged = expiryDate?.toISOString() !== currentExpiryDate;

    const handleClose = () => {
        setOpen(false);
    };

    const isChanged = isDocTypeChanged || isExpiryDateChanged;

    const { alertUser } = useAlert();

    const handleSaveChanges = () => {
        const input: UpdateSupplierFileMetadataInput = {
            supplierId: file.supplierId,
            fileName: file.name,
        };

        if (isDocTypeChanged) {
            input.documentType = documentType?.tag;
        }

        if (isExpiryDateChanged) {
            input.expiresAt = expiryDate?.toISOString() ?? ""; // we are possibly removing an expiration
        }

        updateFileMetadata({
            variables: { input },
            refetchQueries: [AppRoutes_GetSupplierDocument],
        })
            .then(() => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "Document updated successfully",
                        description: "Snackbar success message for updating supplier document",
                    }),
                    severity: "success",
                });
                track("Supplier Profile: Updated Document", {
                    currentDocType,
                    currentExpiryDate,
                    supplierId: file.supplierId,
                    newDocumentType: documentType?.tag,
                    newExpiryDate: expiryDate?.toISOString(),
                });
                handleClose();
            })
            .catch(() => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "Error updating document",
                        description: "Snackbar error message for updating supplier document",
                    }),
                    severity: "error",
                });
            });
    };

    const { formatMessage } = useIntl();

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        <FormattedMessage defaultMessage="Edit document" />
                    </Typography>
                    <Button color="ghostGray" onClick={handleClose} sx={{ minWidth: "auto", p: 1 }}>
                        <X />
                    </Button>
                </Stack>
                <Typography variant="textSm" color="textTextHelper" fontWeight={400}>
                    <FormattedMessage defaultMessage="Edit document type and expiry date" />
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Stack spacing={3} sx={{ mt: 2 }}>
                    <Divider />
                    <Stack spacing={5}>
                        <Stack spacing={1}>
                            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                                <Autocomplete
                                    options={documentTypes}
                                    value={documentType ?? undefined}
                                    size="small"
                                    onChange={(_, newValue) => setDocumentType(newValue)}
                                    getOptionLabel={(option) => option.name}
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={<FormattedMessage defaultMessage="Document type" />}
                                            placeholder={formatMessage({
                                                defaultMessage: "Select a document type",
                                            })}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.tag}>
                                            <Stack>
                                                <Typography>{option.name.split("-")[0]}</Typography>
                                                {option.name.includes("-") && (
                                                    <Typography variant="textXs" color="textTextHelper">
                                                        {option.name.split("-")[1]}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Box>
                                    )}
                                    sx={{ width: "70%" }}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={<FormattedMessage defaultMessage="Expiry date" />}
                                        value={expiryDate ? dayjs(expiryDate) : null}
                                        onChange={(newValue) => {
                                            if (!newValue || newValue.isValid()) {
                                                setExpiryDate(newValue?.toDate() ?? null);
                                            }
                                        }}
                                        format="DD/MM/YYYY"
                                        slotProps={{
                                            textField: {
                                                sx: { width: "40%" },
                                                InputProps: {
                                                    sx: {
                                                        "& .MuiInputAdornment-root": {
                                                            order: -1,
                                                            marginLeft: 0,
                                                        },
                                                    },
                                                },
                                            },
                                            actionBar: {
                                                actions: ["clear"],
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button
                    variant="contained"
                    disabled={loading || !isChanged || !documentType}
                    onClick={handleSaveChanges}
                >
                    <FormattedMessage defaultMessage="Save changes" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
