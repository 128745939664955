import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Plus } from "@ignite-analytics/icons";
import { Button, Card, CardContent, Chip, Skeleton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { Documents_SupplierFragment } from "@/gql/graphql";
import { filterUnreviewedDocuments } from "@/lib/files";

import { DocumentsList } from "./DocumentsList";
import { UploadModal } from "./UploadModal";

type Props = {
    isEditor: boolean;
    supplier: Documents_SupplierFragment;
    refetch: () => void;
    loading: boolean;
};

graphql(`
    fragment Documents_Supplier on Supplier {
        id
        files {
            ...DocumentsList_SupplierFile
        }
        assessmentsFiles {
            ...DocumentsList_AssessmentsFile
        }
    }
`);

export const Documents: React.FC<Props> = ({ isEditor, supplier, refetch, loading }) => {
    const [showAllDocuments, setShowAllDocuments] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);

    const hasInboxConcept = useFeatureToggle("supplier-profile-inbox-concept", false);

    const documentsSorted = [...supplier.files, ...supplier.assessmentsFiles].sort((a, b) => {
        const createdAtA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
        const createdAtB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
        return createdAtB - createdAtA;
    });

    const supplierDocumentsSorted = supplier?.files
        ? [...supplier.files].sort((a, b) => {
              const createdAtA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
              const createdAtB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
              return createdAtB - createdAtA;
          })
        : [];

    const navigate = useNavigate();

    const { numberOfUnreviewedFiles } = filterUnreviewedDocuments(
        supplier?.files ?? [],
        supplier?.assessmentsFiles ?? []
    );

    const showShowMoreButton = hasInboxConcept ? supplierDocumentsSorted.length > 3 : documentsSorted.length > 3;
    const documentsToShow = showAllDocuments ? documentsSorted : documentsSorted.slice(0, 3);
    const supplierDocumentsToShow = showAllDocuments ? supplierDocumentsSorted : supplierDocumentsSorted.slice(0, 3);

    return (
        <Stack spacing={1}>
            <Stack spacing={2} py={2}>
                {hasInboxConcept ? (
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="textXl" fontWeight={500}>
                                <FormattedMessage defaultMessage="Documents" description="Documents header" />
                            </Typography>
                            {hasInboxConcept && supplierDocumentsSorted.length > 0 && (
                                <Chip
                                    label={`${supplierDocumentsSorted.length}`}
                                    size="small"
                                    clickable={false}
                                    color="neutral"
                                />
                            )}
                            {!hasInboxConcept && documentsSorted.length > 0 && (
                                <Chip
                                    label={`${documentsSorted.length}`}
                                    size="small"
                                    clickable={false}
                                    color="neutral"
                                />
                            )}
                            {numberOfUnreviewedFiles > 0 && hasInboxConcept && (
                                <Chip
                                    variant="link"
                                    color="primary"
                                    size="small"
                                    label={`${numberOfUnreviewedFiles} to review`}
                                    onClick={() => {
                                        navigate(`/supplier-profile/${supplier.id}/inbox?tab=documents`);
                                    }}
                                />
                            )}
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            <NoPermissionTooltip hasPermission={isEditor}>
                                <Button
                                    color="ghostPrimary"
                                    size="2xsmall"
                                    onClick={() => setOpenUpload(true)}
                                    startIcon={<Plus />}
                                    disabled={!isEditor}
                                >
                                    <FormattedMessage defaultMessage="Add new" />
                                </Button>
                            </NoPermissionTooltip>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="textXl" fontWeight={500}>
                                <FormattedMessage defaultMessage="Documents" description="Documents header" />
                            </Typography>
                            {documentsSorted.length > 0 && (
                                <Chip
                                    label={`${documentsSorted.length}`}
                                    size="xsmall"
                                    clickable={false}
                                    color="neutral"
                                />
                            )}
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            <NoPermissionTooltip hasPermission={isEditor}>
                                <Button
                                    color="ghostPrimary"
                                    size="2xsmall"
                                    onClick={() => setOpenUpload(true)}
                                    startIcon={<Plus />}
                                    disabled={!isEditor}
                                >
                                    <FormattedMessage defaultMessage="Add new" />
                                </Button>
                            </NoPermissionTooltip>
                        </Stack>
                    </Stack>
                )}
                {hasInboxConcept
                    ? supplierDocumentsSorted.length > 0 && (
                          <DocumentsList files={supplierDocumentsToShow} supplierId={supplier.id} isEditor={isEditor} />
                      )
                    : documentsSorted.length > 0 && (
                          <DocumentsList files={documentsToShow} supplierId={supplier.id} isEditor={isEditor} />
                      )}
                {loading ? (
                    <Card>
                        <CardContent>
                            <Stack direction="row" justifyContent="space-between">
                                <Stack spacing={2}>
                                    <Skeleton width="171px" height="17px" sx={{ borderRadius: 0.5 }} />
                                    <Skeleton width="365px" height="17px" sx={{ borderRadius: 0.5 }} />
                                </Stack>
                                <Skeleton width="126px" height="40px" sx={{ borderRadius: 0.5 }} />
                            </Stack>
                        </CardContent>
                    </Card>
                ) : hasInboxConcept ? (
                    supplierDocumentsSorted.length === 0 ? (
                        <Card sx={{ padding: 2, borderRadius: 1 }}>
                            <Typography variant="textSm" color="primary">
                                <FormattedMessage defaultMessage="No documents found" />
                            </Typography>
                        </Card>
                    ) : null
                ) : documentsSorted.length === 0 ? (
                    <Card sx={{ padding: 2, borderRadius: 1 }}>
                        <Typography variant="textSm" color="primary">
                            <FormattedMessage defaultMessage="No documents found" />
                        </Typography>
                    </Card>
                ) : null}
                {showShowMoreButton && !showAllDocuments && (
                    <Button color="secondary" size="xsmall" onClick={() => setShowAllDocuments(true)}>
                        <FormattedMessage defaultMessage="Show all" />
                    </Button>
                )}
                <UploadModal
                    open={openUpload}
                    onClose={() => setOpenUpload(false)}
                    supplierId={supplier.id}
                    refetch={refetch}
                />
            </Stack>
        </Stack>
    );
};
