import { Button, Chip, Grid2, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const mockQuestionnaires = [
    { id: "1", name: "Transparency Act 2025", status: "not_sent" },
    { id: "2", name: "Transparency Act 2025", status: "not_sent" },
];

export const QuestionnairesSection = () => {
    return (
        <Paper sx={{ p: 2 }} elevation={1}>
            <Grid2 container spacing={2}>
                <Grid2 size={12} container justifyContent="space-between" alignItems="center">
                    <Grid2>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Questionnaires" />
                        </Typography>
                    </Grid2>
                    <Grid2>
                        <Button variant="outlined" color="secondary" size="small">
                            <FormattedMessage defaultMessage="Add questionnaire" />
                        </Button>
                    </Grid2>
                </Grid2>

                {mockQuestionnaires.map((quest) => (
                    <Grid2 size={12} key={quest.id} container justifyContent="space-between" alignItems="center">
                        <Grid2>
                            <Typography variant="textMd" fontWeight={500}>
                                {quest.name}
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Chip label={<FormattedMessage defaultMessage="Not sent" />} color="error" size="small" />
                        </Grid2>
                    </Grid2>
                ))}
            </Grid2>
        </Paper>
    );
};
