import { getRegion } from "@ignite-analytics/locale";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import { SupplierCustomFieldType } from "@/gql/graphql";

import { countryCodes } from "../countryCode";
import { getNACELabel } from "../NaceField/helpers";
import { NACEOptions } from "../NaceField/NACEOptions";

interface EditFieldProps {
    id: string;
    value: string | string[] | number; // as dataJson is any when marshalled
    type: SupplierCustomFieldType | "onboarding";
    onFieldChange: (id: string, value: string) => void;
    selectOptions?: string[];
}

export const EditField: React.FC<EditFieldProps> = ({ id, value, onFieldChange, type, selectOptions }) => {
    const region = getRegion();

    switch (type) {
        case SupplierCustomFieldType.Select:
            // check that value have to be type of string
            if (typeof value !== "string") {
                value = "";
            }

            return (
                <Select
                    type="text"
                    size="small"
                    defaultValue={value}
                    onChange={(fieldVal) => {
                        onFieldChange(id, fieldVal.target.value);
                    }}
                    fullWidth
                >
                    <MenuItem value="">
                        <FormattedMessage defaultMessage="Clear value" />
                    </MenuItem>
                    {selectOptions?.map((s: string) => {
                        return (
                            <MenuItem value={s} key={s}>
                                {s}
                            </MenuItem>
                        );
                    })}
                </Select>
            );
        case SupplierCustomFieldType.Nace:
            return (
                <Autocomplete
                    onChange={(_e, value) => onFieldChange && onFieldChange(id, value?.code ?? "")}
                    options={NACEOptions}
                    getOptionLabel={(option) => `${option.code} - ${getNACELabel(option)}`}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                    value={NACEOptions.find((nace) => nace.code === value)}
                    filterOptions={(options, params) => {
                        const inputValue = params.inputValue.toLowerCase();
                        return options.filter((option) => {
                            const codeMatches = option.code.toLowerCase().includes(inputValue);
                            const nameMatches = getNACELabel(option).toLowerCase().includes(inputValue);
                            return codeMatches || nameMatches;
                        });
                    }}
                    slotProps={{ paper: { sx: { wordBreak: "pre-line" } } }}
                    ListboxProps={{
                        sx: {
                            "& .MuiAutocomplete-option": {
                                height: "100%",
                            },
                        },
                    }}
                />
            );
        case SupplierCustomFieldType.Boolean:
            if (typeof value !== "string") {
                value = "false";
            }
            return (
                <Select
                    size="small"
                    defaultValue={value}
                    onChange={(fieldVal) => onFieldChange(id, fieldVal.target.value)}
                    fullWidth
                >
                    <MenuItem value="true">
                        <FormattedMessage defaultMessage="Yes" />
                    </MenuItem>
                    <MenuItem value="false">
                        <FormattedMessage defaultMessage="No" />
                    </MenuItem>
                </Select>
            );
        default:
            if (id === "country") {
                return (
                    <Autocomplete
                        onChange={(_e, value) => onFieldChange && onFieldChange(id, value?.code ?? "")}
                        options={countryCodes}
                        getOptionLabel={(option) =>
                            `${option.code} - ${region == "no-NB" ? option.name_no : option.name_en}`
                        }
                        renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                        value={countryCodes.find((country) => country.code === value)}
                        filterOptions={(options, params) => {
                            const inputValue = params.inputValue.toLowerCase();
                            return options.filter((option) => {
                                const codeMatches = option.code.toLowerCase().includes(inputValue);
                                const nameMatches = (region === "no-NB" ? option.name_no : option.name_en)
                                    .toLowerCase()
                                    .split(" ")
                                    .some((word) => word.startsWith(inputValue));
                                return codeMatches || nameMatches;
                            });
                        }}
                    />
                );
            }
            return (
                <TextField
                    size="small"
                    variant="outlined"
                    type={
                        type === SupplierCustomFieldType.Spend || type == SupplierCustomFieldType.MonetaryAmount
                            ? "number"
                            : type
                    }
                    defaultValue={
                        type === SupplierCustomFieldType.Date && typeof value === "string"
                            ? dayjs(value).format("YYYY-MM-DD")
                            : value
                    }
                    onChange={(fieldVal) => onFieldChange && onFieldChange(id, fieldVal.target.value)}
                    fullWidth
                />
            );
    }
};
