import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { DotsHorizontal } from "@ignite-analytics/icons";
import { Card, Grid, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FinancialsIcon } from "@/assets/FinancialsIcon";
import { NoData } from "@/components/Common/NoData";

import { useFinancialsData } from "./hooks";
import { PotentialChart } from "./PotentialChart";
import { RevenueEbitChart } from "./RevenueEbitChart";
import { RiskChart } from "./RiskChart";

interface FinancialsPageProps {
    supplierId: string;
}

export const FinancialsPage: React.FC<FinancialsPageProps> = ({ supplierId }) => {
    const { formatMessage } = useIntl();

    const useNewEnrichment = useFeatureToggle("newEnrichment", false);

    const {
        loading,
        financialData,
        chartOptions: options,
        risk,
        potential,
        hasFinancialData,
        hasLatestFinancialStatement,
        hasSavingsEstimate,
        error,
    } = useFinancialsData(supplierId, useNewEnrichment);

    return (
        <Stack gap={3} width="100%">
            <Stack spacing={2} direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <FinancialsIcon />
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Financials" />
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                    {false && ( //add back when we have export functionality
                        <IconButton color="secondary" size="small">
                            <DotsHorizontal fontSize="small" />
                        </IconButton>
                    )}
                </Stack>
            </Stack>

            {loading ? (
                <Skeleton variant="rectangular" height={400} />
            ) : !hasFinancialData ? (
                <Card sx={{ padding: 2 }}>
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Financial data" />
                    </Typography>
                    <NoData message={formatMessage({ defaultMessage: "No financial data available" })} height="340px" />
                </Card>
            ) : (
                <RevenueEbitChart financialData={financialData} config={options} />
            )}
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    {loading ? (
                        <Skeleton variant="rectangular" height={500} />
                    ) : hasLatestFinancialStatement ? (
                        <RiskChart data={risk} />
                    ) : (
                        <Card sx={{ padding: 2 }}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="textXl" fontWeight={500}>
                                    <FormattedMessage defaultMessage="Risk" />
                                </Typography>
                            </Stack>
                            <NoData
                                message={formatMessage({ defaultMessage: "Could not calculate risk" })}
                                height="452px"
                            />
                        </Card>
                    )}
                </Grid>
                <Grid item md={12} lg={6}>
                    {loading ? (
                        <Skeleton variant="rectangular" height={500} />
                    ) : !hasSavingsEstimate || error ? (
                        <Card sx={{ padding: 2 }}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="textXl" fontWeight={500}>
                                    <FormattedMessage defaultMessage="Potential" />
                                </Typography>
                            </Stack>
                            <NoData
                                title={formatMessage({
                                    defaultMessage: "Could not calculate potential",
                                })}
                                message={formatMessage({
                                    defaultMessage:
                                        "To get a potential score, the supplier needs recent spend. If we do not find enough relevant factors, we don't calculate the potential score.",
                                })}
                                height="452px"
                            />
                        </Card>
                    ) : (
                        <PotentialChart data={potential} />
                    )}
                </Grid>
            </Grid>
        </Stack>
    );
};
