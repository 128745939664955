import { NACEOption } from "./NACEOptions";

export function getNACELabel(option: NACEOption | null) {
    if (!option) return "";
    const locale = navigator.language;

    if (locale === "nb") {
        return `${option.name_no}`;
    }
    return `${option.name_en}`;
}
