import { DotsHorizontal } from "@ignite-analytics/icons";
import { Card, IconButton, Skeleton, Stack, Typography, Grid } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FinancialsIcon } from "@/assets/FinancialsIcon";
import { Valu8Logo } from "@/assets/Valu8Logo";
import { NoData } from "@/components/Common/NoData";

import { useFinancialsData } from "./hooks";
import { PotentialChart } from "./PotentialChart";
import { RevenueEbitChart } from "./RevenueEbitChart";
import { RiskChart } from "./RiskChart";

interface FinancialsPageProps {
    supplierId: string;
}

export const FinancialsPage: React.FC<FinancialsPageProps> = ({ supplierId }) => {
    const { formatMessage } = useIntl();

    const {
        loading,
        noMatch,
        financialData,
        chartOptions: options,
        risk,
        potential,
        hasFinancialData,
        hasLatestFinancialStatement,
        hasSavingsEstimate,
        error,
    } = useFinancialsData(supplierId);

    return (
        <Stack gap={3} width="100%">
            <Stack spacing={2} direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <FinancialsIcon />
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Financials" />
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                    {false && ( //add back when we have export functionality
                        <IconButton color="secondary" size="small">
                            <DotsHorizontal fontSize="small" />
                        </IconButton>
                    )}
                </Stack>
            </Stack>

            {!loading && noMatch ? (
                <Card sx={{ padding: 2 }}>
                    <NoData
                        title={formatMessage({ defaultMessage: "No supplier match found" })}
                        message={formatMessage({
                            defaultMessage:
                                "We couldn't find this supplier in our enrichment database. This might be because the supplier isn't in a covered country or hasn't been processed yet. Please check that the name, country, and organizational number are correct.",
                        })}
                        height="340px"
                    />
                </Card>
            ) : (
                <>
                    {loading ? (
                        <Skeleton variant="rectangular" height={400} />
                    ) : !hasFinancialData || financialData.filter((d) => d.revenue || d.ebitMargin).length == 0 ? (
                        <Card sx={{ padding: 2 }}>
                            <Typography variant="textXl" fontWeight={500}>
                                <FormattedMessage defaultMessage="Financial data" />
                            </Typography>
                            <NoData
                                title={formatMessage({ defaultMessage: "No financial data available" })}
                                message={formatMessage({
                                    defaultMessage:
                                        "Our enrichment provider did not return revenue or EBIT margin for this supplier",
                                })}
                                height="340px"
                            />
                        </Card>
                    ) : (
                        <RevenueEbitChart financialData={financialData} config={options} />
                    )}
                    <Grid container spacing={2}>
                        <Grid item md={12} lg={6}>
                            {loading ? (
                                <Skeleton variant="rectangular" height={500} />
                            ) : hasLatestFinancialStatement ? (
                                <RiskChart data={risk} logo={<Valu8Logo />} />
                            ) : (
                                <Card sx={{ padding: 2 }}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="textXl" fontWeight={500}>
                                            <FormattedMessage defaultMessage="Risk" />
                                        </Typography>
                                    </Stack>
                                    <NoData
                                        title={formatMessage({ defaultMessage: "Risk score unavailable" })}
                                        message={formatMessage({
                                            defaultMessage:
                                                "Insufficient enrichment data to calculate a risk score for this supplier.",
                                        })}
                                        height="452px"
                                    />
                                </Card>
                            )}
                        </Grid>
                        <Grid item md={12} lg={6}>
                            {loading ? (
                                <Skeleton variant="rectangular" height={500} />
                            ) : !hasSavingsEstimate || error ? (
                                <Card sx={{ padding: 2 }}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="textXl" fontWeight={500}>
                                            <FormattedMessage defaultMessage="Potential" />
                                        </Typography>
                                    </Stack>
                                    <NoData
                                        title={formatMessage({
                                            defaultMessage: "Potential score unavailable",
                                        })}
                                        message={formatMessage({
                                            defaultMessage:
                                                "Recent spend data is required to calculate a potential score. The score is only shown when all required factors are available.",
                                        })}
                                        height="452px"
                                    />
                                </Card>
                            ) : (
                                <PotentialChart data={potential} logo={<Valu8Logo />} />
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Stack>
    );
};
