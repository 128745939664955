import { useQuery } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import * as Sentry from "@sentry/react";
import React, { useEffect, useMemo } from "react";
import { createBrowserRouter, NavigateFunction, Outlet, RouterProvider } from "react-router-dom";

import { AssessmentsPage } from "@/components/Pages/Assessments";
import { EmissionsPage } from "@/components/Pages/Emissions";
import { FinancialsPage } from "@/components/Pages/Financials";
import { InboxPage } from "@/components/Pages/Inbox";
import { OnboardingPage } from "@/components/Pages/Onboarding";
import { OverviewPage } from "@/components/Pages/Overview";
import { useSupplierCountryLanguage } from "@/components/Pages/Overview/SideColumn/useSupplierCountryLanguage";
import ProfileRequirements from "@/components/Pages/ProfileRequirements";
import Requirements from "@/components/Pages/Requirements";
import RequirementsDetail from "@/components/Pages/Requirements/RequirementsDetail";
import { SelectSupplierPage } from "@/components/Pages/SelectSupplier";
import { SpendPage } from "@/components/Pages/Spend";
import { ProfileLayout } from "@/components/ProfileLayout";
import { graphql } from "@/gql";
import { ElectricityBasis } from "@/gql/graphql";
import { useAlert } from "@/providers";
import { NavigationContextProvider } from "@/providers/NavigationContext";
import { PollingContextProvider } from "@/providers/PollingContext";

interface SupplierProfileRouteParams {
    navigate?: NavigateFunction;
}

const getNormalizedSupplier = graphql(`
    query AppRoutes_GetSupplier(
        $input: GetSupplierInput!
        $electricityBasis: ElectricityBasis!
        $startYear: Int!
        $endYear: Int!
        $language: SupplierCountryLanguage
    ) {
        getSupplier(input: $input) {
            supplier {
                id
                ...Onboarding_Supplier
                ...OverviewPage_Supplier
                ...Layout_Supplier
            }
        }
    }
`);

export const AppRoutes: React.FC<SupplierProfileRouteParams> = ({ navigate }) => {
    const pathSegments = window.location.pathname.split("/");
    const id = pathSegments[2];
    useEffect(() => {
        if (id && id !== "select") {
            track("Supplier Profile: Viewed Supplier");
        }
    }, [id]);

    const { alertUser } = useAlert();
    const getNormalized = useMemo(() => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get("getNormalized") === "true";
    }, []);

    const currentYear = new Date().getFullYear();
    const language = useSupplierCountryLanguage();
    const { data, refetch, loading, startPolling, stopPolling } = useQuery(getNormalizedSupplier, {
        errorPolicy: "all",
        variables: {
            input: { id: id ?? "", getNormalized: getNormalized },
            electricityBasis: ElectricityBasis.MarketBased,
            startYear: currentYear - 4,
            endYear: currentYear,
            language,
        },
        fetchPolicy: "cache-and-network",
        skip: !id,
        onError: (error) => {
            if (data?.getSupplier.supplier !== null) {
                alertUser({ value: `Could not get all data for supplier: ${error.message}`, severity: "error" });
            } else {
                alertUser({ value: "Could not get supplier data", severity: "error" });
            }
            Sentry.captureException(error, {
                tags: { app: "supplier-profile-app", message: "Failed to get federated supplier" },
            });
        },
    });
    const router = createBrowserRouter([
        {
            id: "root",
            path: "/",
            element: (
                <NavigationContextProvider navigateFunc={navigate}>
                    <Outlet />
                </NavigationContextProvider>
            ),
            children: [
                {
                    path: "supplier-profile",
                    element: <ProfileLayout supplier={data?.getSupplier.supplier} loading={loading} />,
                    children: [
                        {
                            path: "select/",
                            element: <SelectSupplierPage />,
                        },
                        {
                            path: ":id/onboarding",
                            element: <OnboardingPage supplier={data?.getSupplier.supplier} />,
                        },
                        {
                            path: ":id/overview",
                            element: (
                                <PollingContextProvider startPolling={startPolling} stopPolling={stopPolling}>
                                    <OverviewPage
                                        supplier={data?.getSupplier.supplier}
                                        loading={loading}
                                        refetch={refetch}
                                    />
                                </PollingContextProvider>
                            ),
                        },
                        {
                            path: ":id/spend",
                            element: <SpendPage supplier={data?.getSupplier.supplier} />,
                        },
                        {
                            path: ":id/emissions",
                            element: <EmissionsPage supplierId={data?.getSupplier.supplier.id ?? ""} />,
                        },
                        {
                            path: ":id/financials",
                            element: <FinancialsPage supplierId={data?.getSupplier.supplier.id ?? ""} />,
                        },
                        {
                            path: ":id/assessments",
                            element: <AssessmentsPage supplierId={data?.getSupplier.supplier.id ?? ""} />,
                        },
                        {
                            path: ":id/inbox",
                            element: (
                                <PollingContextProvider startPolling={startPolling} stopPolling={stopPolling}>
                                    <InboxPage supplier={data?.getSupplier.supplier} loading={loading} />
                                </PollingContextProvider>
                            ),
                        },
                        {
                            path: ":id/requirements",
                            element: <ProfileRequirements />,
                        },
                    ],
                },
                {
                    path: "requirements",
                    element: <Requirements />,
                },
                {
                    path: "requirements/:id/",
                    element: <RequirementsDetail />,
                },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
};
