import { LocationPin, SquareBolt } from "@ignite-analytics/icons";
import { Card, CardContent, Divider, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage } from "react-intl";

import { EmissionsIcon } from "@/assets/EmissionsIcon";
import { graphql } from "@/gql";
import { ElectricityBasis, EmissionsDevelopmentChart_SupplierFragment, SupplierEmissionsForYear } from "@/gql/graphql";

import { EmissionFactors } from "./EmissionFactors";
import { EmissionsDevelopmentChart } from "./EmissionsDevelopmentChart";

graphql(`
    fragment EmissionsDevelopmentChart_Supplier on Supplier {
        id
        emissions(
            emissionsInput: {
                electricityBasis: $electricityBasis
                startYear: $startYear
                endYear: $endYear
                filter: { businessUnitIds: [], spendCategoryIds: [] }
            }
        ) {
            ...EmissionsDevelopmentChart_TotalEmissions
        }
    }
`);

interface EmissionsDevelopmentCardProps {
    supplier?: EmissionsDevelopmentChart_SupplierFragment;
    loading?: boolean;
    electricityBasis: ElectricityBasis;
    setElectricityBasis: (basis: ElectricityBasis) => void;
    factors: SupplierEmissionsForYear[] | undefined | null;
    emissionFactorsYear: number;
    setEmissionFactorsYear: Dispatch<SetStateAction<number>>;
}

export const EmissionsDevelopmentCard: React.FC<EmissionsDevelopmentCardProps> = ({
    supplier,
    loading,
    electricityBasis,
    setElectricityBasis,
    factors,
    emissionFactorsYear,
    setEmissionFactorsYear,
}) => {
    const [isBarHovered, setIsBarHovered] = useState(false);
    const handleMouseOver = () => setIsBarHovered(true);
    const handleMouseOut = () => setIsBarHovered(false);

    return (
        <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <EmissionsIcon />
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Emissions" />
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <ToggleButtonGroup
                        size="2xsmall"
                        value={electricityBasis}
                        onChange={() => {
                            if (electricityBasis === ElectricityBasis.LocationBased) {
                                setElectricityBasis(ElectricityBasis.MarketBased);
                            } else {
                                setElectricityBasis(ElectricityBasis.LocationBased);
                            }
                        }}
                    >
                        <Tooltip
                            title={
                                <FormattedMessage
                                    defaultMessage="Location-based estimates for electricity emissions"
                                    description="Tooltip for button group for picking electricity type"
                                />
                            }
                        >
                            <ToggleButton value={ElectricityBasis.LocationBased}>
                                <LocationPin sx={{ fontSize: "20px" }} />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip
                            title={
                                <FormattedMessage
                                    defaultMessage="Market-based estimates for electricity emissions"
                                    description="Tooltip for button group for picking electricity type"
                                />
                            }
                        >
                            <ToggleButton value={ElectricityBasis.MarketBased}>
                                <SquareBolt sx={{ fontSize: "20px" }} />
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>
                </Stack>
            </Stack>
            <Card>
                <CardContent onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    <Stack spacing={1} marginBottom={3}>
                        <Typography variant="textXl" fontWeight={500}>
                            <FormattedMessage defaultMessage="Emission development" />
                        </Typography>
                        <Typography variant="textSm" sx={{ opacity: 0.6 }}>
                            <FormattedMessage defaultMessage="in tCO₂e" />
                        </Typography>
                    </Stack>
                    <EmissionsDevelopmentChart
                        emissions={supplier?.emissions ?? []}
                        loading={loading}
                        isBarHovered={isBarHovered}
                    />
                </CardContent>
                <Stack padding={3} spacing={3}>
                    <Divider />
                    <EmissionFactors
                        factors={factors}
                        emissionFactorsYear={emissionFactorsYear}
                        setEmissionFactorsYear={setEmissionFactorsYear}
                    />
                </Stack>
            </Card>
        </Stack>
    );
};
