import { Stack, Typography } from "@mui/material";

import SelectSupplier from "@/components/SelectSupplier";

export const SelectSupplierPage: React.FC = () => {
    return (
        <Stack p={3}>
            <Typography variant="h6">Select a supplier:</Typography>
            <SelectSupplier />
        </Stack>
    );
};
