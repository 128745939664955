import { ArrowLeft, Trash } from "@ignite-analytics/icons";
import { Box, Button, Chip, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Navigate, useParams } from "react-router-dom";

// const getRequirement = graphql(`
//     query Requirements_GetRequirement($id: ID!) {
//         getRequirement(id: $id) {
//             requirement {
//                 id
//                 name
//                 supplierCount
//                 createdBy
//                 createdAt
//                 updatedBy
//                 updatedAt
//                 fields
//                 codeOfConduct
//                 certifications
//                 questionnaires
//             }
//         }
//     }
// `);

// Mock data for development
const mockRequirementData = {
    getRequirement: {
        requirement: {
            id: "req-123",
            name: "Environmental Compliance",
            supplierCount: 42,
            createdBy: "John Doe",
            createdAt: "2023-05-15T10:30:00Z",
            updatedBy: "Jane Smith",
            updatedAt: "2023-06-20T14:45:00Z",
            fields: ["Country", "Org Number", "Has SBTi"],
            codeOfConduct: true,
            certifications: ["ISO 9001", "ISO 14001"],
            questionnaires: ["Environmental Impact Assessment", "Sustainability Practices"],
        },
    },
};

// Mock loading and error states
const loading = false;
const error = false;

const mockSuppliers = [
    {
        id: 1,
        name: "Supplier A",
        category: "L2 category",
        businessUnit: "Business unit",
        appliedBy: "Orla Flores",
        dateApplied: "27 Jun 2023",
    },
    {
        id: 2,
        name: "Supplier B",
        category: "L2 category",
        businessUnit: "Business unit",
        appliedBy: "Orla Flores",
        dateApplied: "27 Jun 2023",
    },
];

const RequirementsDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 5,
        page: 0,
    });

    const { formatDate } = useIntl();

    // const { data, loading, error } = useQuery(getRequirement, {
    //     variables: { id: id! },
    //     skip: !id,
    // });

    if (!id) {
        return <Navigate to="/requirements" replace />;
    }

    if (loading) {
        return (
            <Box sx={{ p: 3 }}>
                <Skeleton variant="rectangular" height={400} />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">
                    <FormattedMessage defaultMessage="Error loading requirement" />
                </Typography>
            </Box>
        );
    }

    const requirement = mockRequirementData.getRequirement.requirement;

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Supplier name",
            display: "flex",
            renderHeader: () => <FormattedMessage defaultMessage="Supplier name" />,
            flex: 1,
        },
        {
            field: "category",
            headerName: "Category",
            renderHeader: () => <FormattedMessage defaultMessage="Category" />,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Chip label={params.value} size="small" />
                    <Chip label="+2" size="small" color="primary" />
                </Stack>
            ),
        },
        {
            field: "businessUnit",
            headerName: "Business unit",
            renderHeader: () => <FormattedMessage defaultMessage="Business unit" />,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Chip label={params.value} size="small" />
                    <Chip label="+2" size="small" color="primary" />
                </Stack>
            ),
        },
        {
            field: "appliedBy",
            headerName: "Applied by",
            display: "flex",
            renderHeader: () => <FormattedMessage defaultMessage="Applied by" />,
            flex: 1,
        },
        {
            field: "dateApplied",
            headerName: "Date applied",
            display: "flex",
            renderHeader: () => <FormattedMessage defaultMessage="Date applied" />,
            flex: 1,
        },
        {
            field: "actions",
            headerName: "Actions",
            renderHeader: () => <FormattedMessage defaultMessage="Actions" />,
            flex: 0.5,
            display: "flex",
            sortable: false,
            renderCell: () => (
                <IconButton size="small">
                    <Trash />
                </IconButton>
            ),
        },
    ];

    return (
        <Stack gap={4} padding={4}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row">
                    <Stack spacing={2}>
                        <IconButton
                            color="secondary"
                            size="small"
                            onClick={() => window.history.back()}
                            sx={{ border: 0, borderColor: "divider" }}
                        >
                            <ArrowLeft />
                        </IconButton>
                    </Stack>
                    <Stack>
                        <Stack direction="row" spacing={2} alignItems="center" marginBottom={2}>
                            <Typography variant="displayXs" gutterBottom>
                                {requirement?.name}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2} gap={2}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="textSm" color="text.secondary">
                                    <FormattedMessage defaultMessage="Created by" />
                                </Typography>
                                <Typography variant="textSm" fontWeight={500}>
                                    {requirement?.createdBy}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="textSm" color="text.secondary">
                                    <FormattedMessage defaultMessage="Last edited at:" />
                                </Typography>
                                <Typography variant="textSm">{formatDate(requirement?.updatedAt)}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Button variant="outlined" size="small" color="secondary">
                        <FormattedMessage defaultMessage="Edit" />
                    </Button>
                    <IconButton size="small" color="secondary">
                        <Trash />
                    </IconButton>
                </Stack>
            </Stack>

            {/* Suppliers Table */}
            <Typography variant="textLg" fontWeight={500}>
                <FormattedMessage defaultMessage="Used by" />
            </Typography>
            <Box>
                <DataGridPro
                    rows={mockSuppliers}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[5, 10, 20]}
                    disableRowSelectionOnClick
                    autoHeight
                />
            </Box>
        </Stack>
    );
};

export default RequirementsDetail;
