import { getDesignTokens, responsiveFontSizes } from "@ignite-analytics/theme";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { enUS, nbNO } from "@mui/material/locale";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useMemo } from "react";
import { IntlConfig, IntlProvider, ReactIntlErrorCode } from "react-intl";

import { ApolloProvider } from "@/lib/apollo";
import { LocaleMessages } from "@/lib/i18n";
import { AppProps } from "@/types";

import { AlertProvider } from "./Alerts";
import { CurrencyProvider } from "./CurrencyProvider/provider";
import { UserContextProvider } from "./UserContext";

type Props = {
    children?: React.ReactNode;
    messages: LocaleMessages;
} & AppProps;

function getMuiLocale(locale: string) {
    switch (locale) {
        case "nb-NO":
            return nbNO;
        default:
            return enUS;
    }
}

const onIntlError: IntlConfig["onError"] = (err) => {
    if (process.env.NODE_ENV === "production") {
        return null;
    }

    if (err.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
        return null;
    }

    console.error(err);
};

const queryClient = new QueryClient();

export const AppProvider: React.FC<Props> = ({ children, theme = "ignite-riddle", locale, messages }) => {
    const designTokens = useMemo(() => getDesignTokens(theme, "light"), [theme]);
    const localization = useMemo(() => getMuiLocale(locale), [locale]);

    return (
        <UserContextProvider>
            <ThemeProvider theme={responsiveFontSizes(createTheme(designTokens, localization, { name: theme }))}>
                <CssBaseline />
                <IntlProvider messages={messages} locale={locale} key={locale} onError={onIntlError}>
                    <QueryClientProvider client={queryClient}>
                        <ApolloProvider>
                            <AlertProvider>
                                <CurrencyProvider>{children}</CurrencyProvider>
                            </AlertProvider>
                        </ApolloProvider>
                    </QueryClientProvider>
                </IntlProvider>
            </ThemeProvider>
        </UserContextProvider>
    );
};
