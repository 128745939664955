import type { ResultOf } from "@graphql-typed-document-node/core";
import { MenuItem, Select, Stack, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { RiskChip } from "@/components/Common/StatusChip";
import { graphql } from "@/gql";
import { RiskScore } from "@/gql/graphql";

import { isRiskScore } from "./isRiskScore";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SupplierFragment = graphql(`
    fragment YourRiskEstimate_Supplier on Supplier {
        riskV3 {
            social {
                net
            }
        }
    }
`);

interface YourRiskEstimateProps {
    supplier: ResultOf<typeof SupplierFragment>;
    editing?: boolean;
    value: RiskScore | "MISSING";
    onChange(newScore: RiskScore | "MISSING"): void;
    onReasonChange(reason: string): void;
}

function YourRiskEstimate(props: YourRiskEstimateProps) {
    const { supplier, editing, value, onChange, onReasonChange } = props;
    const defaultValue = supplier.riskV3?.social?.net ?? "MISSING";

    if (editing) {
        return (
            <Stack spacing={1}>
                <Select<RiskScore | "MISSING">
                    size="medium"
                    value={value}
                    defaultValue={defaultValue}
                    onChange={(e) => {
                        if (isRiskScore(e.target.value)) {
                            onChange(e.target.value);
                        } else {
                            onChange("MISSING");
                        }
                    }}
                    displayEmpty
                    renderValue={(value) => {
                        if (value === "MISSING") {
                            return (
                                <FormattedMessage
                                    defaultMessage="Select an option"
                                    description="Select risk score dropdown placeholder"
                                />
                            );
                        }
                        return <RiskChip size="small" riskScore={value} />;
                    }}
                >
                    {[RiskScore.VeryLow, RiskScore.Low, RiskScore.Medium, RiskScore.High, RiskScore.VeryHigh].map(
                        (risk, index, arr) => (
                            <MenuItem key={risk} value={risk} divider={index === arr.length - 1}>
                                <RiskChip size="small" riskScore={risk} />
                            </MenuItem>
                        )
                    )}
                    <MenuItem value={undefined}>
                        <FormattedMessage defaultMessage="Reset status" description="Reset risk score option label" />
                    </MenuItem>
                    <MenuItem value="MISSING" sx={{ display: "none" }}></MenuItem>
                </Select>
                {value !== defaultValue && (
                    <TextField
                        multiline
                        rows={3}
                        onChange={(e) => onReasonChange(e.target.value)}
                        label={
                            <FormattedMessage
                                defaultMessage="Reason for change"
                                description="Reason for changing risk estimate text field label"
                            />
                        }
                    />
                )}
            </Stack>
        );
    }

    return <RiskChip riskScore={supplier.riskV3?.social?.net} />;
}

export { YourRiskEstimate };
