import { styled, TableCell } from "@mui/material";

export const NoWrapCell = styled(TableCell)(({ theme }) => ({
    position: "relative",
    borderBottom: `1px solid ${theme.palette.divider}`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    zIndex: 0,
}));

export const StickyHeaderCell = styled(NoWrapCell)(({ theme }) => ({
    position: "sticky",
    left: 0,
    top: 0,
    zIndex: 1,
    background: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
}));

export const StickyCell = styled(NoWrapCell)(({ theme }) => ({
    position: "sticky",
    left: 0,
    zIndex: 1,
    background: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
}));
