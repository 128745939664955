import { ShapeIcon } from "@ignite-analytics/components";
import { ChevronDown, DotsHorizontal, Shapes } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Chip,
    Grid2,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { requirementRowEstimatedWidths } from "@/components/Pages/ProfileRequirements/constants";
import SubRequirementRow from "@/components/Pages/ProfileRequirements/SubRequirementRow";
import { UnapplyRequirementDialog } from "@/components/Pages/ProfileRequirements/UnapplyRequirementDialog";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

export type RequirementStatus = "Fulfilled" | "Unfulfilled";
export type RequirementType = "Fields" | "Certification" | "CodeOfConduct" | "Questionnaire" | "Contact";

export interface RequirementItem {
    id: string;
    name: string;
    status: RequirementStatus;
    type: RequirementType;
    owner?: string;
    date?: string;
}

export interface RequirementGroup {
    id: string;
    name: string;
    status: RequirementStatus;
    types?: RequirementType[];
    owner?: string;
    date?: string;
    updatedAt?: string;
    children?: RequirementItem[];
}

const mockRequirements: RequirementGroup[] = [
    {
        id: "g1",
        name: "A supplier",
        status: "Fulfilled",
        types: ["Fields", "Certification"],
        owner: "Rafael Perez",
        date: "27 Jun 2023",
        updatedAt: "27 Jun 2023",
    },
    {
        id: "g2",
        name: "Food suppliers",
        status: "Fulfilled",
        types: ["Fields"],
        owner: "Elena Pezzetta",
        date: "27 Jun 2023",
        updatedAt: "27 Jun 2023",
    },
    {
        id: "g3",
        name: "Criticality 3",
        status: "Unfulfilled",
        types: ["Fields", "CodeOfConduct", "Questionnaire"],
        owner: "Orla Flores",
        date: "27 Jun 2023",
        updatedAt: "27 Jun 2023",
        children: [
            { id: "c3-1", name: "Org. number", status: "Unfulfilled", type: "Fields" },
            { id: "c3-2", name: "Country", status: "Unfulfilled", type: "Fields" },
            { id: "c3-3", name: "Industry", status: "Fulfilled", type: "Fields" },
            { id: "c3-4", name: "Supplier contact", status: "Fulfilled", type: "Fields" },
            { id: "c3-5", name: "Production country", status: "Unfulfilled", type: "Fields" },
            { id: "c3-6", name: "Custom field", status: "Unfulfilled", type: "Fields" },
            { id: "c3-7", name: "Code of Conduct", status: "Unfulfilled", type: "CodeOfConduct" },
            { id: "c3-8", name: "Transparency Act", status: "Fulfilled", type: "Questionnaire" },
        ],
    },
    {
        id: "g4",
        name: "Social risk",
        status: "Fulfilled",
        types: ["Fields", "Questionnaire"],
        owner: "Orla Flores",
        date: "27 Jun 2023",
        updatedAt: "27 Jun 2023",
    },
];

export const ProfileRequirementsPage: React.FC = () => {
    const outgoingNavigate = useOutgoingNavigate();
    const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>(
        mockRequirements.reduce(
            (acc, group) => {
                if (group.status === "Unfulfilled" && group.children && group.children.length > 0) {
                    acc[group.id] = true;
                }
                return acc;
            },
            {} as Record<string, boolean>
        )
    );
    const [unapplyRequirementId, setUnapplyRequirementId] = useState<string | null>(null);
    const unapplyDialogOpen = Boolean(unapplyRequirementId);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewRequirement = (groupId: string) => {
        handleClose();
        outgoingNavigate(`/requirements/${groupId}`);
        track("Supplier Profile: Navigate to requirement", {
            supplierId: groupId,
        });
    };

    const handleOpenUnapplyDialog = (requirementId: string) => {
        handleClose();
        setUnapplyRequirementId(requirementId);
    };

    const handleCloseUnapplyDialog = () => {
        setUnapplyRequirementId(null);
    };

    const handleAccordionChange = (panelId: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedGroups((prev) => ({ ...prev, [panelId]: isExpanded }));
    };

    const getStatusChip = (status: RequirementStatus) => {
        const isFulfilled = status === "Fulfilled";
        return <Chip size="small" label={status} color={isFulfilled ? "success" : "error"} />;
    };

    return (
        <Stack spacing={3} width="100%">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                    <ShapeIcon icon={<Shapes />} />
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Requirements" description="Requirements page title" />
                    </Typography>
                </Stack>
                <Button variant="contained" color="secondary">
                    <FormattedMessage
                        defaultMessage="Apply new requirements"
                        description="Button to apply requirements"
                    />
                </Button>
            </Stack>

            <Typography variant="textSm">
                <FormattedMessage
                    defaultMessage="View, edit, and unapply requirements applied to this supplier."
                    description="Requirements page subtitle"
                />
            </Typography>

            <Stack spacing={1.5}>
                {mockRequirements.map((requirement) => {
                    const isExpanded = !!expandedGroups[requirement.id];
                    return (
                        <Accordion
                            key={requirement.id}
                            expanded={isExpanded}
                            onChange={handleAccordionChange(requirement.id)}
                            elevation={1}
                            sx={{ border: 1, borderRadius: 1, borderColor: "divider" }}
                        >
                            <AccordionSummary
                                aria-controls={`${requirement.id}-content`}
                                id={`${requirement.id}-header`}
                            >
                                <Grid2
                                    container
                                    width="100%"
                                    spacing={2}
                                    display="flex"
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Grid2 size={requirementRowEstimatedWidths.initial}>
                                        <IconButton
                                            size="small"
                                            sx={{ transform: isExpanded ? "rotate(180deg)" : undefined }}
                                        >
                                            <ChevronDown />
                                        </IconButton>
                                    </Grid2>
                                    <Grid2 size={requirementRowEstimatedWidths.name}>
                                        <Typography variant="textSm" sx={{ fontWeight: 500 }}>
                                            {requirement.name}
                                        </Typography>
                                    </Grid2>
                                    <Grid2 size={requirementRowEstimatedWidths.status} textAlign="center">
                                        <Tooltip title={"Since " + requirement.updatedAt}>
                                            {getStatusChip(requirement.status)}
                                        </Tooltip>
                                    </Grid2>
                                    <Grid2 size={requirementRowEstimatedWidths.types} overflow="hidden">
                                        <Stack direction="row" spacing={1}>
                                            {requirement.types?.map((type) => (
                                                <Chip key={type} label={type} size="small" />
                                            ))}
                                        </Stack>
                                    </Grid2>
                                    <Grid2 size={requirementRowEstimatedWidths.owner}>
                                        <Typography variant="textSm">{requirement.owner ?? ""}</Typography>
                                    </Grid2>
                                    <Grid2 size={requirementRowEstimatedWidths.date}>
                                        <Typography variant="textSm">{requirement.date ?? ""}</Typography>
                                    </Grid2>
                                    <Grid2 size={requirementRowEstimatedWidths.actions} sx={{ textAlign: "right" }}>
                                        <IconButton size="small" onClick={handleClick}>
                                            <DotsHorizontal />
                                        </IconButton>
                                        <Menu
                                            id="sub-requirement-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={() => handleOpenUnapplyDialog(requirement.id)}>
                                                <FormattedMessage defaultMessage="Unapply" />
                                            </MenuItem>
                                            <MenuItem onClick={() => handleViewRequirement(requirement.id)}>
                                                <FormattedMessage defaultMessage="View requirement" />
                                            </MenuItem>
                                        </Menu>
                                    </Grid2>
                                </Grid2>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: 0 }}>
                                <Stack>
                                    {requirement.children?.map((child) => (
                                        <SubRequirementRow key={child.id} item={child} />
                                    ))}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Stack>
            {unapplyRequirementId && (
                <UnapplyRequirementDialog
                    open={unapplyDialogOpen}
                    requirementId={unapplyRequirementId}
                    onClose={handleCloseUnapplyDialog}
                />
            )}
        </Stack>
    );
};

export default ProfileRequirementsPage;
