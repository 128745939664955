import { CircleSolid } from "@ignite-analytics/icons";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { getNACELabel } from "../NaceField/helpers";
import { NACEOptions } from "../NaceField/NACEOptions";

interface IndustryRiskProps {
    nace: string;
}

export const IndustryRisk: React.FC<IndustryRiskProps> = ({ nace }) => {
    const theme = useTheme();
    const industry = NACEOptions.find((industry) => industry.code === nace);
    const color = (() => {
        switch (industry?.score) {
            case "veryLow":
            case "low":
                return theme.palette.tokens?.icon.iconSuccess;
            case "medium":
                return theme.palette.tokens?.icon.iconWarning;
            case "high":
            case "veryHigh":
                return theme.palette.tokens?.icon.iconError;
            default:
                return theme.palette.tokens?.icon.iconGray;
        }
    })();

    const label = industry ? getNACELabel(industry) : undefined;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" height="100%">
            {industry ? (
                <Tooltip title={label} followCursor>
                    <Stack direction="row" spacing={1} maxWidth="100%" alignItems="center" height="100%">
                        <CircleSolid sx={{ color, height: "8px", width: "8px" }} />
                        <Typography variant="textSm" noWrap sx={{ textOverflow: "ellipsis", overflow: "ellipsis" }}>
                            {`${industry.code}: ${label}`}
                        </Typography>
                    </Stack>
                </Tooltip>
            ) : (
                <Typography>
                    <FormattedMessage defaultMessage="N/A" />
                </Typography>
            )}
        </Stack>
    );
};
