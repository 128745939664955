import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Chip, List, ListItem, ListItemButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import { track } from "@/lib/track";

export const SideNavigation: React.FC<{
    scrolling: boolean;
    isSmallScreen: boolean;
    numberOfUnreviewedFiles: number;
}> = ({ scrolling, isSmallScreen, numberOfUnreviewedFiles }) => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();

    const { id } = useParams<{ id: string }>();
    const currentPath = window.location.pathname;

    // https://app.launchdarkly.com/projects/default/flags/onboarding-supplier-profile/targeting?env=production&env=test&selected-env=production
    const hasOnboardingProfile = useFeatureToggle("onboarding-supplier-profile", false);
    const hasInboxConcept = useFeatureToggle("supplier-profile-inbox-concept", false);

    const items = [
        { path: "overview", message: formatMessage({ defaultMessage: "Overview" }) },
        { path: "spend", message: formatMessage({ defaultMessage: "Spend" }) },
        { path: "emissions", message: formatMessage({ defaultMessage: "Emissions" }) },
        { path: "financials", message: formatMessage({ defaultMessage: "Financials" }) },
        { path: "assessments", message: formatMessage({ defaultMessage: "Assessments" }) },
    ];

    const itemsWithInbox = hasInboxConcept
        ? [
              ...items.filter((item) => item.path !== "assessments"),
              { path: "inbox", message: formatMessage({ defaultMessage: "Inbox" }) },
          ]
        : items;
    const itemsWithOnboardingItem = hasOnboardingProfile
        ? [{ path: "onboarding", message: formatMessage({ defaultMessage: "Onboarding" }) }, ...itemsWithInbox]
        : itemsWithInbox;

    const selectedItem = itemsWithOnboardingItem.find((item) => currentPath.includes(item.path));

    return (
        <Stack
            width={isSmallScreen ? "215px" : "245px"}
            minWidth={isSmallScreen ? "215px" : "245px"}
            sx={{
                background: (theme) => theme.palette.background.backgroundSecondary,
                borderRadius: 1,
                marginBottom: 3,
                position: "fixed",
                top: 150,
                bottom: 24,
                marginTop: scrolling ? 1 : 0,
                transition: "margin-top 0.3s",
            }}
        >
            <List sx={{ padding: 2, gap: 1.5 }}>
                {itemsWithOnboardingItem.map((item) => (
                    <ListItem key={item.path} sx={{ paddingX: 0 }}>
                        <ListItemButton
                            onClick={() => {
                                track("Supplier Profile: Clicked Side Navigation", {
                                    page: item.path,
                                });
                                navigate(`${id}/${item.path}`, { replace: true });
                            }}
                            selected={selectedItem?.path === item.path}
                            disabled={!id}
                            sx={{
                                borderRadius: 1,
                                paddingX: 1.25,
                                paddingY: 0.75,
                                backgroundColor: (theme) =>
                                    selectedItem?.path === item.path
                                        ? `${theme.palette.background.paper} !important`
                                        : "inherit",
                            }}
                        >
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="textSm" fontWeight={500}>
                                    {item.message}
                                </Typography>
                                {item.path === "inbox" && numberOfUnreviewedFiles > 0 && (
                                    <Chip label={numberOfUnreviewedFiles} color="primary" size="xsmall" />
                                )}
                            </Stack>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
};
