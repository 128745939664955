import { CircleSolid } from "@ignite-analytics/icons";
import { Chip, Stack, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { countryCodes } from "../countryCode";
import { getCountryLabel } from "../utils";

interface ProductionCountriesProps {
    countries: string[];
}

export const ProductionCountries: React.FC<ProductionCountriesProps> = ({ countries }) => {
    const theme = useTheme();
    const getCountryColor = (score: string | null | undefined) => {
        switch (score) {
            case "1":
            case "2":
                return theme.palette.tokens?.icon.iconSuccess;
            case "3":
            case "4":
                return theme.palette.tokens?.icon.iconWarning;
            case "5":
            case "6":
                return theme.palette.tokens?.icon.iconError;
            default:
                return theme.palette.tokens?.icon.iconGray;
        }
    };
    return (
        <Stack direction="row" gap={1.25} flexWrap="wrap">
            {countries.length > 0 ? (
                countries.map((country, index) => {
                    const riskCountry = countryCodes.find((c) => c.code === country);
                    return (
                        <Chip
                            key={index}
                            size="small"
                            variant="tag"
                            label={
                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                    <CircleSolid
                                        fontSize="small"
                                        sx={{
                                            width: "16px",
                                            height: "16px",
                                            color: getCountryColor(riskCountry?.score),
                                        }}
                                    />
                                    <Typography variant="textXs" fontWeight={500} textOverflow="ellipsis">
                                        {getCountryLabel(riskCountry)}
                                    </Typography>
                                </Stack>
                            }
                        />
                    );
                })
            ) : (
                <Typography>
                    <FormattedMessage defaultMessage="N/A" />
                </Typography>
            )}
        </Stack>
    );
};
