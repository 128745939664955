import type { ResultOf } from "@graphql-typed-document-node/core";
import { Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";

import { RiskIndicator } from "./RiskIndicator";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CountryFragment = graphql(`
    fragment GeoRisk_Supplier on Supplier {
        riskV3 {
            social {
                countryRisk {
                    basedOn {
                        country {
                            id
                            name(language: $language)
                            risk {
                                score
                            }
                        }
                    }
                }
            }
        }
    }
`);

interface GeoRiskProps {
    supplier: ResultOf<typeof CountryFragment>;
}

export const GeoRisk: React.FC<GeoRiskProps> = ({ supplier }) => {
    const country = supplier.riskV3?.social?.countryRisk?.basedOn?.country;

    return (
        <Stack direction="row" alignItems="center">
            {country ? (
                <Tooltip title={country.name} followCursor>
                    <Stack direction="row" alignItems="center" spacing={1} maxWidth="100%" height="100%">
                        <RiskIndicator riskScore={country.risk?.score} sx={{ fontSize: 8 }} />
                        <Typography variant="textSm" noWrap sx={{ textOverflow: "ellipsis", overflow: "ellipsis" }}>
                            {country.name}
                        </Typography>
                    </Stack>
                </Tooltip>
            ) : (
                <Typography>
                    <FormattedMessage defaultMessage="N/A" />
                </Typography>
            )}
        </Stack>
    );
};
