import { useMutation } from "@apollo/client";
// import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { DotsVertical, Trash } from "@ignite-analytics/icons";
import { CircularProgress, Divider, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { getNameAndContentType } from "@/lib/files";
import { track } from "@/lib/track";

import { DeleteModal } from "./DeleteModal";
import { DocumentEditModal } from "./DocumentEditModal";

import { DocumentListFiles } from "./index";

type ActionProps = {
    file: DocumentListFiles;
    supplierId: string;
    isEditor: boolean;
};

const documentActions_SupplierFileDownloadUrlMutation = graphql(`
    mutation DocumentsActions_CreateSupplierFileDownloadURL($input: CreateSupplierFileDownloadUrlInput!) {
        createSupplierFileDownloadUrl(input: $input) {
            url
        }
    }
`);

const documentActions_AssessmentsDownloadUrlMutation = graphql(`
    mutation DocumentsActions_CreateAssessmentsDownloadURL($input: CreateAssessmentsDownloadURLInput!) {
        createAssessmentsDownloadURL(input: $input) {
            url
        }
    }
`);

graphql(`
    fragment DocumentsActions_SupplierFile on SupplierFile {
        name
        ...DeleteModal_SupplierFile
    }
`);

export const DocumentActions: React.FC<ActionProps> = ({ file, supplierId, isEditor }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [downloadSupplierFile] = useMutation(documentActions_SupplierFileDownloadUrlMutation);
    const [downloadAssessmentsFile] = useMutation(documentActions_AssessmentsDownloadUrlMutation);

    const hasEditFeature = useFeatureToggle("supplier-document-edit-flow", false);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { origin: uploadOrigin = "" } = file.metaJson ? JSON.parse(file.metaJson) : {};

    async function getFileUrl(selectedFile: DocumentListFiles) {
        switch (selectedFile.__typename) {
            case "SupplierFile": {
                const supplierUrlData = await downloadSupplierFile({
                    variables: {
                        input: {
                            supplierId: selectedFile.supplierId,
                            fileName: selectedFile.name,
                        },
                    },
                });
                return supplierUrlData?.data?.createSupplierFileDownloadUrl?.url ?? "";
            }
            case "AssessmentsFile": {
                const assessmentsUrlData = await downloadAssessmentsFile({
                    variables: {
                        input: {
                            referenceId: selectedFile.referenceId,
                            fileName: selectedFile.name,
                        },
                    },
                });
                return assessmentsUrlData.data?.createAssessmentsDownloadURL?.url ?? "";
            }
            default:
                return Promise.resolve("");
        }
    }

    const handleDownloadFile = async (selectedFile: DocumentListFiles) => {
        const url = await getFileUrl(selectedFile);
        const { originalFileName, contentType } = getNameAndContentType(selectedFile);
        if (!url) {
            return Promise.resolve();
        }

        const headerSet = {
            "Content-Disposition": `attachment; filename*=UTF-8''${originalFileName}`,
            "Content-Type": contentType,
            "X-Content-Type-Options": "nosniff",
        };

        const fileBlob = fetch(url, {
            method: "GET",
            headers: headerSet,
        })
            .then((response) => {
                return response.blob();
            })
            .catch((err) => {
                throw err;
            });
        return fileBlob
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.setAttribute("download", `${decodeURIComponent(originalFileName)}`);
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                track("Supplier Profile: Downloaded Document", { supplierId });
            })
            .catch((err) => {
                throw err;
            });
    };

    const handleViewFile = async (selectedFile: DocumentListFiles) => {
        const url = await getFileUrl(selectedFile);
        const { originalFileName, contentType } = getNameAndContentType(selectedFile);
        if (!url) {
            return Promise.resolve();
        }

        const headerSet = {
            "Content-Disposition": `attachment; filename*=UTF-8''${originalFileName}`,
            "Content-Type": contentType,
            "X-Content-Type-Options": "nosniff",
        };

        fetch(url, {
            method: "GET",
            headers: headerSet,
        })
            .then((response) => response.blob())
            .then((blob) => {
                if (contentType === "application/octet-stream") {
                    const blobUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.setAttribute("download", decodeURIComponent(originalFileName));
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                } else {
                    const blobUrl = window.URL.createObjectURL(blob);
                    window.open(blobUrl, "_blank");
                }
                track("Supplier Profile: Viewed Document", { supplierId });
            })
            .catch((err) => {
                throw err;
            });
    };

    return (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
            {isLoading ? (
                <Stack>
                    <CircularProgress size={24} />
                </Stack>
            ) : (
                <IconButton
                    size="xsmall"
                    color="secondary"
                    className={"menu-icon" + file.name}
                    onClick={handleMenuClick}
                >
                    <DotsVertical />
                </IconButton>
            )}
            <Menu key={"menu-" + file.name} anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    key={"view-" + file.name}
                    onClick={async () => {
                        await handleViewFile(file);
                        handleClose();
                    }}
                >
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="View" />
                    </Typography>
                </MenuItem>
                {file.__typename === "SupplierFile" && hasEditFeature && (
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <MenuItem key={"edit-" + file.name} onClick={() => setOpenEditModal(true)}>
                            <Typography variant="textSm">
                                <FormattedMessage defaultMessage="Edit" />
                            </Typography>
                        </MenuItem>
                    </NoPermissionTooltip>
                )}
                <MenuItem
                    key={"download-" + file.name}
                    onClick={async () => {
                        await handleDownloadFile(file);
                        handleClose();
                    }}
                >
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="Download" />
                    </Typography>
                </MenuItem>
                {file.__typename === "SupplierFile" && (
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <Divider />
                        <MenuItem
                            key={"delete-" + file.name}
                            onClick={() => {
                                setOpenDeleteModal(true);
                                handleClose();
                            }}
                            disabled={!isEditor}
                        >
                            {uploadOrigin === "assessment" ? (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="textSm" color="error">
                                        <FormattedMessage defaultMessage="Mark as unreviewed" />
                                    </Typography>
                                </Stack>
                            ) : (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Trash color="error" />
                                    <Typography variant="textSm" color="error">
                                        <FormattedMessage defaultMessage="Delete" />
                                    </Typography>
                                </Stack>
                            )}
                        </MenuItem>
                    </NoPermissionTooltip>
                )}
            </Menu>
            {file.__typename === "SupplierFile" && (
                <>
                    <DeleteModal
                        file={file}
                        open={openDeleteModal}
                        setOpen={setOpenDeleteModal}
                        setIsLoading={setIsLoading}
                        supplierId={supplierId}
                    />
                    <DocumentEditModal file={file} open={openEditModal} setOpen={setOpenEditModal} />
                </>
            )}
        </Stack>
    );
};
