import { Company, LocationPin, Tag, User } from "@ignite-analytics/icons";
import { Button, Grid2, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { CountrySelect } from "@/components/Common/CountrySelect";

import { ProductionCountriesSelect } from "../../Overview/SideColumn/SupplierInfo/ProductionCountriesSelect";
import { AddContactDialog } from "../Components/AddContactDialog";
import { Contact, ContactCard } from "../Components/ContactCard";
import { IndustryAutocomplete } from "../Components/IndustrySelect";

export const SupplierInformationSection = () => {
    const [country, setCountry] = useState<{ id: string; name: string; __typename: "SupplierCountry" }>({
        __typename: "SupplierCountry",
        id: "placeholder",
        name: "Norway",
    });
    const [productionCountries, setProductionCountries] = useState<
        { id: string; name: string; __typename: "SupplierCountry" }[]
    >([]);
    const [industry, setIndustry] = useState<string>("");
    const [organizationNumber, setOrganizationNumber] = useState<string>("");
    const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false);
    const [contacts, setContacts] = useState<Contact[]>([
        // Example contact
        {
            id: "1",
            firstName: "John",
            lastName: "Doe",
            email: "john.doe@ignite.no",
            phoneNumber: "+47 987 65 321",
            position: "Account executive",
        },
    ]);
    const [editContact, setEditContact] = useState<Contact | undefined>(undefined);

    const handleAddContact = (contact: {
        id?: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        position?: string;
    }) => {
        if (contact.id) {
            // Edit existing contact
            setContacts(contacts.map((c) => (c.id === contact.id ? ({ ...contact, id: c.id } as Contact) : c)));
        } else {
            // Add new contact
            setContacts([...contacts, { ...contact, id: crypto.randomUUID() } as Contact]);
        }
        setEditContact(undefined);
    };

    const handleEditContact = (contact: Contact) => {
        setEditContact(contact);
        setIsAddContactDialogOpen(true);
    };

    const handleDeleteContact = (contactId: string) => {
        setContacts(contacts.filter((c) => c.id !== contactId));
    };

    const handleCountryChange = (value: { id: string; name: string; __typename: "SupplierCountry" } | null) => {
        if (value) {
            setCountry(value);
        } else {
            setCountry({ id: "placeholder", name: "Norway", __typename: "SupplierCountry" });
        }
    };
    const handleProductionCountriesChange = (
        value: { id: string; name: string; __typename: "SupplierCountry" }[] | null
    ) => {
        if (value) {
            setProductionCountries(value);
        } else {
            setProductionCountries([]);
        }
    };

    return (
        <Paper sx={{ p: 2 }} elevation={1}>
            <Grid2 container spacing={3}>
                <Grid2 size={12}>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="Supplier information" />
                    </Typography>
                </Grid2>
                <Grid2 container>
                    <Grid2 size={5} container alignItems="center" spacing={1}>
                        <Grid2>
                            <Tag />
                        </Grid2>
                        <Grid2>
                            <Typography variant="textSm" fontWeight={500}>
                                <FormattedMessage defaultMessage="Organization number" />
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={7}>
                        <TextField
                            fullWidth
                            placeholder="Enter organization number"
                            value={organizationNumber}
                            onChange={(e) => setOrganizationNumber(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 size={5} container alignItems="center" spacing={1}>
                        <Grid2>
                            <LocationPin />
                        </Grid2>
                        <Grid2>
                            <Typography variant="textSm" fontWeight={500}>
                                <FormattedMessage defaultMessage="Country" />
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={7}>
                        <CountrySelect defaultValue={country} onChange={handleCountryChange} />
                    </Grid2>
                    <Grid2 size={5} container alignItems="center" spacing={1}>
                        <Grid2>
                            <LocationPin />
                        </Grid2>
                        <Grid2>
                            <Typography variant="textSm" fontWeight={500}>
                                <FormattedMessage defaultMessage="Production countries" />
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={7}>
                        <ProductionCountriesSelect
                            defaultValue={productionCountries}
                            onChange={handleProductionCountriesChange}
                        />
                    </Grid2>

                    <Grid2 size={5} container alignItems="center" spacing={1}>
                        <Grid2>
                            <Company />
                        </Grid2>
                        <Grid2>
                            <Typography variant="textSm" fontWeight={500}>
                                <FormattedMessage defaultMessage="Industry" />
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={7}>
                        <IndustryAutocomplete
                            industry={industry}
                            onChange={(newValue) => setIndustry(newValue ?? "")}
                        />
                    </Grid2>
                    <Grid2 size={5} container alignItems="center" spacing={1}>
                        <Grid2>
                            <User />
                        </Grid2>
                        <Grid2>
                            <Typography variant="textSm" fontWeight={500}>
                                <FormattedMessage defaultMessage="Supplier contact" />
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={7} display="flex" justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            onClick={() => setIsAddContactDialogOpen(true)}
                        >
                            <FormattedMessage defaultMessage="Add contact" />
                        </Button>
                    </Grid2>
                </Grid2>
                <Grid2 size={12} container spacing={2} mt={2}>
                    {contacts.map((contact) => (
                        <Grid2 size={12} key={contact.id}>
                            <ContactCard contact={contact} onEdit={handleEditContact} onDelete={handleDeleteContact} />
                        </Grid2>
                    ))}
                </Grid2>
            </Grid2>
            <AddContactDialog
                open={isAddContactDialogOpen}
                onClose={() => {
                    setIsAddContactDialogOpen(false);
                    setEditContact(undefined);
                }}
                onAddContact={handleAddContact}
                editContact={editContact}
            />
        </Paper>
    );
};
