import { ShapeIcon } from "@ignite-analytics/components";
import { ArrowDownTray } from "@ignite-analytics/icons";
import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { OverviewPage_SupplierFragment } from "@/gql/graphql";

import { AssessmentsTab } from "./Assessments/AssessmentsTab";
import { DocumentsTab } from "./Documents/DocumentsTab";

interface InboxPageProps {
    supplier?: OverviewPage_SupplierFragment;
    loading: boolean;
}

export const InboxPage: React.FC<InboxPageProps> = ({ supplier, loading }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    // Get tab from URL or default to "assessments"
    const tab = (searchParams.get("tab") as "assessments" | "documents") || "documents";

    // Update URL when tab changes
    const handleTabChange = (_: React.SyntheticEvent, newValue: "assessments" | "documents") => {
        setSearchParams({ tab: newValue });
    };

    return (
        <Stack spacing={3} width="100%">
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <ShapeIcon color="accent" variant="default" size="small" icon={<ArrowDownTray />} />
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Inbox" description="Assessments title" />
                    </Typography>
                </Stack>
            </Stack>
            <Tabs value={tab} onChange={handleTabChange}>
                <Tab label="Documents" value="documents" />
                <Tab label="Assessments" value="assessments" />
            </Tabs>
            {tab === "documents" && <DocumentsTab supplier={supplier} loading={loading} />}
            {tab === "assessments" && <AssessmentsTab supplierId={supplier?.id ?? ""} />}
        </Stack>
    );
};
