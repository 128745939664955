import { useEffect, useState } from "react";

import { TruckIcon } from "@/assets/TruckIcon";
import { ProfileAvatar } from "@/components/Common/ProfileAvatar";
import { track } from "@/lib/track";

import { cleanCompanyName } from "./helpers";

export const CompanyIcon: React.FC<{ name: string }> = ({ name }) => {
    const [icon, setIcon] = useState<string | null>(null);

    useEffect(() => {
        const clientId = import.meta.env.VITE_BRANDFETCH_CLIENT_ID;
        if (!clientId) {
            return;
        }
        const nameSearch = cleanCompanyName(name) || name;
        fetch(`https://api.brandfetch.io/v2/search/${nameSearch}?c=${clientId}&limit=1`)
            .then((res) => res.json())
            .then((data) => {
                const iconSrc = data[0]?.icon;
                track("Supplier Profile: Invoked Brandfetch API", { gotHit: iconSrc !== undefined, nameSearch });
                setIcon(iconSrc);
            });
    }, [name]);

    if (!icon) return <ProfileAvatar icon={<TruckIcon />} />;

    return (
        <img src={icon} style={{ width: "44px", height: "44px" }} alt="Logos by Brandfetch" crossOrigin="anonymous" />
    );
};
