import { AlertColor, AlertProps, SnackbarProps } from "@mui/material";
import React, { useContext } from "react";

type Message = {
    value: React.ReactNode;
    title?: React.ReactNode;
    severity?: AlertColor;
};

type Alert = {
    message: Message;
    AlertProps?: Omit<AlertProps, "severity">;
    SnackbarProps?: SnackbarProps;
    timestamp: number;
};

type AlertOptions = {
    AlertProps?: Omit<AlertProps, "severity">;
    SnackbarProps?: SnackbarProps;
};

type IAlertContext = {
    alertUser: (alert: Message, options?: AlertOptions) => void;
    onClose: () => void;
    alert: Alert | null;
};

const AlertContext = React.createContext<IAlertContext>({
    alertUser: () => null,
    onClose: () => null,
    alert: null,
});

export function useAlert(): IAlertContext {
    return useContext(AlertContext);
}

export const AlertProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [alert, setAlert] = React.useState<Alert | null>(null);

    function alertUser(message: Message, options?: AlertOptions) {
        setAlert({ message, timestamp: new Date().getTime(), ...options });
    }

    function handleClose() {
        setAlert(null);
    }

    return <AlertContext.Provider value={{ alertUser, alert, onClose: handleClose }}>{children}</AlertContext.Provider>;
};
