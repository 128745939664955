import type { ResultOf } from "@graphql-typed-document-node/core";
import { Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";

import { getNACELabel } from "../NaceField/helpers";
import { NACEOptions } from "../NaceField/NACEOptions";

import { RiskIndicator } from "./RiskIndicator";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SupplierFragment = graphql(`
    fragment IndustryRisk_Supplier on Supplier {
        nace
        riskV3 {
            social {
                industry {
                    score
                }
            }
        }
    }
`);

interface IndustryRiskProps {
    supplier: ResultOf<typeof SupplierFragment>;
}

export const IndustryRisk: React.FC<IndustryRiskProps> = ({ supplier }) => {
    const industry = NACEOptions.find((industry) => industry.code === supplier.nace);

    const label = industry ? getNACELabel(industry) : undefined;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" height="100%">
            {industry ? (
                <Tooltip title={label} followCursor>
                    <Stack direction="row" spacing={1} maxWidth="100%" alignItems="center" height="100%">
                        <RiskIndicator
                            riskScore={supplier.riskV3?.social?.industry?.score}
                            sx={{
                                fontSize: 8,
                            }}
                        />
                        <Typography variant="textSm" noWrap sx={{ textOverflow: "ellipsis", overflow: "ellipsis" }}>
                            {`${industry.code}: ${label}`}
                        </Typography>
                    </Stack>
                </Tooltip>
            ) : (
                <Typography>
                    <FormattedMessage defaultMessage="N/A" />
                </Typography>
            )}
        </Stack>
    );
};
