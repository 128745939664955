import { Button, Chip, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { DocumentsList_AssessmentsFileFragment } from "@/gql/graphql";
import { getNameAndContentType, getShortDocumentTypeName, middleEllipsis } from "@/lib/files";

import { AssessmentDocumentEditModal } from "../../Overview/MainColumn/Documents/DocumentsList/AssessmentDocumentReviewModal";
import { DocumentActions } from "../../Overview/MainColumn/Documents/DocumentsList/DocumentActions";
import { ExpirationChip } from "../../Overview/MainColumn/Documents/DocumentsList/ExpirationChip";

type Props = {
    files: DocumentsList_AssessmentsFileFragment[];
    supplierId: string;
    isEditor: boolean;
};

export const InboxDocumentsList: React.FC<Props> = ({ files, supplierId, isEditor }) => {
    const { formatDate } = useIntl();
    const [selectedFileId, setSelectedFileId] = useState<string | null>(null);

    return files?.map((file) => {
        const { originalFileName } = getNameAndContentType(file);
        const metaJson = file.metaJson ? JSON.parse(file.metaJson) : {};
        const docType = metaJson.doctype;
        const expiryDate = metaJson.expirydate;

        return (
            <Paper key={`${file.name}+${file.metaJson}`} sx={{ padding: 2 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack spacing={1} pr={2}>
                        <Tooltip title={decodeURIComponent(originalFileName)}>
                            <Typography
                                variant="textSm"
                                fontWeight={500}
                                sx={{ overflowWrap: "break-word", wordBreak: "break-all" }}
                            >
                                {middleEllipsis(decodeURIComponent(originalFileName))}
                            </Typography>
                        </Tooltip>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {getShortDocumentTypeName(docType) !== "" && (
                                <Chip
                                    label={getShortDocumentTypeName(docType)}
                                    color="primary"
                                    size="small"
                                    clickable={false}
                                />
                            )}

                            <Typography variant="textXs">
                                {file.createdAt !== null ? formatDate(file.createdAt) : ""}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <ExpirationChip expiryDate={expiryDate} />
                        <Button
                            color="secondary"
                            size="xsmall"
                            key={"edit-" + file.name}
                            onClick={() => setSelectedFileId(file.name)}
                            disabled={!isEditor}
                        >
                            <Typography variant="textSm">
                                <FormattedMessage defaultMessage="Review" />
                            </Typography>
                        </Button>
                        <DocumentActions isEditor={isEditor} file={file} supplierId={supplierId} />
                    </Stack>
                </Stack>
                <AssessmentDocumentEditModal
                    file={file}
                    open={selectedFileId === file.name}
                    setClose={() => setSelectedFileId(null)}
                    supplierId={supplierId}
                />
            </Paper>
        );
    });
};
