import { useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { DotsVertical } from "@ignite-analytics/icons";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { RiskChip } from "@/components/Common/StatusChip";
import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql/gql";
import {
    ClassificationOptions,
    DateInterval,
    SupplierCustomFieldType,
    SupplierInfo_SupplierFragment,
} from "@/gql/graphql";
import { useGeneralPermission, useSupplierPermission } from "@/lib/permissions";
import { track } from "@/lib/track";

import { AboutLabel } from "./AboutLabel";
import { AboutSupplierModal } from "./AboutSupplierModal";
import { CertsInfo } from "./CertsInfo";
import { CocInfoChip } from "./CocInfoChip";
import { GeoRisk } from "./RiskFields/GeoRisk";
import { IndustryRisk } from "./RiskFields/IndustryRisk";
import { ProductionCountries } from "./RiskFields/ProductionCountries";
import { InfoRow, isValidDate } from "./utils";

graphql(`
    fragment SupplierInfo_Supplier on Supplier {
        id
        name
        orgNumber
        nace
        countryV2 {
            id
            iso2Code
            name(language: $language)
        }
        productionCountriesV2 {
            ...ProductionCountries_SupplierCountry
        }
        onboarding {
            status
            approverId
            evaluatedAt
        }
        ...GeoRisk_Supplier
        riskV3 {
            social {
                gross
                net
                industry {
                    score
                }
            }
        }
        ...IndustryRisk_Supplier
        ...AboutSupplierModal_Supplier
        codeOfConducts {
            filename
            expiresAt
            uploadedAt
            originalFilename
            uploadedBy
        }
        certifications {
            filename
            expiresAt
            uploadedAt
            documentType
            uploadedBy
            originalFilename
        }
        spendDimensions {
            l12MSpend
        }
    }

    fragment SupplierInfo_RoleUser on RoleUser {
        id
        firstName
        lastName
    }
`);

const getApproverUserQuery = graphql(`
    query getUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...SupplierInfo_RoleUser
            }
        }
    }
`);

const getSupplierTableColumnsQuery = graphql(`
    query getSupplierTableColumns {
        getSupplierTableMeta {
            columns {
                type
                ...AboutSupplierModal_Columns
            }
        }
    }
`);

const getSupplierPaymentTermsQuery = graphql(`
    query getSuppliersPaymentTerms($input: GetSuppliersPaymentTermsInput!) {
        getSuppliersPaymentTerms(input: $input) {
            suppliers {
                avgPaymentTerms
            }
        }
    }
`);

interface SupplierInfoProps {
    supplier: SupplierInfo_SupplierFragment;
    profileConfig: {
        displayOnboarding: boolean;
        displaySocialRisk: boolean;
        displaySpend: boolean;
        displayedCustomFields: string[];
        displayDocumentsInfo: boolean;
    };
}

export const SupplierInfo: React.FC<SupplierInfoProps> = ({ supplier, profileConfig }) => {
    const isEditor = useSupplierPermission({ object: "general", relation: "write" });
    // Privileged write in general namespaces defines an admin
    const isAdmin = useGeneralPermission({ object: "privileged", relation: "write" });
    const [modalView, setModalView] = useState<"edit" | "configure" | "display" | null>(null);
    const { formatMessage } = useIntl();
    const [approver, setApprover] = useState("");
    const onboardingEnabled = useFeatureToggle("onboarding-enabled", true);
    // https://app.launchdarkly.com/projects/default/flags/configure-supplier-profile-enabled/targeting?env=production&env=test&selected-env=production
    const configureEnabled = useFeatureToggle("configure-supplier-profile-enabled", false);
    const { data: columnsData } = useQuery(getSupplierTableColumnsQuery);
    const supplierTableColumns = columnsData?.getSupplierTableMeta.columns ?? [];

    useQuery(getApproverUserQuery, {
        variables: {
            input: {
                userIds: [supplier.onboarding?.approverId ?? ""],
            },
        },
        skip: !supplier.onboarding?.approverId || supplier.onboarding?.approverId === "",
        onCompleted: (data) => {
            if (data.getUsers.result.length !== 1) return;
            setApprover(`${data.getUsers.result[0].firstName} ${data.getUsers.result[0].lastName}`);
        },
    });

    const { data: paymentTermsData } = useQuery(getSupplierPaymentTermsQuery, {
        variables: {
            input: {
                filters: {
                    suppliers: [supplier.id],
                },
                period: {
                    relative: {
                        period: DateInterval.Month,
                        length: 12,
                        offset: 0,
                    },
                },
            },
        },
    });
    const paymentTerms = paymentTermsData?.getSuppliersPaymentTerms?.suppliers[0]?.avgPaymentTerms;

    const onboardingDate = supplier.onboarding?.evaluatedAt ?? "";
    const isValidOnboardingDate = isValidDate(onboardingDate);

    /**
     * Classification fields need additional data fetching to get the group labels.
     */
    const customFields = supplier.customFields
        .map((field) => {
            let value = JSON.parse(field.dataJson);
            if (field.fieldType === SupplierCustomFieldType.Classification) {
                const column = supplierTableColumns.find((tc) => tc.id === field.fieldId);
                if (column && Array.isArray(value)) {
                    value = value
                        .map((groupId: string) => {
                            const group = (column.typeOptions as ClassificationOptions)?.groups?.find(
                                (g) => g.id === groupId
                            );
                            return group?.value;
                        })
                        .filter((label) => label !== undefined);
                }
            }
            const order = profileConfig.displayedCustomFields.indexOf(field.fieldId);
            return {
                id: field.fieldId,
                name: field.name,
                value,
                type: field.fieldType,
                order,
                visibleInCompactView: order !== -1,
            };
        })
        .sort((a, b) => a.order - b.order);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    return (
        <Card>
            <CardHeader
                title={
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="About" description="supplier information header" />
                    </Typography>
                }
                action={
                    <IconButton
                        type="button"
                        color="secondary"
                        size="small"
                        onClick={(event) => {
                            track("Supplier Profile: Edit Supplier Info Opened");
                            setMenuAnchorEl(event.currentTarget);
                        }}
                    >
                        <DotsVertical />
                    </IconButton>
                }
            />
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClick={() => setMenuAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <NoPermissionTooltip
                    hasPermission={isEditor}
                    message={
                        <FormattedMessage defaultMessage="Your permissions do not allow editing this supplier profile" />
                    }
                >
                    <MenuItem onClick={() => setModalView("edit")} disabled={!isEditor}>
                        <FormattedMessage defaultMessage="Edit" description="Edit button" />
                    </MenuItem>
                </NoPermissionTooltip>
                {configureEnabled && (
                    <NoPermissionTooltip
                        hasPermission={isAdmin}
                        message={
                            <FormattedMessage
                                defaultMessage="Only Ignite admins can configure supplier profiles"
                                description="Tooltip message for configuring supplier profiles"
                            />
                        }
                    >
                        <MenuItem onClick={() => setModalView("configure")} disabled={!isAdmin}>
                            <FormattedMessage defaultMessage="Configure view" description="Menu item for configuring" />
                        </MenuItem>
                    </NoPermissionTooltip>
                )}
            </Menu>
            <CardContent sx={{ paddingTop: 0 }}>
                <Stack spacing={2}>
                    <Grid container rowSpacing={2}>
                        <AboutLabel
                            label={
                                <FormattedMessage
                                    defaultMessage="Headquarter country"
                                    description="Headquarter country label"
                                />
                            }
                        >
                            <Typography variant="textSm">{supplier.countryV2?.name}</Typography>
                        </AboutLabel>
                        <AboutLabel
                            label={
                                <FormattedMessage
                                    defaultMessage="Production countries"
                                    description="Production countries label"
                                />
                            }
                        >
                            {supplier.productionCountriesV2.length > 0 && (
                                <Stack gap={1} alignItems="flex-start" direction="row" flexWrap="wrap">
                                    {supplier.productionCountriesV2.map((country) => (
                                        <Chip key={country.id} label={country.name} size="small" color="neutral" />
                                    ))}
                                </Stack>
                            )}
                            {supplier.productionCountriesV2.length === 0 && (
                                <Typography variant="textSm">
                                    <FormattedMessage
                                        defaultMessage="N/A"
                                        description="Production countries N/A placeholder"
                                    />
                                </Typography>
                            )}
                        </AboutLabel>
                        <InfoRow
                            id="industry"
                            name={formatMessage({ defaultMessage: "Industry", description: "Industry name" })}
                            value={supplier.nace ?? ""}
                            type={SupplierCustomFieldType.Nace}
                        />
                        <InfoRow
                            id="vat-id"
                            name={formatMessage({ defaultMessage: "VAT id", description: "VAT id name" })}
                            value={supplier.orgNumber ?? ""}
                            type={SupplierCustomFieldType.Text}
                        />
                    </Grid>
                    {supplier.onboarding && onboardingEnabled && profileConfig.displayOnboarding && (
                        <>
                            <Divider />
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Onboarding" description="Onboarding info header" />
                            </Typography>
                            <Grid
                                container
                                rowSpacing={2}
                                sx={{
                                    "& .MuiGrid-item:nth-of-type(1), & .MuiGrid-item:nth-of-type(2)": { paddingTop: 0 },
                                }}
                            >
                                <InfoRow
                                    id="onboarding-approver"
                                    name={formatMessage({ defaultMessage: "Approver", description: "Approver name" })}
                                    value={
                                        approver
                                            ? approver
                                            : formatMessage({
                                                  defaultMessage: "N/A",
                                                  description: "Onboarding approver placeholder",
                                              })
                                    }
                                    type={SupplierCustomFieldType.Text}
                                />
                                <InfoRow
                                    id="onboarding-approved-at"
                                    name={formatMessage({
                                        defaultMessage: "Approved at",
                                        description: "Approved at name",
                                    })}
                                    value={
                                        isValidOnboardingDate
                                            ? onboardingDate
                                            : formatMessage({
                                                  defaultMessage: "N/A",
                                                  description: "Onboarding approved at placeholder",
                                              })
                                    }
                                    type={
                                        isValidOnboardingDate
                                            ? SupplierCustomFieldType.Date
                                            : SupplierCustomFieldType.Text
                                    }
                                />
                                <InfoRow
                                    id="onboarding-status"
                                    name={formatMessage({
                                        defaultMessage: "Onboarding status",
                                        description: "Onboarding status name",
                                    })}
                                    value={supplier.onboarding.status}
                                    type="onboarding"
                                />
                            </Grid>
                        </>
                    )}
                    {profileConfig.displayDocumentsInfo && (
                        <>
                            <Divider />
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Compliance" description="Compliance info header" />
                            </Typography>
                            <Grid container rowSpacing={2}>
                                <InfoRow
                                    id="code-of-conduct"
                                    name={formatMessage({
                                        defaultMessage: "Code of Conduct",
                                        description: "Code of Conduct",
                                    })}
                                    value=""
                                    component={<CocInfoChip codeOfConducts={supplier.codeOfConducts ?? []} />}
                                    type={SupplierCustomFieldType.Text}
                                />
                                <InfoRow
                                    id="certifications"
                                    name={formatMessage({
                                        defaultMessage: "Certifications",
                                        description: "Certifications",
                                    })}
                                    value=""
                                    component={<CertsInfo certs={supplier.certifications ?? []} />}
                                    type={SupplierCustomFieldType.Text}
                                />
                            </Grid>
                        </>
                    )}
                    {profileConfig.displaySocialRisk && (
                        <>
                            <Divider />
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Social risk" description="Social Risk info header" />
                            </Typography>
                            <Grid
                                container
                                rowSpacing={2}
                                sx={{
                                    "& .MuiGrid-item:nth-of-type(1), & .MuiGrid-item:nth-of-type(2)": { paddingTop: 0 },
                                }}
                            >
                                <InfoRow
                                    id="social-risk"
                                    name={formatMessage({
                                        defaultMessage: "Ignite risk estimate",
                                        description: "Social risk",
                                    })}
                                    value=""
                                    type={SupplierCustomFieldType.Risk}
                                    component={<RiskChip riskScore={supplier.riskV3?.social?.gross} />}
                                />
                                <InfoRow
                                    id="social-risk"
                                    name={formatMessage({
                                        defaultMessage: "Your risk estimate",
                                        description: "Social risk",
                                    })}
                                    value=""
                                    type={SupplierCustomFieldType.Risk}
                                    component={<RiskChip riskScore={supplier.riskV3?.social?.net} />}
                                />
                                <InfoRow
                                    id="geography-risk"
                                    name={formatMessage({
                                        defaultMessage: "Country",
                                        description: "Geography risk",
                                    })}
                                    value=""
                                    type={SupplierCustomFieldType.Risk}
                                    component={<GeoRisk supplier={supplier} />}
                                />
                                <AboutLabel
                                    label={
                                        <FormattedMessage
                                            defaultMessage="Production countries"
                                            description="Production countries label"
                                        />
                                    }
                                >
                                    <ProductionCountries countries={supplier.productionCountriesV2} />
                                </AboutLabel>
                                <InfoRow
                                    id="industry-risk"
                                    name={formatMessage({
                                        defaultMessage: "Industry risk",
                                        description: "Industry risk",
                                    })}
                                    value=""
                                    type={SupplierCustomFieldType.Risk}
                                    component={<IndustryRisk supplier={supplier} />}
                                />
                            </Grid>
                        </>
                    )}
                    {profileConfig.displayedCustomFields.length > 0 && (
                        <>
                            <Divider />
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage
                                    defaultMessage="Additional fields"
                                    description="Additional fields info header"
                                />
                            </Typography>
                            <Grid container rowSpacing={2}>
                                {customFields
                                    .filter((field) => field.visibleInCompactView)
                                    .map((field) => (
                                        <InfoRow
                                            id={field.id}
                                            key={field.id}
                                            name={field.name}
                                            value={field.value}
                                            type={field.type}
                                        />
                                    ))}
                            </Grid>
                        </>
                    )}
                    {profileConfig.displaySpend && (
                        <>
                            <Divider />
                            <Typography variant="textMd" fontWeight={500}>
                                <FormattedMessage defaultMessage="Spend" description="Spend info header" />
                            </Typography>
                            <Grid container rowSpacing={2}>
                                <InfoRow
                                    id="spend-ltm"
                                    name={formatMessage({
                                        defaultMessage: "Total spend LTM",
                                        description: "Spend name",
                                    })}
                                    value={supplier.spendDimensions.l12MSpend}
                                    type={SupplierCustomFieldType.Spend}
                                />
                                <InfoRow
                                    id="avg-payment-terms"
                                    name={formatMessage({
                                        defaultMessage: "Actual payment terms",
                                        description: "Avg. payment terms name",
                                    })}
                                    value=""
                                    component={
                                        <Typography variant="textSm">
                                            {paymentTerms ? (
                                                formatMessage(
                                                    { defaultMessage: "{value} days" },
                                                    {
                                                        value: paymentTerms.toFixed(0),
                                                    }
                                                )
                                            ) : (
                                                <FormattedMessage defaultMessage="N/A" />
                                            )}
                                        </Typography>
                                    }
                                    type={SupplierCustomFieldType.Text}
                                />
                            </Grid>
                        </>
                    )}
                </Stack>
            </CardContent>
            <CardActions>
                <Stack width="100%">
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => {
                            track("Supplier Profile: Show More Supplier Info Opened");
                            setModalView("display");
                        }}
                    >
                        <FormattedMessage defaultMessage="Show more" description="Show more button supplier info" />
                    </Button>
                </Stack>
            </CardActions>
            {modalView !== null && (
                <AboutSupplierModal
                    supplier={supplier}
                    approver={approver}
                    profileConfig={profileConfig}
                    mode={modalView}
                    setModalView={setModalView}
                    onboardingEnabled={onboardingEnabled}
                />
            )}
        </Card>
    );
};
