import { Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { FormatDateOptions, FormatNumberOptions, FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { InfoRow_SupplierCustomFieldFragment, OnboardingStatus, SupplierCustomFieldType } from "@/gql/graphql";

import { CountryOption } from "./countryCode";
import { EditField } from "./EditField/index";
import { getNACELabel } from "./NaceField/helpers";
import { NACEOptions } from "./NaceField/NACEOptions";

graphql(`
    fragment InfoRow_SupplierCustomField on SupplierCustomField {
        name
        dataJson
        fieldType
    }
`);

interface InfoRowProps {
    id?: string;
    name: string;
    value: string | string[] | number; // as dataJson is any when marshalled
    type: SupplierCustomFieldType | "onboarding";
    customField?: InfoRow_SupplierCustomFieldFragment;
    edit?: boolean;
    tooltip?: string;
    onFieldChange?: (id: string, value: string) => void;
    selectOptions?: string[];
    component?: React.ReactNode;
}

export interface ClassificationCustomField extends InfoRow_SupplierCustomFieldFragment {
    valueById: Map<string, string>;
}

export const InfoRow: React.FC<InfoRowProps> = ({
    id,
    name,
    value,
    type,
    edit,
    tooltip,
    onFieldChange,
    selectOptions,
    component,
}) => {
    const { formatDate, formatNumber } = useIntl();

    if (component) {
        return (
            <React.Fragment>
                <Grid item xs={6} alignItems="center" textOverflow="ellipsis" overflow="clip">
                    <Typography variant="textSm" fontWeight={600}>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{ overflowX: "hidden", alignItems: "center" }}>
                    {component}
                </Grid>
            </React.Fragment>
        );
    }

    if (edit && canEditType(type) && onFieldChange && id) {
        return (
            <React.Fragment>
                <Grid item xs={6} alignItems="center" textOverflow="ellipsis" overflow="clip">
                    <Typography variant="textSm" fontWeight={600}>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{ overflowX: "hidden", alignItems: "center" }}>
                    <EditField
                        id={id}
                        value={value}
                        type={type}
                        onFieldChange={onFieldChange}
                        selectOptions={selectOptions}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Grid item xs={6} alignItems="center" textOverflow="ellipsis" overflow="clip">
                <Typography variant="textSm" fontWeight={600}>
                    {name}
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ overflowX: "hidden", alignItems: "center" }}>
                {tooltip ? (
                    <Tooltip title={tooltip}>{formatValue(value, type, formatDate, formatNumber)}</Tooltip>
                ) : (
                    <>{formatValue(value, type, formatDate, formatNumber)}</>
                )}
            </Grid>
        </React.Fragment>
    );
};

function formatValue(
    value: string | string[] | number, // as dataJson is any when marshalled
    type: SupplierCustomFieldType | "onboarding",
    dateFormatter: (value: Parameters<Intl.DateTimeFormat["format"]>[0] | string, opts?: FormatDateOptions) => string,
    numberFormatter: (value: Parameters<Intl.NumberFormat["format"]>[0], opts?: FormatNumberOptions) => string
): React.ReactElement {
    if (value === "" || value == null || value === undefined) {
        return <React.Fragment />;
    }

    if (type === SupplierCustomFieldType.Risk) {
        switch (value) {
            case "LOW":
                return (
                    <Chip
                        label={<FormattedMessage defaultMessage="Low" description="'Low' risk chip label" />}
                        color="success"
                        size="small"
                        variant="status"
                    />
                );
            case "VERY_LOW":
                return (
                    <Chip
                        label={<FormattedMessage defaultMessage="Very Low" description="'Very Low' risk chip label" />}
                        color="success"
                        size="small"
                        variant="status"
                    />
                );
            case "MEDIUM":
                return (
                    <Chip
                        label={<FormattedMessage defaultMessage="Medium" description="'Medium' risk chip label" />}
                        color="warning"
                        size="small"
                        variant="status"
                    />
                );
            case "HIGH":
                return (
                    <Chip
                        label={<FormattedMessage defaultMessage="High" description="'High' risk chip label" />}
                        color="error"
                        size="small"
                        variant="status"
                    />
                );
            case "VERY_HIGH":
                return (
                    <Chip
                        label={
                            <FormattedMessage defaultMessage="Very High" description="'Very High' risk chip label" />
                        }
                        color="error"
                        size="small"
                        variant="status"
                    />
                );
        }
    }
    if (typeof value === "string") {
        switch (type) {
            case "onboarding": {
                return getOnboardingChip(value as OnboardingStatus);
            }
            case SupplierCustomFieldType.Date: {
                return <Typography variant="textSm">{formatDate(value, dateFormatter)}</Typography>;
            }
            case SupplierCustomFieldType.Nace: {
                const option = NACEOptions.find((opt) => opt.code === value.slice(0, 5)) ?? null;
                return <Typography variant="textSm">{`${option?.code}: ${getNACELabel(option)}`}</Typography>;
            }
        }
    }

    if (Array.isArray(value) && type === SupplierCustomFieldType.Classification) {
        return (
            <Stack direction="row" gap={1} flexWrap="wrap">
                {value.map((v: string, i: number) => {
                    return (
                        <Typography variant="textSm" key={`${v}_${i}`}>
                            {v}
                        </Typography>
                    );
                })}
            </Stack>
        );
    }

    if (typeof value === "number") {
        switch (type) {
            case SupplierCustomFieldType.Number:
            case SupplierCustomFieldType.MonetaryAmount:
            case SupplierCustomFieldType.Aggregation:
            case SupplierCustomFieldType.Spend:
                return <Typography variant="textSm">{numberFormatter(value, { maximumFractionDigits: 2 })}</Typography>;
        }
    }

    return <Typography variant="textSm">{value}</Typography>;
}

function getOnboardingChip(status: OnboardingStatus | undefined) {
    switch (status) {
        case OnboardingStatus.Approved:
            return <Chip color="success" label="Approved" size="small" variant="status" />;
        case OnboardingStatus.InOnboarding:
            return <Chip color="warning" label="In onboarding" size="small" variant="status" />;
        case OnboardingStatus.Rejected:
            return <Chip color="error" label="Rejected" size="small" variant="status" />;
        default:
            return <></>;
    }
}

// TODO: Have to figure out "empty state"/auto approved of onboarding
function formatDate(
    dateStr: string | null | undefined,
    dateFormatter: (value: Parameters<Intl.DateTimeFormat["format"]>[0] | string, opts?: FormatDateOptions) => string
): React.ReactNode | "" {
    // Check if the input is null or undefined
    if (dateStr === null || dateStr === undefined) {
        return "";
    }

    // Parse the date string
    const date = new Date(dateStr);

    // Create a date object for "0001-01-01 00:00:00 +0000 UTC"
    const invalidDate = new Date(Date.UTC(2001, 0, 1, 0, 0, 0));

    // Check if the parsed date is invalid
    if (isNaN(date.getTime())) {
        return "";
    }

    // Compare the provided date with the invalid date
    if (date.getTime() === invalidDate.getTime()) {
        return "";
    }

    return dateFormatter(date);
}

export function isValidDate(dateString: string) {
    const date = new Date(dateString ?? "");
    if (!date || isNaN(date.getTime())) {
        return false;
    }
    const invalidDate = new Date(Date.UTC(2001, 0, 1, 0, 0, 0));
    return date.getTime() !== invalidDate.getTime();
}

function canEditType(type: SupplierCustomFieldType | "onboarding") {
    switch (type) {
        case SupplierCustomFieldType.Text:
        case SupplierCustomFieldType.Number:
        case SupplierCustomFieldType.MonetaryAmount:
        case SupplierCustomFieldType.Date:
        case SupplierCustomFieldType.Nace:
        case SupplierCustomFieldType.Spend:
        case SupplierCustomFieldType.Boolean:
        case SupplierCustomFieldType.Select:
            return true;
        default:
            return false;
    }
}

export function getCountryLabel(option: CountryOption | undefined | null) {
    if (!option) return "";
    const locale = navigator.language;

    if (locale === "nb") {
        return `${option.name_no}`;
    }
    return `${option.name_en}`;
}
