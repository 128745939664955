import { useQuery } from "@apollo/client";
import { ShapeIcon } from "@ignite-analytics/components";
import { GridPlus } from "@ignite-analytics/icons";
import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import { GridColDef, GridRowModel, GridRowParams, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { DataGrid } from "@/components/Common/DataGrid/DataGrid";
import { GridToolbarSearchField } from "@/components/Common/DataGrid/Toolbar/GridToolbarSearchField";
import { GridToolbarTotalItemsContainer } from "@/components/Common/DataGrid/Toolbar/GridToolbarTotalItemsContainer";
import { graphql } from "@/gql";

const getRequirements = graphql(`
    query Requirements_GetRequirements {
        getRequirements {
            requirements {
                id
                name
                createdBy
                createdAt
                updatedAt
                updatedBy
                supplierCount
            }
        }
    }
`);

type Requirement = {
    id: string;
    name: string;
    types: Array<"Fields" | "Documents" | "Questionnaire">;
    createdBy: {
        initials: string;
        name: string;
    };
    numberOfSuppliers: number;
};

type RequirementRow = GridRowModel<Requirement>;

const mockData: RequirementRow[] = [
    {
        id: "1",
        name: "A suppliers",
        types: ["Fields", "Documents", "Questionnaire"],
        createdBy: { initials: "OF", name: "Orla Flores" },
        numberOfSuppliers: 4502,
    },
    {
        id: "2",
        name: "B suppliers",
        types: ["Fields", "Documents", "Questionnaire"],
        createdBy: { initials: "MEM", name: "Mons Erling Mathiesen" },
        numberOfSuppliers: 200,
    },
    // Add more mock data as needed
];

const columns: GridColDef<RequirementRow>[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 1,
        display: "flex",
        renderCell: (params) => (
            <Typography variant="textSm" fontWeight={600}>
                {params.value}
            </Typography>
        ),
    },
    {
        field: "types",
        headerName: "Requirement types",
        flex: 1,
        display: "flex",
        renderCell: (params) => (
            <Stack direction="row" gap={1}>
                {params.value.map((type: string) => (
                    <Chip key={type} label={type} size="small" color="primary" />
                ))}
            </Stack>
        ),
    },
    {
        field: "createdBy",
        headerName: "Created by",
        flex: 1,
        display: "flex",
        renderCell: (params) => <Typography variant="body2">{params.value.name}</Typography>,
    },
    {
        field: "numberOfSuppliers",
        headerName: "Number of suppliers",
        flex: 1,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: (params) => params.value.toLocaleString(),
    },
];

export const RequirementsList: React.FC = () => {
    const navigate = useNavigate();

    useQuery(getRequirements, {
        onCompleted: (data) => {
            console.log(data);
        },
    });

    const handleRowClick = (params: GridRowParams) => {
        navigate(`${params.row.id}`);
    };

    const handleCreateNew = () => {
        // TODO: Implement create new functionality
        console.log("Create new requirement clicked");
    };

    if (mockData.length === 0) {
        return (
            <Stack
                direction="column"
                gap={4}
                padding={16}
                alignItems="center"
                justifyContent="center"
                sx={{ height: "100%" }}
            >
                <ShapeIcon size="small" color="accent" icon={<GridPlus />} />
                <Typography variant="textXl" fontWeight={500} textAlign="center">
                    <FormattedMessage
                        defaultMessage="There are currently no requirements created."
                        description="No requirements found"
                    />
                </Typography>
                <Box>
                    <Button variant="contained" color="primary" size="small" onClick={handleCreateNew}>
                        <FormattedMessage defaultMessage="Create new" description="Create new requirement button" />
                    </Button>
                </Box>
            </Stack>
        );
    }

    return (
        <DataGrid
            rows={mockData}
            columns={columns}
            initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
            }}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
            onRowClick={handleRowClick}
            slots={{ toolbar: CustomToolbar }}
            pagination
            sx={{
                "& .MuiDataGrid-row:hover": {
                    cursor: "pointer",
                },
            }}
        />
    );
};

const CustomToolbar: React.FC = () => {
    return (
        <GridToolbarContainer>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Stack direction="row" columnGap={1} width="50%" alignItems="center">
                    <GridToolbarTotalItemsContainer />
                    <Divider variant="fullWidth" orientation="vertical" flexItem sx={{ height: "unset" }} />
                    <GridToolbarSearchField />
                </Stack>
            </Stack>
        </GridToolbarContainer>
    );
};
